import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import AuthService from '../../../services/auth.service'

import 'react-datepicker/dist/react-datepicker.css'
import Moment from 'moment'

Moment.locale('es')
class ModalDelete extends React.Component {
  constructor() {
    super()
    this.state = {

      success: false,
      loader: false
    }

    this.handleModalShowHideDelete = this.handleModalShowHideDelete.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit() {
    this.setState({
      loader: true
    })
    AuthService.deleteSuscriotion(this.props.item?.PK
    )
      .then(
        response => {
          this.setState({
            success: true,
            loader: false
          })
        },
        error => {
          this.setState({
            loader: false
          })
        }
      )
  }

  handleModalShowHideDelete(value) {
    this.setState({
      success: false
    })
    this.props.handleModalShowHide(value)
  }

  render() {
    return (
      <div>
        <Modal className='modal__delete ' show={this.props.showHide}>
          {this.state.success
            ? <Modal.Header closeButton onClick={() => this.handleModalShowHideDelete(true)}>
            </Modal.Header>
            : <Modal.Header closeButton onClick={() => this.handleModalShowHideDelete()}>
            </Modal.Header>}
          <Modal.Body >
            {this.state.success
              ? <Container>
                <Row>
                  <Col className="success">
                    <i className="icon-trash"></i>
                    <div className='success__text modal__title'>{this.props.item?.active ? 'Suscripción eliminada' : 'Petición de suscripción rechazada'}</div>
                  </Col>
                </Row>
              </Container>
              : <Container>
                {this.state.loader

                  ? <div className="loader__container" data-testid="mi-loader">
                  <div className="loader"></div>
                </div>
                
                  : <React.Fragment>
                    <h4 className="modal__title">
                    {this.props.item?.active ? '¿Confirma que desea retirar a este suscriptor?' : '¿Confirma que desea eliminar la petición de suscripción?'}
                       </h4>
                    <div className="card__list__title">
                      {this.props.item?.name}
                    </div>
                    <div>
                      {this.props.item?.PK}
                    </div>
                     <div>
                      {this.props.item?.email}
                    </div>
                    <div>
                      {this.props.item?.code}
                    </div>
                    <Row className="d-flex justify-content-end modal__btn mb-0">
                      <Button className="btn btn-extra" onClick={() => this.handleModalShowHideDelete()}>Cancelar</Button>
                      <Button className='ml-24' variant="primary" onClick={() => this.handleSubmit()}>
                        Sí, eliminar
                      </Button>
                    </Row>
                  </React.Fragment>
                }
              </Container>
            }
          </Modal.Body>

        </Modal>

      </div >
    )
  }
}

export default ModalDelete
