import React from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Check from '../../../img/c-check.png'
import config from '../../../config'

import ReCAPTCHA from 'react-google-recaptcha'

import AuthService from '../../../services/auth.service'

import 'react-datepicker/dist/react-datepicker.css'
import Moment from 'moment'

Moment.locale('es')

class ModalSuscribe extends React.Component {
  constructor() {
    super()
    this.state = {
      name: '',
      email: '',
      code: '',
      active: 0,
      setCatcha: false

    }

    this.handleModalShowHide = this.handleModalShowHide.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.catchaBtn = this.catchaBtn.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  catchaBtn(value) {
    if (value) {
      this.setState({
        setCatcha: true
      })
    } else {
      this.setState({
        setCatcha: false
      })
    }
  }

  handleSubmit() {
    this.setState({
      loader: true,
      success: false

    })
    const obj = {
      name: this.state.name,
      email: this.state.email,
      code: this.state.code,
      active: 0
    }

    AuthService.postSuscriptores(obj
    )
      .then(
        response => {
          this.setState({
            success: true,
            loader: false,
            name: '',
            email: '',
            code: '',
            active: 0,
            setCatcha: false

          })
        },
        error => {
        }
      )
  }

  handleModalShowHide() {
    this.props.handleModalShowHide()
    this.setState({
      loader: false,
      success: false

    })
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }

  render() {
    return (
      <div>
        <Modal data-testid="ModalSuscribe" className='modal__suscribe' show={this.props.showHide}>
          <Modal.Header closeButton onClick={() => this.handleModalShowHide()} />
          <Modal.Body >
            {this.state.success
              ? <Container>
                <Row>
                  <Col className="success">

                    <img className="mb-4" src={Check} alt={Check} />

                    <div className='success__text modal__title'>¡Suscripción realizada!</div>
                  </Col>
                </Row>
              </Container>
              : <Container>
                {this.state.loader

                  ? <div className="loader__container">
                    <div className="loader"></div>
                  </div>
                  : <React.Fragment>
                    <h4 className="modal__title">Suscríbete para recibir las actualizaciones </h4>
                    <div className="modal__body p-0">
                      <div className="modal__descripcion">Te enviaremos notificaciones de las incidencias cuando sean reportadas, actualizadas o resueltas.</div>
                      <Form data-testid="suscribe-form" >
                        <Row className="justify-content-md-center">
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Label htmlFor="nombreInput">Nombre</Form.Label>
                              <Form.Control id="nombreInput" type="text" onChange={this.handleInputChange} name="name" />
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Label htmlFor="emailInput">Correo electrónico</Form.Label>
                              <Form.Control id="emailInput" type="email" onChange={this.handleInputChange} name="email" />

                            </Form.Group>

                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Label htmlFor="codeInput">Código de Comercio</Form.Label>
                              <Form.Control id="codeInput" type="text" onChange={this.handleInputChange} name="code" />
                            </Form.Group>

                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <ReCAPTCHA
                                data-testid="g-recaptcha-checkbox"
                                onChange={this.catchaBtn}

                                sitekey={config.KEY_RECAPTCHA}
                              />

                            </Form.Group>

                          </Col>
                        </Row >
                      </Form ></div>
                    <Row className="d-flex justify-content-end modal__btn mb-0">

                      {
                        (this.state.name &&
                          this.state.code &&
                          this.state.setCatcha &&
                          this.state.email)
                          ? <Button data-testid="btn-Suscribirme" className='btn--w btn__gradient'  variant="primary" onClick={() => this.handleSubmit()}>
                            Suscribirme
                          </Button>
                          : <Button data-testid="btn-Suscribirme-disable" variant="secondary">  Suscribirme</Button>
                      }

                    </Row>
                  </React.Fragment>
                }
              </Container>
            }
          </Modal.Body>

        </Modal>

      </div >
    )
  }
}

export default ModalSuscribe
