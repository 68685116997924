import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Check from '../../../img/c-check.png'
import ModalDetache from '../incident/modalDetache'

import AuthService from '../../../services/auth.service'

import 'react-datepicker/dist/react-datepicker.css'
import Moment from 'moment'

Moment.locale('es')
class ModalDeleteIncidentComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      loader: false,
      success: false
    }

    this.handleModalShowHide = this.handleModalShowHide.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit() {
    this.setState({
      loader: true,
      success: false

    })

    AuthService.deleteComponentesPoliticas(this.props.parent, this.props.item?.SK)
      .then(
        response => {
          this.setState({
            success: true,
            loader: false
          })
        },
        error => {
        }
      )
  }

  handleModalShowHide(value) {
    this.props.handleModalShowHide(value)
    this.setState({
      success: false
    })
  }

  render() {
    return (
      <div>
        <Modal data-testid="deletePolice" className='modal__delete modal__box' show={this.props.showHide}>
          {this.state.success
            ? <Modal.Header closeButton onClick={() => this.handleModalShowHide(true)}>
            </Modal.Header>
            : <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
            </Modal.Header>}
          <Modal.Body >
            {this.state.success
              ? <Container>
                <Row>
                  <Col className="success">
                    <img className="mb-4" src={Check} alt={Check} />
                    <div className='success__text modal__title'>Politica desvinculado</div>
                  </Col>
                </Row>
              </Container>
              : <Container>
                {this.state.loader
                  ? <div className="loader__container">
                    <div className="loader"></div>
                  </div>
                  : <ModalDetache item={this.props.item}
                    handleSubmit={this.handleSubmit}
                    handleModalShowHide={this.handleModalShowHide}
                    modalTitle="¿Confirma si desea que esta política deje de esta asociada a la aplicación?" />
                }
              </Container>
            }
          </Modal.Body>

        </Modal>

      </div >
    )
  }
}
ModalDeleteIncidentComponent.propTypes = {
  parent: PropTypes.any.isRequired,
  handleModalShowHide: PropTypes.func,
  item: PropTypes.any,
  showHide: PropTypes.bool
}
export default ModalDeleteIncidentComponent
