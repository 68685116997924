import React, { useState } from 'react';
import Moment from 'moment';
import { Accordion } from 'react-accessible-accordion';
import Body from './body';
import CardDescription from '../../components/card/aplication/description';
import CardProgress from '../../components/card/progress';
import Maintenance from '../../components/card/aplication/maintenance';
import Modal from '../../components/modal/aplication/create';
import ModalMaintenance from '../../components/modal/aplication/createMantanient';
import ModalIncident from '../../components/modal/incident/create';

import '../../../src/css/app.scss';

Moment.locale('es');

const Index = (props) => {
  const [showHide, setShowHide] = useState(false);
  const [showHideIncident, setShowHideIncident] = useState(false);
  const [showHideMaintenance, setShowHideMaintenance] = useState(false);

  const handleModalShowHide = (loader) => {
    setShowHide(!showHide);
    if (loader) {
      ResetAllDataComponet(loader);
    }
  };

  const handleModalShowHideincident = () => {
    setShowHideIncident(!showHideIncident);
  };

  const handleModalShowMaintenance = (loader) => {
    setShowHideMaintenance(!showHideMaintenance);
    if (loader) {
      ResetAllDataComponet(loader);
    }
  };

  const ResetAllDataComponet = (loader) => {
    if (loader) {
      props.callAllDataComponent();
    }
  };

  return (
    <React.Fragment>
      {props.loader ? (
        <div className="loader__container" data-testid="loader">
          <div className="loader"></div>
        </div>
      ) : (
        <React.Fragment>
          {props.Maintenance && props.Maintenance.filter((item) => {
            return (
              Moment(item.endDate).format('YYYY-MM-DD HH:mm:ss') >=
              Moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
            );
          })?.length >= 1 && (
              <div className="maintenance__title">
                Mantenimiento Programado
              </div>
            )}
          {props.Maintenance &&
            props.Maintenance
              .filter((item) => {
                return (
                  Moment(item.endDate).format('YYYY-MM-DD HH:mm:ss') >=
                  Moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
                );
              })
              .map((item, i) => (
                <React.Fragment key={'Maintenance-' + i}>
                  <Maintenance
                    btnEdit={true}
                    btnDelete={true}
                    ResetAllDataComponet={ResetAllDataComponet}
                    maintenance={item}
                    admin={props.admin}
                  />
                </React.Fragment>
              ))}
        </React.Fragment>
      )}
      {props.AllDataIncident && <CardProgress items={props.AllDataIncident} />}

      <CardDescription
        title="Estado de Servicios"
        btn={true}
        btnIncident={true}
        btn_text="Crear aplicación"
        btn_textIncident="Registrar incidente"
        btn_textMaintenance="Programar mantenimiento"
        text="Los semáforos registran los incidentes relacionados a disponibilidad y/o performance que se presenten en un día."
        type={1}
        admin={props.admin}
        btnFuntion={handleModalShowHide}
        btnFuntion2={handleModalShowHideincident}
        btnFuntion3={handleModalShowMaintenance}
      />
      {props.admin && (
        <React.Fragment>
          <ModalIncident
            showHide={showHideIncident}
            handleModalShowHide={handleModalShowHideincident}
          />
          <Modal
            showHide={showHide}
            handleModalShowHide={handleModalShowHide}
            ResetAllDataComponet={ResetAllDataComponet}
            AllDataComponent={props.AllDataComponent}
          />
          <ModalMaintenance
            showHide={showHideMaintenance}
            handleModalShowHide={handleModalShowMaintenance}
            ResetAllDataComponet={ResetAllDataComponet}
            AllDataComponent={props.AllDataComponent}
          />
        </React.Fragment>
      )}

      {props.loader ? (
        <div className="loader__container" data-testid="loader">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="card__list">
          <Accordion allowZeroExpanded={true}>
            {props.AllDataComponent &&
              props.AllDataComponent.map((item, i) => {
                return (
                  <Body
                    admin={props.admin}
                    data-testid="card-data-component"
                    ResetAllDataComponet={ResetAllDataComponet}
                    key={'Body-' + i}
                    item={item}
                    i={i}
                  />
                );
              })}
          </Accordion>
        </div>
      )}
    </React.Fragment>
  );
};

export default Index;