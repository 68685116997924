import { Button, } from 'react-bootstrap'
import Row from 'react-bootstrap/Row'

function ModalRowDelete(props) {
    return (
        <Row className="d-flex justify-content-end modal__btn mb-0">
            <Button className='btn btn-extra' variant="outline-secondary" onClick={() => props.handleModalShowHide()}>Cancelar</Button>
            <Button className='ml-24' variant="primary" onClick={() => props.handleSubmit()}>
                Sí, eliminar
            </Button>
        </Row>

    )
}

export default ModalRowDelete
