import React, { useState, useEffect } from 'react';
import { Button, Form, Modal, Col, Container, Row } from 'react-bootstrap';
import CardItem from '../../components/card/suscribe/listItem';
import ModalDelete from '../../components/modal/suscribe/delete';
import ModalActive from '../../components/modal/suscribe/active';
import AuthService from '../../services/auth.service';
import Check from '../../img/c-check.png';

const Body = (props) => {
  const [showHideActive, setShowHideActive] = useState(false);
  const [showHideDelete, setShowHideDelete] = useState(false);
  const [showHideModalPlatform, setShowHideModalPlatform] = useState(false);
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const [validate, setvalidate] = useState(false);
  const [plataformas, setPlataformas] = useState([]);

  useEffect(() => {
    const updatedPlataformas = props.plataformas?.map((plataforma) => {
      const checked = !!props.item?.components.find((c) => c?.PK === plataforma?.PK);
      return { label: plataforma.name, id: plataforma.PK, checked };
    });
    setPlataformas(updatedPlataformas);
  }, [props.plataformas, props.item]);

  const handleInputChangeCheck = (event) => {
    const { id, checked } = event.target;
    const index = id.replace('add_platform_', '');
    const updatedPlataformas = plataformas.map((p, i) => {
      if (index === 'all' || index === i.toString()) {
        return { ...p, checked: !!checked };
      }
      return p;
    });
    setPlataformas(updatedPlataformas);
    setvalidate(true)
  };

  const handleModalShowHide = () => {
    const state = {
      showHideModalPlatform: !showHideModalPlatform,
    };
    if (!showHideModalPlatform) {
      state.success = false;
    }
    setShowHideModalPlatform(state.showHideModalPlatform);
    setSuccess(state.success);
  };

  const handleModalShowHidActive = (loader) => {
    setShowHideActive(!showHideActive);
    if (loader) {
      props.ResetAllData(loader);
    }
  };

  const handleModalShowHidDelete = (loader) => {
    setShowHideDelete(!showHideDelete);
    if (loader) {
      props.ResetAllData(loader);
    }
  };

  const handleSubmit = async () => {
    setLoader(true);
    setSuccess(false);
    const components = plataformas.filter((item) => item.checked).map((item) => item.id);
    const obj = {
      active: true,
      components,
    };

    try {
      await AuthService.patchSuscriptorID(props.item?.PK, obj);
      setSuccess(true);
    } catch (error) {
      setSuccess(true);
    } finally {
      setLoader(false);
    }
  };

  return (
    <React.Fragment>
      {props.active ? (
        <Col md={12}>
          <CardItem
            i={props.i}
            item={props.item}
            btnFuntion={handleModalShowHidActive}
            btnFuntionDelete={handleModalShowHidDelete}
            admin={props.admin}
            active={props.active}
          />
          <ModalDelete
            item={props.item}
            showHide={showHideDelete}
            handleModalShowHide={handleModalShowHidDelete}
            name="grupo"
          />
          <ModalActive
            item={props.item}
            showHide={showHideActive}
            handleModalShowHide={handleModalShowHidActive}
            name="grupo"
          />
        </Col>
      ) : (
        <Col md={12}>
          <CardItem
            i={props.i}
            item={props.item}
            btnFuntion={handleModalShowHidActive}
            btnFuntionDelete={handleModalShowHidDelete}
            btnFuntionModal={handleModalShowHide}
            admin={props.admin}
            active={props.active}
            approbate={true}
            plataformas={plataformas}
            handleInputChangeCheckRCA={props.handleInputChangeCheckRCA}
          />
          <ModalDelete
            item={props.item}
            showHide={showHideDelete}
            handleModalShowHide={handleModalShowHidDelete}
            name="grupo"
          />
          <ModalActive
            item={props.item}
            showHide={showHideActive}
            handleModalShowHide={handleModalShowHidActive}
            name="grupo"
          />
          <Modal show={showHideModalPlatform}>
            {success ? (
              <React.Fragment>
                <Modal.Header closeButton onClick={() => props.ResetAllData(true)}>
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    <Row>
                      <Col className="success">
                        <img className="mb-4" src={Check} alt={Check} />
                        <div className="success__text modal__title"> ¡Aplicaciones agregadas exitosamente!</div>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>  </React.Fragment>
            ) : (
              <React.Fragment>

                <Modal.Header closeButton onClick={handleModalShowHide}>
                </Modal.Header>
                <Modal.Body className='pb-0'>
                  <Container>
                    {loader ? (
                      <div className="loader__container">
                        <div className="loader"></div>
                      </div>
                    ) : (
                      <React.Fragment>
                        <article>
                          <h4 className="modal__title mb-24">Agregar aplicaciones </h4>
                          <h6 className="card__form__title">{props.item?.name}</h6>
                          <p className="card__form__description mb-24">{props.item?.PK}</p>
                        </article>
                        <div className="card__form">
                          <div className="card__form__head">
                            <Form.Check
                              type="checkbox"
                              label="Todas las aplicaciones"
                              inline
                              className="btn__checkbox active"
                              name="rca"
                              onChange={handleInputChangeCheck}
                              id="add_platform_all"
                              defaultChecked={plataformas.every(item => item.checked === true)}
                            />
                          </div>
                          <div className="card__form__body">
                            <div className="card__form__list">
                              {plataformas?.map((item, i) => (
                                <div className="card__form__item" key={i}>
                                  <Form.Check
                                    type="checkbox"
                                    label={item.label}
                                    inline
                                    className="btn__checkbox active"
                                    name={item.id}
                                    onChange={handleInputChangeCheck}
                                    checked={item.checked}
                                    id={'add_platform_' + i}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </Container>
                </Modal.Body>
              </React.Fragment>)}

            {!success && !loader && (
              <Modal.Body>
                <Modal.Footer className='p-0'>
                  <Button onClick={handleModalShowHide} className="m-0 btn btn-extra">
                    Cancelar
                  </Button>
                  {
                    (validate)
                      ? <Button className='ml-24-i mt-0 mb-0 mx-0 ' variant="primary" onClick={handleSubmit} data-testid="update-btn">
                        Aceptar
                      </Button>
                      : <Button className="ml-24-i mt-0 mb-0 mx-0 " variant="secondary" data-testid="update-btn"> Aceptar</Button>
                  }
                </Modal.Footer>
              </Modal.Body>
            )}
          </Modal>
        </Col>
      )}
    </React.Fragment>
  );
};

export default Body;