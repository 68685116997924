import React, { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';

const fileTypes = ['PDF'];

function DragDrop(props) {
  const [file, setFile] = useState(null);

  const handleChange = (file) => {
    setFile(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      props.handleInputChangeFile(
        {
          file64: reader.result,
          fileName: file?.name
        },
        true
      );
    };
    reader.onerror = function (error) {
    };
  };

  const reset = () => {
    setFile(null);
    props.handleInputChangeFile(
      {
        file64: null,
        fileName: null
      },
      false
    );
  };

  return (
    <>
      <div className="file__label">
        Adjuntar RCA
      </div>
      {file?.name ? (
        <div className="file__select">
          <div className="file__text">
            {file?.name}
          </div>
          <button onClick={reset} type="button" className="btn-close" aria-label="Close" howu-index="768"></button>
        </div>
      ) : (
        <div className="file">
          <FileUploader id="file-input" classes={'file_zona'} label="Seleccionar documento" handleChange={handleChange} types={fileTypes} />
        </div>
      )}
    </>
  );
}

export default DragDrop;
