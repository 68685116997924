import React from 'react'
import Logo from '../../../img/logo3.png'
import Moment from 'moment'

Moment.locale('es')

class Footer extends React.Component {
  render () {
    return (
            <footer data-testid="footer-component" className="footer" >
                <div className="footer__container content">
                    <div className="footer__logo" >
                        <img src={Logo} alt="Logo" />
                    </div>
                    <div className="footer__info" >
                        Copyright © {Moment(new Date()).format(
                      'YYYY'
                    )} Niubiz - Todos los derechos reservados
                    </div>
                </div>
            </footer>
    )
  }
}

export default Footer
