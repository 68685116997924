import React from 'react'
import PropTypes from 'prop-types'

import { Button, Modal, Form } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Check from '../../../img/c-check.png'
import CardAddPoliticas from './cardAddPolice'
import CardDeletePoliticas from './cardDeletePolitica'

import AuthService from '../../../services/auth.service'

import Moment from 'moment'

Moment.locale('es')
class ModalUpdateComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      id: '',
      subGrupos: [],
      name: '',
      grupo: '',
      subgrupo: '',
      loader: false,
      success: false,
      validate: false,
      loaderPoliticaActual: false,
      PoliticasActual: []
    }

    this.handleModalShowHide = this.handleModalShowHide.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClickgetPoliticaActual = this.handleClickgetPoliticaActual.bind(this)
  }

  componentDidMount() {
    this.handleClickgetPoliticaActual()
  }

  componentWillReceiveProps(props) {
    this.setState({

      id: props.item.SK,
      subGrupos: props.item.subGrupos,
      name: props.item.name,
      grupo: props.item.grupo,
      subgrupo: props.item.subgrupo
    })
  }

  handleClickgetPoliticaActual(loader) {
    if (loader) {
      this.setState({
        loaderPoliticaActual: true
      })
    }
    AuthService.getComponentesPoliticas(this.props.item?.SK)
      .then(
        response => {
          this.setState({
            PoliticasActual: response.data.data.Items,
            loaderPoliticaActual: false
          })
        }

      )
  }

  handleSubmit() {
    this.setState({
      loader: true
    })
    const obj = {
      id: this.props.item?.SK,
      name: (this.state.name ? this.state.name : this.props.item?.name),
      grupo: (this.props.item?.tipo === 'grupo' ? '' : this.props.item?.grupo),
      subgrupo: (this.props.item?.tipo === 'grupo' || this.props.item?.tipo === 'subgrupo' ? '' : this.props.item?.subgrupo)

    }

    AuthService.postComponents(obj)
      .then(
        response => {
          this.setState({
            success: true,
            loader: false
          })
        },
        error => {

        }
      )
  }

  handleClickgetSubGrupos(id) {
    AuthService.getGruposSubgrupos(id)
      .then(
        response => {
          this.setState({

            subGrupos: response.data.data.Items

          })
        },
        error => {

        }
      )
  }

  handleModalShowHide(value) {
    this.props.handleModalShowHide(value)
    this.setState({
      success: false
    })
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
      validate: true
    })

    if (value && name === 'grupo') {
      this.handleClickgetSubGrupos(value)
    }
  }

  render() {
    return (
      <div>
        <Modal data-testid="ModalUpdateComponent" className={this.props.item?.tipo === 'componente' ? '' : 'modal__delete'} show={this.props.showHide}>
          {this.state.success
            ? <Modal.Header closeButton onClick={() => this.handleModalShowHide(true)}>
            </Modal.Header>
            : <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
            </Modal.Header>}
          <Modal.Body>
            {this.state.success
              ? <Container>
                <Row>
                  <Col className="success">

                    <img className="mb-4" src={Check} alt={Check} />

                    <div className='success__text modal__title'>{this.props.item?.tipo === 'componente' ? 'Aplicación editada' : (this.props.item?.tipo === 'subgrupo' ? 'Subgrupo editado' : 'Grupo editado')} </div>
                  </Col>
                </Row>
              </Container>
              : <Container>
                {this.state.loader

                  ? <div className="loader__container">
                    <div className="loader"></div>
                  </div>
                  : <React.Fragment>
                    <h4 className="modal__title">Edita los datos {this.props.item?.tipo === 'componente' ? 'de la aplicación ' : (this.props.item?.tipo === 'subgrupo' ? 'del subgrupo' : 'del grupo')} </h4>
                    <Form >
                      <Row className="justify-content-md-center">
                        <Col md={this.props.item?.tipo === 'componente' ? 6 : 12}>

                          <Form.Group className="mb-3" controlId="formGroupEmail">
                            <Form.Label>Nombre {this.props.item?.tipo === 'componente' ? 'de la aplicación' : (this.props.item?.tipo === 'subgrupo' ? 'del subgrupo' : 'del grupo')}</Form.Label>
                            <Form.Control defaultValue={this.props.item?.name} type="text" onChange={this.handleInputChange} name='name' />
                          </Form.Group>

                        </Col>

                        {this.props.item?.tipo === 'componente' &&

                          <Col md={6}>
                            <CardAddPoliticas
                              reset={this.handleClickgetPoliticaActual}
                              parent={this.props.item?.SK}
                            />
                          </Col>
                        }
                        {this.props.item?.tipo === 'componente' &&
                          <Col md={12}>

                            <Col md={6}>
                              <Form.Label>Politicas selecionadas</Form.Label>
                            </Col>
                            <Col md={6} />
                            <Col md={12}>
                              {this.state.loaderPoliticaActual
                                ? <div className="loader__container">
                                  <div className="loader"></div>
                                </div>
                                : <Row>
                                  {
                                    (this.state.PoliticasActual.map((item, i) => {
                                      return (
                                        <Col key={i} md={6}>

                                          <CardDeletePoliticas
                                            reset={this.handleClickgetPoliticaActual}
                                            parent={this.props.item?.SK}
                                            item={item} />

                                        </Col >
                                      )
                                    })
                                    )
                                  }
                                </Row>
                              }

                            </Col ></Col >

                        }
                        <Row className="d-flex justify-content-end modal__btn mb-0">
                          <Button className='btn btn-extra' variant="outline-secondary" onClick={() => this.handleModalShowHide()}>Cancelar</Button>
                          {
                            (this.state.validate)
                              ? <Button className='ml-24' variant="primary" onClick={() => this.handleSubmit()}>
                                Editar {this.props.item?.tipo === 'componente' ? 'aplicación' : (this.props.item?.tipo === 'subgrupo' ? 'subgrupo' : 'grupo')}
                              </Button>
                              : <Button className='ml-24' variant="secondary">
                                Editar {this.props.item?.tipo === 'componente' ? 'aplicación' : (this.props.item?.tipo === 'subgrupo' ? 'subgrupo' : 'grupo')}
                              </Button>
                          }
                        </Row>

                      </Row>
                    </Form>
                  </React.Fragment>
                }
              </Container>
            }
          </Modal.Body>

        </Modal>

      </div >
    )
  }
}
ModalUpdateComponent.propTypes = {
  handleModalShowHide: PropTypes.func,
  showHide: PropTypes.bool,
  item: PropTypes.any
}
export default ModalUpdateComponent
