import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ModalEdit from './modalEdit';
import ModalTitleUpdate from './modalTitleUpdate';

import AuthService from '../../../services/auth.service';
import { getTimeHourFormat } from '../../../utils/timeHourFormat';
import 'react-datepicker/dist/react-datepicker.css';
import Moment from 'moment';
Moment.locale('es');

const ModalEditIncident = (props) => {
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState(false);

  useEffect(() => {
    if (props.item) {
      const dateTimeHourFormat = getTimeHourFormat(props.item.dateTimeHour);
      setStatus(props.item.status);
      setMessage(props.item.message);
      setStartDate(dateTimeHourFormat);
    }
  }, [props.item]);

  const handleSubmit = () => {
    const obj = {
      id: props.item?.SK,
      status: status ? status : props.item?.status,
      message: message ? message : props.item?.message,
      dateTimeHour: Moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
    };

    setLoading(true);
    AuthService.putActualizacionIncidente(props.parent.SK, { ...obj }).then(
      (response) => {
        setSuccess(true);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const handleModalShowHide = (value) => {
    setSuccess(false);
    setLoading(false); 
    props.handleModalShowHide(value);
    setValidate(false)
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'status':
        setStatus(value);
        break;
      case 'message':
        setMessage(value);
        break;
      default:
        break;
    }
    setValidate(true)
  };

  const handleChangeDate = (date) => {
    setStartDate(date);
    setValidate(true)

  };

  return (
    <div>
      <Modal show={props.showHide}>
        <Modal.Header closeButton onClick={() => handleModalShowHide(success)} data-testid="closeBtn"></Modal.Header>
        <Modal.Body>
          {success ? (
            <ModalTitleUpdate />
          ) : (
            <>
              {loading ? (
                <div className="loader__container">
                  <div className="loader"></div>
                </div>
              ) : (
                <Container>
                  <h4 className="modal__title">Editar estado del incidente</h4>
                  <Form>
                    <Row className="justify-content-md-center">
                      <ModalEdit disabled={true} item={props.item} handleSubmit={handleInputChange} handleChangeDate={handleChangeDate} startDate={startDate} />
                      <Col sm={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Descripción</Form.Label>
                          <Form.Control defaultValue={props.item?.message} type="text" onChange={handleInputChange} name="message" aria-label="Descripción" />
                        </Form.Group>
                      </Col>
                      <Row className="d-flex justify-content-end modal__btn mb-0">
                        <Button className="btn btn-extra" variant="outline-secondary" onClick={() => handleModalShowHide()}>
                          Cancelar
                        </Button>
                        {validate ? (
                          <Button className="ml-24" variant="primary" onClick={() => handleSubmit()}>
                            Actualizar incidente
                          </Button>
                        ) : (
                          <Button className="ml-24" variant="secondary">
                            Actualizar incidente
                          </Button>
                        )}
                      </Row>
                    </Row>
                  </Form>
                </Container>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalEditIncident;