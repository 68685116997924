import React, { useState } from 'react';
import T_a from '../../../img/t_a.png';
import T_v from '../../../img/t_v.png';
import BtnMore from '../../../img/btn_more.png';
import BtnMinus from '../../../img/btn_minus.png';
import ListItem from './listItemIncidenteUpdate';
import E_2 from '../../../img/e_2.png';
import E_1 from '../../../img/e_1.png';
import Month from '../incident/month';
import ListItemGroupsEditDelete from '../aplication/listItemGroupsEditDelete';
import XLSX from 'xlsx';
import Moment from 'moment';
Moment.locale('es');

const CardItem = (props) => {
    const [active, setActive] = useState(false);

    const handleClickActive = () => {
        setActive(!active);
    };

    const handleClick = () => {
        props.btnFuntion();
    };

    const handleClickDelete = () => {
        props.btnFuntionDelete();
    };

    const handleClickActualizar = () => {
        props.btnFuntionActualizar();
    };

    const handleClickAddRCA = () => {
        props.btnFuntionAddRCA();
    };
    const sortedActualizaciones = props.item?.actualizaciones?.sort((a, b) => {
        const dateA = new Date(a?.dateTimeHour);
        const dateB = new Date(b?.dateTimeHour);
        return dateB - dateA;
    });


    const handleDownloadExcel = () => {
        const data = props.subscribersData;
        const filteredData = data
            .filter(item => item.rcasent === true)
            .map(item => ({
                name: item.name,
                email: item.email,
                rcasent: item.rcasent,
            }));

        const ws = XLSX.utils.json_to_sheet(filteredData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

        const dataUrl = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + btoa(XLSX.write(wb, { bookType: 'xlsx', type: 'binary' }));

        const a = document.createElement('a');
        a.href = dataUrl;
        a.download = 'Reporte_de_envío_RCA_' + props.item?.PK + '.xlsx';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };


    return (
        <div data-testid="card-list-item">
            <Month>
                <div className="card__list__status__item--a">
                    <div className="card__list__status__img">
                        {props.item?.status === 'Resolved' ? <img src={T_v} alt="T_v" /> : <img src={T_a} alt="T_a" />}
                    </div>
                    <div>
                        {Moment(props.item?.dateTimeHour).format('D MMMM YYYY - hh:mm:ss A')}
                    </div>
                </div>
                {props.item?.rcaUrl  && props.admin &&
                    <div className='badge ms-4'>
                        RCA <span className='text-lowercase'>enviado</span>
                    </div>
                }

            </Month>

            <div key={props.i} className="card__list__container card__list__container__item">
                <div className="accordion__heading">
                    <div className="card__list__top no-pointer">
                        <div className="card__list__head">
                            <div className="card__list__status__item">
                                <div className="card__list__status__item--a">
                                    <div className="card__list__title mt-0">
                                        {props.item?.name} - {props.item?.published === true ? 'Publicado' : 'Borrador'}
                                    </div>
                                </div>
                                {props.admin && (
                                    <div className="card__list__updates">
                                        {props.item.status === 'Resolved' && props.item?.published === true ? (
                                            <div className="card__list__update__item pointer" onClick={handleClickAddRCA}>
                                                <div className="card__list__update__img">
                                                    <i className="icon-see"></i>
                                                </div>
                                                {props.item.rcaUrl ?

                                                    <div className="card__list__update__text">
                                                        Reenviar RCA
                                                    </div>
                                                    :
                                                    <div className="card__list__update__text">
                                                        Adjuntar y enviar RCA
                                                    </div>
                                                }
                                            </div>
                                        ) : (
                                            <div className="card__list__update__item pointer" onClick={handleClickActualizar}>
                                                <div className="card__list__update__img">
                                                    <i className="icon-arrow-top"></i>
                                                </div>
                                                <div className="card__list__update__text">
                                                    Actualizar estado
                                                </div>
                                            </div>
                                        )}
                                        <ListItemGroupsEditDelete handleClick={handleClick} handleClickDelete={handleClickDelete} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="card__list__body pb_28">
                            <div className="status__content">
                                {props.item?.impact === 'Critical' ? (
                                    <div className="status__item">
                                        <div className="status__img">
                                            <img src={E_1} alt="E_1" />
                                        </div>
                                        <div className="status__text">ESTADO CRÍTICO</div>
                                    </div>
                                ) : (
                                    <div className="status__item">
                                        <div className="status__img">
                                            <img src={E_2} alt="E_2" />
                                        </div>
                                        <div className="status__text">SERVICIO CON ALERTA</div>
                                    </div>
                                )}
                            </div>
                            <div className="card__list__text">{props.item?.message}</div>
                            <div className="card__list__text-b">Publicado por: {props.item?.user}</div>
                            <div className="card__list__text-b">Duración: {props.item?.duration ? props.item?.duration : 'Incidente inconcluso'}</div>
                        </div>
                        <div className="card__list__btn__container  mt-4">
                            {props.item.rcaUrl && props.admin &&
                                <div className="card__list__update__item pointer m-0"
                                    onClick={handleDownloadExcel}>
                                    <div className="card__list__update__img">
                                        <i className="icon-download"></i>
                                    </div>
                                    <div className="card__list__update__text">
                                        Reporte de envío RCA
                                    </div>
                                </div>
                            }
                            <div className='flex justify-content-end' onClick={handleClickActive}>
                                {active ? <img className="card__list__btn pointer" src={BtnMinus} alt={BtnMinus} /> : <img className="card__list__btn pointer" src={BtnMore} alt={BtnMore} />}
                            </div>
                        </div>
                    </div>
                </div>
                {active && (
                    <div>
                        {sortedActualizaciones.map((item, i) => (
                            <ListItem
                                key={i}
                                btnFuntioneditincidentupdate={props.btnFuntioneditincidentupdate}
                                parent={props.item}
                                admin={props.admin}
                                item={item}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CardItem;