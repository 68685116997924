import React from 'react'

class My404Component extends React.Component {
  render () {
    return (
      <div className="error__container">
        <div className="error__content">
          <div className="error__card">
            <div className="error__card__title">404</div>
            <div className="error__card__description">Página no encontrada</div>
          </div>
        </div>
      </div>
    )
  }
}
export default My404Component
