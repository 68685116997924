import React from 'react'
import E_2 from '../../img/e_2.png'
import E_1 from '../../img/e_1.png'
import ProgresList from './progressList'
import PropTypes from 'prop-types'
import 'react-accessible-accordion/dist/fancy-example.css'
import Moment from 'moment'

Moment.locale('es')
class CardProgress extends React.Component {
    render() {
        return (
            <div className="mb-4">

                {

                    (this.props.items && this.props.items?.map((item, i) => {
                        return (
                            <div key={'Progress-' + i} >
                                {item.status !== 'Resolved' && item.published === true &&
                                    <div key={'Progress-item-' + i} className="card__form__title fw-normal card__list__container card__list__container__item mb-3" data-testid={'item' + i}>
                                        <div className="p-3 ">
                                            <div className="card__list__status__text mb-3 ">
                                                {Moment(item.dateTimeHour).format(
                                                    'D MMMM  YYYY - h:mm:ss A'
                                                )}
                                            </div>
                                            <div className="card__list__status__item--a">
                                                <div className="card__list__status__img">
                                                    {item.impact === 'Critical'
                                                        ? <div className="status__item ml-0">
                                                            <div className="status__img">
                                                                <img style={{ width: '14px' }} width={14} src={E_1} alt="E_1" />
                                                            </div>
                                                            <div className="status__text">
                                                                ESTADO CRÍTICO
                                                            </div>
                                                        </div>
                                                        : <div className="status__item ml-0">
                                                            <div className="status__img">
                                                                <img style={{ width: '14px' }} width={14} src={E_2} alt="E_2" />
                                                            </div>
                                                            <div className="status__text">
                                                                SERVICIO CON ALERTA
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                            <div className="steps__title text-start text-uppercase my-3">
                                                {item.name}
                                            </div>
                                            <div className="card__list__text mb-3">
                                                {item.message}
                                            </div>
                                            <div className="card__list__text-b">
                                                Duración: {item.duration ? item.duration : 'Incidente inconcluso'}
                                            </div>

                                        </div>
                                        <ProgresList item={item} />
                                    </div>
                                }
                            </div>
                        )
                    })
                    )
                }
            </div>
        )
    }
}

CardProgress.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        status: PropTypes.string,
        published: PropTypes.bool,
        impact: PropTypes.string,
        dateTimeHour: PropTypes.string,
        name: PropTypes.string,
        message: PropTypes.string,
        duration: PropTypes.string,
        SK: PropTypes.any
    }))

}

export default CardProgress
