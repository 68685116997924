import React from 'react'
import PropTypes from 'prop-types'
import E_1 from '../../../img/e_1.png'
import E_2 from '../../../img/e_2.png'
import E_3 from '../../../img/e_3.png'
class Card extends React.Component {
  incidences = [
    {
      label: 'Estable',
      description: 'No se informan alertas.',
      imgSrc: E_3,
      imgAlt: 'image estado estable'
    },
    {
      label: 'Alerta',
      description: 'Posible incidencia.',
      imgSrc: E_2,
      imgAlt: 'image estado alerta'
    },
    {
      label: 'Crítico',
      description: 'Incidencia crítica.',
      imgSrc: E_1,
      imgAlt: 'image estado crítica'
    }
  ]

  constructor () {
    super()
    this.handleClick = this.handleClick.bind(this)
    this.handleClick2 = this.handleClick2.bind(this)
    this.handleClick3 = this.handleClick3.bind(this)
  }

  handleClick () {
    this.props.btnFuntion()
  }

  handleClick2 () {
    this.props.btnFuntion2()
  }

  handleClick3 () {
    this.props.btnFuntion3()
  }

  render () {
    return (
            <React.Fragment>

                <div className="card__description card__description__b" >
                    <div className='card__description__head'>
                        <h1 >{this.props.title}</h1>
                    </div>
                    {this.props.admin &&
                    <div className='card__btnBox'>
                        <div className='card__btnBox__text'>
                            Como administrador puedes:
                        </div>
                        <div onClick={() => this.handleClick()} className="btn"> {this.props.btn_text}</div>

                        <div onClick={() => this.handleClick2()} className="btn"> {this.props.btn_textIncident}</div>

                        <div onClick={() => this.handleClick3()} className="btn"> {this.props.btn_textMaintenance}</div>

                    </div>
                    }

                    <div className="card__description__body ">
                        <div className="status__label">

                            <div className='status__text__normal'>
                            A continuación se muestra el estado de los servicios
                            <span className='status__text'> durante los {window.innerWidth > 1200 ? '60' : '30'} días.
                            </span>
                            </div>
                        </div>
                        <div className="status__items status__items-b">
                            <div className="status__item pl-0" howu-index="5160">Leyenda:</div>
                            {
                                this.incidences.map((state) => (
                                    <div className="status__item" key={state.label}>
                                        <div className="status__img">
                                            <img src={state.imgSrc} alt={state.imgAlt} />
                                        </div>
                                        <div className="status__text">
                                        {state.label.toUpperCase()}:
                                            <span className='status__text__normal'>&nbsp;{state.description}</span>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
    )
  }
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  btnFuntion: PropTypes.func,
  btnFuntion2: PropTypes.func,
  btnFuntion3: PropTypes.func,
  btn_text: PropTypes.string,
  btn_textIncident: PropTypes.string,
  btn_textMaintenance: PropTypes.string
}

export default Card
