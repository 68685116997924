import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Check from '../../../img/c-check.png'
import AuthService from '../../../services/auth.service'
import 'react-datepicker/dist/react-datepicker.css'
import Moment from 'moment'
import ModalRowDelete from '../components/modalRowDelete'
Moment.locale('es')
class ModalDeleteComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      id: ' ',
      startDate: new Date(),
      startDateState: new Date(),
      name: '',
      status: '',
      impact: '',
      message: '',
      success: false,
      loader: false
    }

    this.handleModalShowHide = this.handleModalShowHide.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentWillReceiveProps(props) {
    this.setState({

      id: props.item.SK,
      name: props.item.name,
      status: props.item.status,
      impact: props.item.impact,
      message: props.item.message
    })
  }

  handleSubmit() {
    this.setState({
      loader: true
    })
    AuthService.deleteComponents(this.props.item?.SK
    )
      .then(
        response => {
          this.setState({
            success: true,
            loader: false
          })
        },
        error => {

        }
      )
  }

  handleModalShowHide(value) {
    this.props.handleModalShowHide(value)
    this.setState({
      success: false
    })
  }

  render() {
    return (
      <div>
        <Modal data-testid="ModalDeleteComponent" className='modal__delete' show={this.props.showHide}>
          {this.state.success
            ? <Modal.Header closeButton onClick={() => this.handleModalShowHide(true)}>
            </Modal.Header>
            : <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
            </Modal.Header>}
          <Modal.Body >
            {this.state.success
              ? <Container>
                <Row>
                  <Col className="success">

                    <img className="mb-4" src={Check} alt={Check} />

                    <div className='success__text modal__title'>{this.props.name === 'aplicación' ? 'Aplicación eliminada' : (this.props.name === 'subgrupo' ? 'Subgrupo eliminado' : 'Grupo eliminado')}</div>
                  </Col>
                </Row>
              </Container>
              : <Container>
                {this.state.loader

                  ? <div className="loader__container">
                    <div className="loader"></div>
                  </div>
                  : <React.Fragment>
                    <h4 className="modal__title">¿Confirma que desea
                      eliminar {this.props.name === 'aplicación' ? 'la aplicación' : (this.props.name === 'subgrupo' ? 'el subgrupo' : 'el grupo')}? </h4>
                    <div className='card__list__container'>
                      <div className='accordion__heading'>
                        <div className="card__list__top" >

                          <div className="card__list__body">
                            <div className="card__list__title">{this.props.item?.name}
                            </div >
                            <div className="card__list__text">{this.props.item?.message}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ModalRowDelete handleModalShowHide={this.handleModalShowHide} handleSubmit={this.handleSubmit} />
                  </React.Fragment>
                }
              </Container>
            }
          </Modal.Body>
        </Modal>
      </div >
    )
  }
}
ModalDeleteComponent.propTypes = {
  handleModalShowHide: PropTypes.func,
  showHide: PropTypes.bool,
  item: PropTypes.any,
  name: PropTypes.string
}
export default ModalDeleteComponent
