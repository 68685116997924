import authHeader from './auth-header'
import authHeaderToken from './auth-header-token'
import axiosInstance from './axiosInstance'

class AuthService {

  async getsuscriptoresIncidenteId(id) {
    try {
      const headers = await authHeaderToken();

      return axiosInstance.get('/suscriptores-incidente/' + id, {
        headers: headers,
      });
    } catch (error) {
      throw error;
    }
  }

  async getIncidentesAdmin() {
    try {
      const headers = await authHeaderToken();

      return axiosInstance.get('/incidentes-adm', {
        headers: headers,
      });
    } catch (error) {
      throw error;
    }
  }

  async getIncidentesAdminSearch(key) {
    try {
      const headers = await authHeaderToken();

      return axiosInstance.get('/incidentes-adm?' + key, {
        headers: headers,
      });
    } catch (error) {
      throw error;
    }
  }

  async getmantenimientos(key) {
    try {
      const headers = await authHeaderToken();

      return axiosInstance.get('/mantenimientos?' + key, {
        headers: headers,
      });
    } catch (error) {
      throw error;
    }
  }

  async potIncidente(value) {
    try {
      const headers = await authHeaderToken();

      return axiosInstance.post('/incidentes', value, {
        headers: headers,
      });
    } catch (error) {
      throw error;
    }
  }

  async postIncidenteRca(value) {
    try {
      const headers = await authHeaderToken();

      return axiosInstance.post('/incidentes-rca', value, {
        headers: headers,
      });
    } catch (error) {
      throw error;
    }
  }

  async postMantenimientos(value) {
    try {
      const headers = await authHeaderToken();

      return axiosInstance.post('/mantenimientos', value, {
        headers: headers,
      });
    } catch (error) {
      throw error;
    }
  }

  async putActualizacionIncidente(id, value) {
    try {
      const headers = await authHeaderToken();

      return axiosInstance.post('/incidentes-actualizaciones/' + id, value, {
        headers: headers,
      });
    } catch (error) {
      throw error;
    }
  }

  async putActualizacionMantenimientos(id, value) {
    try {
      const headers = await authHeaderToken();

      return axiosInstance.patch('/mantenimientos/' + id, value, {
        headers: headers,
      });
    } catch (error) {
      throw error;
    }
  }

  async putActualizacionUpdateIncidente(id, value) {
    try {
      const headers = await authHeaderToken();

      return axiosInstance.post('/incidentes-actualizaciones/' + id, value, {
        headers: headers,
      });
    } catch (error) {
      throw error;
    }
  }

  async postComponentesPoliticas(id, value) {
    try {
      const headers = await authHeaderToken();

      return axiosInstance.post('/componentes-politicas/' + id, value, {
        headers: headers,
      });
    } catch (error) {
      throw error;
    }
  }

  async postsuscriptoresMassive(value) {
    try {
      const headers = await authHeaderToken();

      return axiosInstance.post('/suscriptores-masivo', value, {
        headers: headers,
      });
    } catch (error) {
      throw error;
    }
  }

  async postNotificacion(value) {
    try {
      const headers = await authHeaderToken();

      return axiosInstance.post('/notificacion', value, {
        headers: headers,
      });
    } catch (error) {
      throw error;
    }
  }

  async postComponents(value) {
    try {
      const headers = await authHeaderToken();

      return axiosInstance.post('/componentes', value, {
        headers: headers,
      });
    } catch (error) {
      throw error;
    }
  }

  async postIncidenteComponents(id, value) {
    try {
      const headers = await authHeaderToken();

      return axiosInstance.post('/incidentes-componentes/' + id, value, {
        headers: headers,
      });
    } catch (error) {
      throw error;
    }
  }

  async deleteIncidentes(id) {
    try {
      const headers = await authHeaderToken();

      return axiosInstance.delete('/incidentes/' + id, {
        headers: headers,
      });
    } catch (error) {
      throw error;
    }
  }

  async deleteSuscriotion(id) {
    try {
      const headers = await authHeaderToken();

      return axiosInstance.delete('/suscriptores/' + id, {
        headers: headers,
      });
    } catch (error) {
      throw error;
    }
  }

  async deleteComponentesPoliticas(id, value) {
    try {
      const headers = await authHeaderToken();

      return axiosInstance.delete('/componentes-politicas/' + id + '/' + value, {
        headers: headers,
      });
    } catch (error) {
      throw error;
    }
  }

  async deleteComponents(id) {
    try {
      const headers = await authHeaderToken();

      return axiosInstance.delete('/componentes/' + id, {
        headers: headers,
      });
    } catch (error) {
      throw error;
    }
  }

  async deleteMantenimientos(id) {
    try {
      const headers = await authHeaderToken();

      return axiosInstance.delete('/mantenimientos/' + id, {
        headers: headers,
      });
    } catch (error) {
      throw error;
    }
  }

  async deleteComponentIncident(idIncident, idComponent) {
    try {
      const headers = await authHeaderToken();

      return axiosInstance.delete('/incidentes-componentes/' + idIncident + '/' + idComponent, {
        headers: headers,
      });
    } catch (error) {
      throw error;
    }
  }

  async getSuscriptores(config = {}) {
    try {
      // Espera a que se resuelva la promesa de authHeaderToken()
      const headers = await authHeaderToken();

      // Realiza la solicitud HTTP con los encabezados obtenidos
      return axiosInstance.get('/suscriptores', {
        headers: headers,
        ...config,
      });
    } catch (error) {
      // Maneja los errores aquí si es necesario
      throw error;
    }
  }

  async getPolices() {
    try {
      const headers = await authHeaderToken();

      return axiosInstance.get('/politicas', {
        headers: headers
      });
    } catch (error) {
      // Maneja los errores aquí si es necesario
      throw error;
    }
  }

  async getComponentesPoliticas(id) {
    try {
      const headers = await authHeaderToken();

      return axiosInstance.get('/componentes-politicas/' + id, {
        headers: headers
      });
    } catch (error) {
      // Maneja los errores aquí si es necesario
      throw error;
    }
  }

  async getIncidenteComponents(id) {
    try {
      const headers = await authHeaderToken();

      return axiosInstance.get('/incidentes-componentes/' + id, {
        headers: headers
      });
    } catch (error) {
      // Maneja los errores aquí si es necesario
      throw error;
    }
  }

  async patchSuscriptorID(id, value) {
    try {
      const headers = await authHeaderToken();

      return axiosInstance.patch('/suscriptores/' + id, value, {
        headers: headers
      });
    } catch (error) {
      // Maneja los errores aquí si es necesario
      throw error;
    }
  }

  async patchSuscriptor(value) {
    try {
      const headers = await authHeaderToken();

      return axiosInstance.patch('/suscriptores', value, {
        headers: headers
      });
    } catch (error) {
      // Maneja los errores aquí si es necesario
      throw error;
    }
  }

  getPlataformas() {
    return axiosInstance.get('/plataformas', {
      headers: authHeader()
    })
  }

  getHistoricoComponents(id) {
    return axiosInstance.get('/historico/' + id, {
      headers: authHeader()
    })
  }

  getGrupos(config = {}) {
    return axiosInstance.get('/grupos', {
      headers: authHeader(),
      ...config
    })
  }

  getGruposSubgrupos(id) {
    return axiosInstance.get('/grupos-subgrupos/' + id, {
      headers: authHeader()
    })
  }

  getSubgruposComponents(id) {
    return axiosInstance.get('/subgrupos-componentes/' + id, {
      headers: authHeader()
    })
  }

  getIncidentes(key) {
    return axiosInstance.get('/incidentes?' + key, {
      headers: authHeader()
    })
  }

  getActualizacionesIncidentes(id) {
    return axiosInstance.get('/incidentes-actualizaciones/' + id, {
      headers: authHeader()
    })
  }

  postSuscriptores(value) {
    return axiosInstance.post('/suscriptores', value
      , { headers: authHeader() })
  }
  getIncidentesId(id) {
    return axiosInstance.get('/incidentes/' + id, {
      headers: authHeader()
    })
  }

  getComponents(config = {}) {
    return axiosInstance.get('/componentes', {
      headers: authHeader(),
      ...config
    })
  }

  getComponentId(id) {
    return axiosInstance.get('/componentes/' + id, {
      headers: authHeader()
    })
  }
  
 
}

export default new AuthService()
