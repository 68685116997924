import React, { useState } from 'react';
import { Modal, FormGroup, Form, Button } from 'react-bootstrap';

const ModalAddTemplate = (props) => {
  const [selectedOption, setSelectedOption] = useState({ value: '', label: '' });

  const handleInputChangeCheck = (event) => {
    const target = event.target;
    const value = target.id;
    const label = target.labels[0].innerText;
    setSelectedOption({ value, label });
  };
  const handleValueAddtemplate = () => {
    props.handleValueAddtemplate(selectedOption.label);
    props.handleModalShowHideAddtemplate();
  };
  const options = [
    {
      type: 'radio',
      label: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tellus est, luctus sed nisl eget, tempus ultricies turpis. Nunc vehicula diam sed condimentum sodales. Curabitur sed tempus turpis. Mauris sagittis sollicitudin ligula vitae maximus. Phasellus porta lacus ornare ante sagittis, ut feugiat eros mollis.',
      className: `btn__checkbox  mb-4 active ${selectedOption.value === 'textA' ? 'active' : ''}`,
      name: 'message',
      checked: selectedOption.value === 'textA',
      onChange: handleInputChangeCheck,
      id: 'textA'
    },
    {
      type: 'radio',
      label: 'Nullam quis dictum elit, in egestas sapien. Vestibulum id enim eu mauris lobortis feugiat sit amet ornare lectus. In fringilla gravida erat non efficitur. Vestibulum convallis molestie posuere.',
      className: `btn__checkbox  mb-4 active ${selectedOption.value === 'textB' ? 'active' : ''}`,
      name: 'message',
      checked: selectedOption.value === 'textB',
      onChange: handleInputChangeCheck,
      id: 'textB'
    },
    {
      type: 'radio',
      label: `Nombre del incidente
              Fecha del incidente
              Nuevo incidente X
              13/06/2022 10:00
              Descripción del incidente
              Morbi feugiat semper dolor in molestie. Proin ullamcorper, turpis id finibus venenatis, diam velit porta ligula, vitae fermentum mi dolor sodales nibh. Nunc lobortis convallis sem nec tempus. Pellentesque non dolor eu lorem aliquet pellentesque. Duis vitae malesuada nisl. Fusce vel interdum neque.
              Escribe o selecciona una plantilla
              Usar plantilla
              Criticidad
              Estado
              Selecciona una opción
              Selecciona una opción
              Descripción del estado
              Escribe una descripción`,
      className: `btn__checkbox  mb-4 active ${selectedOption.value === 'textC' ? 'active' : ''}`,
      name: 'message',
      checked: selectedOption.value === 'textC',
      onChange: handleInputChangeCheck,
      id: 'textC'
    }
  ];

  return (
    <div>
    <Modal show={props.showHideAddtemplate} data-testid="modal-add-template">
      <Modal.Header onClick={props.handleModalShowHideAddtemplate} />
      <Modal.Body>
        <article>
          <h4 className="modal__title">Selecciona una plantilla</h4>
          <p className="card__form__description">
            Escoge un texto sugerido. Recuerda que puedes editarlo luego de escogerlo.
          </p>
        </article>
        <FormGroup className="mb-4">
          {options.map((option, index) => (
            <Form.Check
              key={index}
              data-testid={option.id}
              type="radio"
              label={option.label}
              inline
              className={option.className}
              name={option.name}
              checked={option.checked}
              onChange={option.onChange}
              id={option.id}
            />
          ))}
        </FormGroup>
        <div className="d-flex justify-content-end modal__btn mb-0" >

          <Button onClick={props.handleModalShowHideAddtemplate} className="btn btn-extra" data-testid="back-btn">
            <i className="mr-10 icon-arrow-left" />
            Volver
          </Button>
          {selectedOption !== null ? (
            <Button className='ml-24' onClick={() => handleValueAddtemplate()} data-testid="accept-btn">
              Aceptar
            </Button>
          ) : (
            <Button className='ml-24' variant="secondary">
              Aceptar
            </Button>
          )}</div>
      </Modal.Body>
    </Modal>
    </div>
  );
};

export default ModalAddTemplate;
