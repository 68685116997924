import React from 'react'
import Logo from '../../../img/logo3.png'
import ModalSuscribe from './modalSubscribe'

class Header extends React.Component {
  constructor () {
    super()
    this.state = {
      showHide: false
    }
    this.handleModalShowHide = this.handleModalShowHide.bind(this)
  }

  handleModalShowHide () {
    this.setState({ showHide: !this.state.showHide })
  }

  render () {
    return (
            <React.Fragment>
                <ModalSuscribe
                    showHide={this.state.showHide}
                    handleModalShowHide={this.handleModalShowHide}

                />
                <header data-testid="header" className="header" >
                    <div className="header__container content">
                        <div className="header__logo" >
                            <img src={Logo} alt="Logo" />
                        </div>
                        <div className="header__btns" >
                            <div className=" header__btn header__btn--mobile" onClick={() => this.handleModalShowHide()} >
                                Suscríbete
                            </div>
                            <div className=" header__btn header__btn--desktop" onClick={() => this.handleModalShowHide()} >
                                Suscríbete a las actualizaciones
                            </div>
                        </div>
                    </div>
                </header>

            </React.Fragment>
    )
  }
}

export default Header
