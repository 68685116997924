import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Check from '../../../img/c-check.png';
import AuthService from '../../../services/auth.service';
import { getTimeHourFormat } from '../../../utils/timeHourFormat';
import CardDeleteComponent from './cardDeleteComponent';
import CardAddComponent from './cardAddComponent';
import DatePicker from 'react-datepicker';
import Moment from 'moment';
Moment.locale('es');

const ModalEditUpdateIncident = (props) => {
  const [name, setName] = useState('');
  const [status, setStatus] = useState('');
  const [impact, setImpact] = useState('');
  const [message, setMessage] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [validate, setValidate] = useState(false);
  const [componentActual, setComponentActual] = useState([]);
  const [success, setSuccess] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loadercomponentActual, setLoaderComponentActual] = useState(false);
  const [loadercomponentActualActive, setLoaderComponentActualActive] = useState(false);
  const [published, setPublished] = useState(false); // Agregado el estado "published" con valor inicial false

  useEffect(() => {
    handleClickgetComponentActual();
  }, []);

  useEffect(() => {
    const dateTimeHourFormat = getTimeHourFormat(props.item.dateTimeHour);
    setName(props.item.name);
    setStatus(props.item.status);
    setImpact(!props.item.impact ? 'Warning' : props.item.impact);
    setMessage(props.item.message);
    setPublished(props.item.published);
    setStartDate(dateTimeHourFormat);
  }, [props.item]);

  const handleClickgetComponentActual = (loader) => {
    if (loader) {
      setLoaderComponentActual(true);
      setLoaderComponentActualActive(true);
    }

    AuthService.getIncidenteComponents(props.item?.SK)
      .then((response) => {
        setComponentActual(response.data.data.Items);
        setLoaderComponentActual(false);

      })
      .catch((error) => {
        // Handle error
        setLoaderComponentActual(false);
      });
  };

  const handleModalShowHide = (value) => {
    setSuccess(false);
    props.handleModalShowHide(value);
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    // Update the corresponding state based on the input name
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'status':
        setStatus(value);
        break;
      case 'impact':
        setImpact(value);
        break;
      case 'message':
        setMessage(value);
        break;
      default:
        break;
    }

    setValidate(true);
  };

  const handleInputChangeCheck = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.checked;

    // Update the corresponding state based on the input name
    switch (name) {
      case 'published':
        setPublished(value);
        break;
      default:
        break;
    }

    setValidate(true);
  };

  const handleSubmit = async () => {
    try {
      setLoader(true);
      const obj = {
        id: props.item?.SK,
        name: name ?? props.item?.name,
        status: status ?? props.item?.status,
        impact: impact ?? props.item?.impact,
        message: message ?? props.item?.message,
        dateTimeHour: Moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        published: !!published,
      };

      await AuthService.potIncidente(obj);

      try {
        await AuthService.postNotificacion({
          id: props.item?.SK,
        });
      } catch (error) {
        setValidate(false);
        setLoader(false);
      }

      setSuccess(true);
      setValidate(false);
      setLoader(false);
    } catch (error) {
      // Handle error for AuthService.potIncidente
      setLoader(false);
    }
  };


  const handleChangeDate = (date) => {
    setStartDate(date);
    setValidate(true);
  };

  return (
    <div data-testid="modal-update">
      <Modal show={props.showHide}>
        <Modal.Header closeButton onClick={() => handleModalShowHide(loadercomponentActualActive || success)}></Modal.Header>
        <Modal.Body>
          {success ? (
            <Container>
              <Row>
                <Col className="success">
                  <img className="mb-4" src={Check} alt={Check} />
                  <div className="success__text modal__title">Incidente editado</div>
                </Col>
              </Row>
            </Container>
          ) : (
            <Container>
              {loader ? (
                <div className="loader__container">
                  <div className="loader"></div>
                </div>
              ) : (
                <React.Fragment>
                  <h4 className="modal__title">Editar o publicar el incidente</h4>
                  <Form>
                    <Row className="justify-content-md-center">
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formGroupEmail">
                          <Form.Label>Nombre del incidente</Form.Label>
                          <Form.Control
                            defaultValue={props.item?.name}
                            type="text"
                            onChange={handleInputChange}
                            name="name"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label htmlFor="1">Fecha del incidente</Form.Label>
                          <DatePicker
                            id="1"
                            className="form-select"
                            selected={startDate}
                            onChange={handleChangeDate}
                            showTimeSelect
                            timeFormat="HH:mm"
                            dateFormat="dd/MM/yyyy HH:mm"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Descripción del incidente</Form.Label>
                          <Form.Control
                            defaultValue={props.item?.message}
                            type="text"
                            onChange={handleInputChange}
                            name="message"
                            aria-label="Descripción del incidente"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Criticidad</Form.Label>
                          <Form.Select
                            defaultValue={impact === 'Warning' ? 'Warning' : 'Critical'}
                            onChange={handleInputChange}
                            name="impact"
                            aria-label="Criticidad"
                          >
                            <option disabled>Seleccione una opción</option>
                            <option value="Warning">Alerta</option>
                            <option value="Critical">Crítico</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      {loadercomponentActual ? (
                        <div className="loader__container">
                          <div className="loader"></div>
                        </div>
                      ) : (
                        <Row className='p-0'>
                          <Col md={6}>
                            <Row>
                              <Col md={7}>
                                <Form.Label>
                                  Publicar incidente{' '}
                                  {componentActual.length === 0 && (
                                    <span>
                                      <br />
                                      <strong> (Asocia mínimo una aplicación)</strong>
                                    </span>
                                  )}
                                </Form.Label>
                                <Form.Group className="mb-3">
                                  <Form.Check
                                    inline
                                    disabled={componentActual.length === 0}
                                    label="Publicado"
                                    className={`btn__checkbox active`}
                                    name="published"
                                    type="checkbox"
                                    checked={componentActual.length === 0 ? false : published}
                                    onChange={handleInputChangeCheck}
                                    id={'check'}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={5}>
                                <CardAddComponent reset={handleClickgetComponentActual} parent={props.item?.SK} />
                              </Col>
                            </Row>
                          </Col>
                          <Col md={12}>
                            <Col md={6}>
                              <Form.Label>Aplicaciones asociadas</Form.Label>
                            </Col>
                            <Col md={6} />
                            {componentActual.map((item, i) => (
                              <Col key={i} md={6}>
                                <CardDeleteComponent
                                  reset={handleClickgetComponentActual}
                                  parent={props.item?.SK}
                                  length={componentActual.length}
                                  item={item}
                                  obj={{
                                    id: props.item?.SK,
                                    name: name ?? props.item?.name,
                                    status: status ?? props.item?.status,
                                    impact: impact ?? props.item?.impact,
                                    message: message ?? props.item?.message,
                                    dateTimeHour: Moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
                                    published: !!published,
                                  }}
                                />
                              </Col>
                            ))}
                          </Col>
                        </Row>
                      )}

                      <Col md={6} />
                      <Row className="d-flex justify-content-end modal__btn mb-0">
                        <Button
                          className="btn btn-extra"
                          variant="outline-secondary"
                          onClick={() => handleModalShowHide()}
                        >
                          Cancelar
                        </Button>
                        {validate ? (
                          <Button
                            className="ml-24"
                            variant="primary"
                            onClick={() => handleSubmit()}
                            data-testid="update-btn"
                          >
                            Guardar cambios
                          </Button>
                        ) : (
                          <Button className="ml-24" variant="secondary" data-testid="update-btn">
                            Guardar cambios
                          </Button>
                        )}
                      </Row>
                    </Row>
                  </Form>
                </React.Fragment>
              )}
            </Container>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalEditUpdateIncident;