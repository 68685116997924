import React from 'react'
import { Modal } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Check from '../../img/c-check.png'

import 'react-datepicker/dist/react-datepicker.css'
import Moment from 'moment'

Moment.locale('es')
class ModalLoader extends React.Component {
  constructor () {
    super()
    this.handleModalShowHide = this.handleModalShowHide.bind(this)
  }

  handleModalShowHide (value) {
    this.props.handleModalShowHide(value)
  }

  render () {
    return (
            <div data-testid="modal-loader">
                <Modal className='modal__delete ' show={this.props.showHide}>
                    {this.props.success
                      ? <Modal.Header data-testid="close-success" closeButton onClick={() => this.handleModalShowHide(true)}>
                        </Modal.Header>
                      : <Modal.Header data-testid="close" closeButton onClick={() => this.handleModalShowHide()}>

                        </Modal.Header>}
                    <Modal.Body >
                        {this.props.success
                          ? <Container>
                                <Row>
                                    <Col className="success">

                                        <img className="mb-4" src={Check} alt={Check} />

                                        <div className='success__text modal__title'>Los cambios fueron guardados con éxito</div>
                                    </Col>
                                </Row>
                            </Container>
                          : <Container>
                                <div data-testid="loader" className="loader__container">
                                    <div className="loader"></div>
                                </div>
                            </Container>
                        }
                    </Modal.Body>

                </Modal>

            </div >
    )
  }
}

export default ModalLoader
