import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import My404Component from '../typeUsers/admin/My404Component';
import IHistory from '../typeUsers/admin/incident_history';
import ScheduledMaintenance from '../typeUsers/admin/scheduled_maintenance';
import ServiceStatus from '../typeUsers/admin/service_status';
import Suscribe from '../typeUsers/admin/suscribe';
import Layout from './layout/admin/index';

const AdminRouter = () => {
  return (
    <Router>
      <Layout>
        <Switch>
          <Route path="/admin" exact component={ServiceStatus} />
          <Route path="/admin/incident_history" exact component={IHistory} />
          <Route path="/admin/maintenance_history" exact component={ScheduledMaintenance} />
          <Route path="/admin/state_service" exact component={ServiceStatus} />
          <Route path="/admin/suscribe" exact component={Suscribe} />
          <Route component={My404Component} /> {/* 404 route */}
        </Switch>
      </Layout>
    </Router>
  );
};

export default AdminRouter;
