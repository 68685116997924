import React, { useEffect, useState } from 'react';
import Index from '../../pages/incident_history/index';
import AuthService from '../../services/auth.service';
import './i18n.js';
import '../../css/app.scss';


const IHistory = (props) => {
  const [aplication, setAplication] = useState([]);
  const [textError, setTextError] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      const aplicationResponse = await AuthService.getComponents({ simplify: true });
      setAplication(aplicationResponse.Items);
      setIsLoading(false);
    } catch (error) {
      setAplication([]);
      setTextError('Oops, algo salió mal, intenta refrescar la página o volver a iniciar sesión.');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment >
      {isLoading ? (
        <div className="loader__container">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          {aplication.length > 0 ? (
            <Index
              admin={true}
              aplication={aplication}
            />
          ) : (
            <div>{textError}</div>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default IHistory
