import React from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ModalEdit from './modalEdit'
import ModalTitleUpdate from './modalTitleUpdate'

import AuthService from '../../../services/auth.service'


import 'react-datepicker/dist/react-datepicker.css'
import Moment from 'moment'
Moment.locale('es')

class ModalUpdateIncident extends React.Component {
  constructor() {
    super()
    this.state = {
      id: ' ',
      status: '',
      message: '',
      loader: false,
      success: false,
      startDate: new Date(),
      

    }

    this.handleModalShowHide = this.handleModalShowHideUpdate.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangeDate = this.handleChangeDate.bind(this)
    this.handleChangeDateState = this.handleChangeDateState.bind(this)
  }

  handleSubmit() {
    this.setState({
      loader: true,
      success: false

    })
    const obj = {
      status: this.state.status,
      message: this.state.message,
      dateTimeHour: Moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss'),

    }
    AuthService.putActualizacionIncidente(this.props.item?.PK, { ...obj })
      .then(
        _response => {
          if (obj.status === 'Resolved') {
            AuthService.postNotificacion({
              id: this.props.item?.PK,
              status: this.state.status

            })

              .then(
                _response => {
                  this.setState({
                    success: true,
                    loader: false
                  })
                }

              )
          } else {
            this.setState({
              success: true,
              loader: false
            })
          }
        },
        error => {

        }
      )
  }

  handleModalShowHideUpdate(value) {
    
    this.props.handleModalShowHide(value)
    this.setState({
      success: false,
      status: '',
      message: '',
    })
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
      
    })
  }

  handleChangeDate(date) {
    this.setState({
      startDate: date,
      
    })
  }

  handleChangeDateState(date) {
    this.setState({
      startDateState: date
    })
  }

  render() {
    return (
      <div data-testid="modal-incident-update">
        <Modal show={this.props.showHide}>
          {this.state.success
            ? <Modal.Header closeButton onClick={() => this.handleModalShowHideUpdate(true)} data-testid="close-Btn">
            </Modal.Header>
            : <Modal.Header closeButton onClick={() => this.handleModalShowHideUpdate()} data-testid="close-Btn">
            </Modal.Header>}
          <Modal.Body>
            {this.state.success
              ? <ModalTitleUpdate />
              : <Container>
                {this.state.loader
                  ? <div className="loader__container">
                    <div className="loader"></div>
                  </div>
                  : <React.Fragment>
                    <h4 className="modal__title">Actualizar estado del incidente</h4>
                    <Form >
                      <Row className="justify-content-md-center">
                        <ModalEdit handleSubmit={this.handleInputChange}
                          handleChangeDate={this.handleChangeDate}
                          startDate={this.state.startDate} />
                        <Col sm={12}>

                          <Form.Group className="mb-3" >
                            <Form.Label>Descripción </Form.Label>
                            <Form.Control type="text" onChange={this.handleInputChange} name='message' aria-label="Descripción" />
                          </Form.Group>
                        </Col>

                        <Row className="d-flex justify-content-end modal__btn mb-0">
                          <Button  className='btn btn-extra' variant="outline-secondary" onClick={() => this.handleModalShowHideUpdate()}>Cancelar</Button>
                          {
                            (this.state.message && this.state.status
                            )
                              ? <Button className='ml-24' variant="primary" onClick={() => this.handleSubmit()}>
                                Actualizar incidente
                              </Button>
                              : <Button className="ml-24" variant="secondary"> Actualizar incidente</Button>
                          }
                        </Row>

                      </Row>
                    </Form>
                  </React.Fragment>
                }
              </Container>
            }
          </Modal.Body>

        </Modal>

      </div >
    )
  }
}

export default ModalUpdateIncident
