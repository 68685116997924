import React, { useState } from 'react';
import Moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { Modal } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Check from '../../../img/c-check.png';

import AuthService from '../../../services/auth.service';
import ModalDetache from './modalDetache';

Moment.locale('es');

const ModalDeleteIncidentComponent = ({ showHide, handleModalShowHide, parent, item, length, obj }) => {
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
    setLoader(true);
    try {
      await AuthService.deleteComponentIncident(parent, item?.SK);

      setSuccess(true);
      setLoader(false);

      if (length === 1) {
        await AuthService.potIncidente({ ...obj, published: false });
        await AuthService.postNotificacion({ id: item?.SK });
      }
    } catch (error) {
      // Handle API request error appropriately
      console.error('Error occurred during API request:', error);
      setLoader(false);
    }
  };

  const handleModalShowHideDetache = (value) => {
    handleModalShowHide(value);
    setSuccess(false);
  };

  return (
    <div>
      <Modal className="modal__delete modal__box" show={showHide}>
        <Modal.Header closeButton onClick={() => handleModalShowHideDetache(success)} data-testid="close-btn">
        </Modal.Header>
        <Modal.Body>
          {success ? (
            <Container>
              <Row>
                <Col className="success">
                  <img className="mb-4" src={Check} alt={Check} />
                  <div className="success__text modal__title">Aplicación desvinculada</div>
                </Col>
              </Row>
            </Container>
          ) : (
            <Container>
              {loader ? (
                <div className="loader__container">
                  <div className="loader"></div>
                </div>
              ) : (
                <ModalDetache
                  item={item}
                  handleSubmit={handleSubmit}
                  handleModalShowHide={() =>{ handleModalShowHide(false)  }}
                  modalTitle="¿Confirma que desea que esta aplicación deje de estar asociada a este incidente?"
                />
              )}
            </Container>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalDeleteIncidentComponent;
