import Index from '../../pages/service_status/index'

import React from 'react'
import AuthService from '../../services/auth.service'
import '../../css/app.scss'

class ServiceStatus extends React.Component {
  constructor () {
    super()
    this.state = {
      data: [],
      incident: [],
      maintenance: [],
      loader: false

    }

    this.AllDataComponent = this.AllDataComponent.bind(this)
  }

  componentDidMount () {
    this.AllDataComponent()
  }

  async AllDataComponent () {
    this.setState({
      loader: true

    })
    try {
      const [
        grupo,
        incidente,
        mantenimientos
      ] = await Promise.all([
        AuthService.getGrupos(),
        AuthService.getIncidentes('published=true+&limit=5'),
        AuthService.getmantenimientos()
      ])
      const allGrupo = grupo.data.data.Items
      const allIncidente = incidente.data.data.Items
      const allMantenimientos = mantenimientos.data.data.Items
      this.setState({
        maintenance: allMantenimientos,
        data: allGrupo,
        incident: allIncidente,
        loader: false

      })
    } catch (error) {
      this.setState({
        maintenance: [],
        data: [],
        incident: [],
        loader: false

      })
    }
  }

  render () {
    return (
      <React.Fragment>
        {this.state.data &&
          <Index
            callAllDataComponent={this.AllDataComponent}
            AllDataComponent={this.state.data}
            AllDataIncident={this.state.incident}
            Maintenance={this.state.maintenance}
            loader={this.state.loader}
          />}
      </React.Fragment>
    )
  }
}

export default ServiceStatus
