import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Check from '../../../img/c-check.png'
import Container from 'react-bootstrap/Container'

function ModalTitleUpdate() {
    return (
        <Container>
            <Row>
                <Col className="success">

                    <img className="mb-4" src={Check} alt={Check} />

                    <div className='success__text modal__title'>Incidente actualizado</div>
                </Col>
            </Row>
        </Container>
    )
}

export default ModalTitleUpdate
