import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Check from '../../../img/c-check.png';
import AuthService from '../../../services/auth.service';
import 'react-datepicker/dist/react-datepicker.css';
import Moment from 'moment';
Moment.locale('es');

class ModalAddIncidentComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      component: '',
      success: false,
      validate: false,
      loader: false,
    };
    this.handleModalShowHide = this.handleModalShowHide.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleSubmit() {
    this.setState({
      loader: true,
    });
    AuthService.postIncidenteComponents(this.props.parent,{
      id: this.state.component 
    } )
      .then(
        (response) => {
          this.setState({
            success: true,
            loader: false,
            validate: false,
          });
        },
        (error) => {
          // Manejar el error aquí
        }
      );
  }

  handleModalShowHide(value) {
    this.props.handleModalShowHide(value);
    this.setState({
      success: false,
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
      validate: true,
    });
  }

  render() {
    return (
      <div>
        <Modal className="modal__delete modal__box" show={this.props.showHide}>
          {this.state.success ? (
            <Modal.Header closeButton onClick={() => this.handleModalShowHide(true)} data-testid="closeButton" />
          ) : (
            <Modal.Header closeButton onClick={() => this.handleModalShowHide()} data-testid="closeButton" />
          )}
          <Modal.Body>
            {this.state.success ? (
              <Container>
                <Row>
                  <Col className="success">
                    <img className="mb-4" src={Check} alt={Check} />
                    <div className="success__text modal__title">Aplicación asociada al incidente</div>
                  </Col>
                </Row>
              </Container>
            ) : (
              <Container>
                {this.state.loader ? (
                  <div className="loader__container">
                    <div className="loader" />
                  </div>
                ) : (
                  <React.Fragment>
                    <h4 className="modal__title">Asociar incidente a la aplicación</h4>
                    <Col md={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Selecciona aplicación</Form.Label>
                        <Form.Select onChange={this.handleInputChange} name="component" aria-label="Selecciona aplicación">
                          <option>Seleccione una opción</option>
                          {this.props.item?.map((item, i) => (
                            <option disabled={item.select} key={i} value={item.SK}>
                              {item.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Row className="d-flex justify-content-end modal__btn mb-0">
                      <Button className="btn btn-extra" variant="outline-secondary" onClick={() => this.handleModalShowHide()}>
                        Cancelar
                      </Button>
                      {this.state.validate ? (
                        <Button className="ml-24" variant="primary" onClick={() => this.handleSubmit()} data-testid="create-btn">
                          Asociar
                        </Button>
                      ) : (
                        <Button className="ml-24" variant="secondary" data-testid="create-btn">
                          Asociar
                        </Button>
                      )}
                    </Row>
                  </React.Fragment>
                )}
              </Container>
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default ModalAddIncidentComponent;