import React from 'react'
import { Form } from 'react-bootstrap'
import AuthService from '../../../services/auth.service'

import BtnMore from '../../../img/plus_btn_32.png'

import ModalAddComponent from './addComponent'

class CardAddComponent extends React.Component {
  constructor () {
    super()
    this.state = {
      components: [],

      showHide: false

    }

    this.handleClickgetComponents = this.handleClickgetComponents.bind(this)
    this.handleModalShowHide = this.handleModalShowHide.bind(this)
  }

  componentDidMount () {
    this.handleClickgetComponents()
  }

  handleClickgetComponents () {
    AuthService.getComponents()
      .then(

        response => {
          this.setState({
            components: response.data.data.Items
          })
        }

      )
  }

  handleModalShowHide (loader) {
    this.setState({ showHide: !this.state.showHide })
    if (loader) {
      this.props.reset(loader)
    }
  }

  render () {
    return (
            <React.Fragment>
                <ModalAddComponent
                    item={this.state.components}
                    showHide={this.state.showHide}
                    handleModalShowHide={this.handleModalShowHide}
                    parent={this.props.parent}
                    aria-label="Selecciona aplicación"/>
                <Form.Label>Asociar aplicación</Form.Label>
                <Form.Group onClick={() => this.handleModalShowHide()} className="mb-3" data-testid="add-btn">
                    <img className='pointer' src={BtnMore} alt={BtnMore} />
                </Form.Group>

            </React.Fragment>
    )
  }
}

export default CardAddComponent
