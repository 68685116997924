import CardItemGroup from '../../components/card/aplication/listItemGroups'
import ModalDelete from '../../components/modal/aplication/delete'
import ModalUpdate from '../../components/modal/aplication/update'

import React from 'react'
import '../../../src/css/app.scss'

class Body extends React.Component {
  constructor () {
    super()
    this.state = {
      showHideDelete: false,
      showHideUpdate: false
    }
    this.handleModalShowHidDeleteBody = this.handleModalShowHidDeleteBody.bind(this)
    this.handleModalShowHidUpdate = this.handleModalShowHidUpdate.bind(this)
  }

  handleModalShowHidDeleteBody (loader) {
    this.setState({ showHideDelete: !this.state.showHideDelete })

    if (loader) {
      this.props.ResetAllDataComponet(loader)
    }
  }

  handleModalShowHidUpdate (loader) {
    this.setState({ showHideUpdate: !this.state.showHideUpdate })

    if (loader) {
      this.props.ResetAllDataComponet(loader)
    }
  }

  render () {
    return (
      <React.Fragment>
        <CardItemGroup
          item={this.props.item}
          btnFuntionDelete={this.handleModalShowHidDeleteBody}
          btnFuntion={this.handleModalShowHidUpdate}
          ResetAllDataComponet={this.props.ResetAllDataComponet}
          admin={this.props.admin}
        />
        {this.props.admin &&
          <React.Fragment>
            <ModalDelete
              item={this.props.item}
              showHide={this.state.showHideDelete}
              handleModalShowHide={this.handleModalShowHidDeleteBody}
              name='grupo'
            />
            <ModalUpdate
              item={this.props.item}
              showHide={this.state.showHideUpdate}
              handleModalShowHide={this.handleModalShowHidUpdate}
              name='grupo'
            />
          </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

export default Body
