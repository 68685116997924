import React from 'react'
import Logo from '../../../img/logo3.png'

class Header extends React.Component {
  signOut () {
    this.props.signOut()
    localStorage.removeItem('token')
  }

  render () {
    return (
            <React.Fragment>
                <header data-testid="header-component" className="header" >
                    <div className="header__container content">
                        <div className="header__logo" >
                            <img src={Logo} alt="Logo" />
                        </div>
                        <div className="header__btns" >
                            <div data-testid="sign-out-button" className="btn btn__logout" onClick={() => this.signOut()} >
                            Cerrar sesión
                            </div>

                        </div>
                    </div>
                </header>

            </React.Fragment>
    )
  }
}

export default Header
