import React from 'react';
import { Button, Row } from 'react-bootstrap';

function ModalDetache({ modalTitle, item, handleSubmit, handleModalShowHide }) {
  return (
    <>
      <h4 className="modal__title pr-15">{modalTitle}</h4>
      <div className="card__list__container">
        <div className="accordion__heading">
          <div className="card__list__top">
            <div className="card__list__body">
              <div className="card__list__title">{item?.name}</div>
              <div className="card__list__text">{item?.message}</div>
            </div>
          </div>
        </div>
      </div>
      <Row className="d-flex justify-content-end modal__btn mb-0">
        <Button className='btn btn-extra' variant="outline-secondary"
        onClick={handleModalShowHide}
        >Cancelar</Button>
        <Button className='ml-24'
          variant="primary"
          onClick={handleSubmit}

          data-testid="confirm-btn">
          Confirmar
        </Button>
      </Row>
    </>
  );
}

export default ModalDetache;