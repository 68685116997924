import Index from '../../pages/scheduled_maintenance/index'
import React from 'react'
import './i18n.js'
import '../../css/app.scss'
class ScheduledMaintenance extends React.Component {
  render() {
    return (
      <React.Fragment >
        <Index
          admin={true}
        />
      </React.Fragment>
    )
  }
}

export default ScheduledMaintenance
