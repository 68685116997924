import React from 'react'
import Header from './header'
import Footer from './footer'
import Nav from './nav'
import BannerA from '../../../img/BANNER-01-min.png'
import BannerB from '../../../img/BANNER-02-min.png'
import BannerC from '../../../img/BANNER-03-min.png'
import BannerD from '../../../img/BANNER-04-min.png'
import BannerE from '../../../img/BANNER-05-min.png'
import BannerF from '../../../img/BANNER-06-min.png'

import '../../../css/app.scss'

class Layout extends React.Component {
  render () {
    return (
            <React.Fragment>
                <Header />
                <Nav />
                <div  data-testid="banner" className='banner a'>
                    <img src={BannerA} alt="Banner" />

                </div>
                <div className='banner b'>
                    <img src={BannerB} alt="Banner" />

                </div>
                <div className='banner c'>
                    <img src={BannerC} alt="Banner" />

                </div>
                <div className='banner d'>
                    <img src={BannerD} alt="Banner" />

                </div>
                <div className='banner e'>
                    <img src={BannerE} alt="Banner" />

                </div>
                <div className='banner f'>
                    <img src={BannerF} alt="Banner" />

                </div>
                <div className="content body">
                    {this.props.children}
                </div>
                <Footer />
            </React.Fragment>
    )
  }
}

export default Layout
