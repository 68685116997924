import { Auth } from 'aws-amplify';

let cachedHeaders = null;

// Función para obtener el token de Cognito y almacenarlo en el localStorage
const getData = async () => {
  try {
    const [res] = await Promise.all([Auth.currentSession()]);
    const accessToken = res.getIdToken();
    const jwt = accessToken.getJwtToken();

    localStorage.setItem('token', jwt); // Almacenar el token JWT directamente, no como JSON

  } catch (error) {
    console.log(error, 'error');
    // Manejar errores aquí si es necesario
  }
};

// Función para obtener los encabezados de autorización
const authHeaderToken = async () => {
  if (!cachedHeaders) {
    await getData(); // Asegurarse de que el token esté disponible
    const admin = localStorage.getItem('token');
    if (admin) {
      const adminReplace = admin.replace(/['"]+/g, '');
      cachedHeaders = {
        Authorization: adminReplace,
        Accept: '*/*',
      };
    } else {
      cachedHeaders = {};
    }
  }
  return cachedHeaders;
};

export default authHeaderToken;
