import React from 'react'
import PropTypes from 'prop-types'

import { Button, Modal, Form } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Check from '../../../img/c-check.png'

import AuthService from '../../../services/auth.service'

import 'react-datepicker/dist/react-datepicker.css'
import Moment from 'moment'

Moment.locale('es')
class ModalAddIncidentComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      police: [],
      success: false,
      validate: false,
      loader: false
    }

    this.handleModalShowHide = this.handleModalShowHide.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleSubmit() {
    this.setState({
      loader: true,
      success: false

    })

    const obj = {

      id: 'POL-' + (this.state.police.id)?.toString(),
      name: this.state.police.name
    }
    AuthService.postComponentesPoliticas(this.props.parent, obj)
      .then(
        response => {
          this.setState({
            success: true,
            loader: false,
            validate: false
          })
        },
        error => {
        }
      )
  }

  handleModalShowHide(value) {
    this.props.handleModalShowHide(value)
    this.setState({
      success: false
    })
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: this.props.item[value],
      validate: true
    })
  }

  render() {
    return (
      <div >
        <Modal data-testid="addPolice" className='modal__delete modal__box' show={this.props.showHide}>
          {this.state.success
            ? <Modal.Header closeButton onClick={() => this.handleModalShowHide(true)}>
            </Modal.Header>
            : <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
            </Modal.Header>}
          <Modal.Body >
            {this.state.success
              ? <Container>
                <Row>
                  <Col className="success">

                    <img className="mb-4" src={Check} alt={Check} />

                    <div className='success__text modal__title'>Politica agregada al aplicación </div>
                  </Col>
                </Row>
              </Container>
              : <Container>
                {this.state.loader
                  ? <div className="loader__container">
                    <div className="loader"></div>
                  </div>
                  : <React.Fragment>
                    <h4 className="modal__title">Agregar política a la aplicación</h4>

                    <Col md={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Selecciona politica</Form.Label>

                        <Form.Select
                          data-testid="addPolice-select"
                          onChange={this.handleInputChange} name='police'>
                          <option >Seleccione una opción</option>
                          {

                            (this.props.item?.map((item, i) => {
                              return (
                                <option disabled={item.select} key={i}
                                  value={i}
                                >{item.name}</option>

                              )
                            })
                            )

                          }

                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Row className="d-flex justify-content-end modal__btn mb-0">
                      <Button className='btn btn-extra' variant="outline-secondary" onClick={() => this.handleModalShowHide()}>Cancelar</Button>

                      <Button className='ml-24' disabled={!this.state.validate} data-testid="addPolice-btn-active" variant={this.state.validate ? 'primary' : 'secondary'} onClick={() => this.handleSubmit()}>
                        Agregar
                      </Button>
                    </Row>

                  </React.Fragment>
                }
              </Container>
            }
          </Modal.Body>

        </Modal>

      </div >
    )
  }
}
ModalAddIncidentComponent.propTypes = {
  parent: PropTypes.any.isRequired,
  handleModalShowHide: PropTypes.func,
  item: PropTypes.any,
  showHide: PropTypes.bool
}
export default ModalAddIncidentComponent
