import React from "react";
import { Button } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { LogoLogin } from '../../../components/commonImports';

function LoginFederated(props) {
  return (
    <div data-testid="federated-sign-in-component" className="login__container">
      <div className="login__card">
      <div className="login__logo">
         <img src={LogoLogin}></img>
        </div>
        <div className="login__title">
          ¡HOLA!
        </div>
        <div className="login__description">
          Por favor, has clic para autenticarte.
        </div>
        <Button
          data-testid="login__btn"
          className="login__btn"
          block
          variant="primary"
          onClick={() =>
            Auth.federatedSignIn({ provider: props.federatedIdName })
          }
        >
          Federated Sign In
        </Button>
      </div>
    </div>

  );
}

export default LoginFederated;
