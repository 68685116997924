import React from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Check from '../../../img/c-check.png'
import UsuariosPrueba from '../../../img/usuarios_prueba.xlsx'
import SheetJSFT from './types'
import XLSX from 'xlsx'
import { make_cols } from './MakeColumns'

import AuthService from '../../../services/auth.service'

import 'react-datepicker/dist/react-datepicker.css'
import Moment from 'moment'

Moment.locale('es')
class ModalSuscribe extends React.Component {
  constructor() {
    super()
    this.state = {
      file: {},
      fileValidate: false,
      loader: false,
      error: false,
      errordata: '',

      data: [],
      cols: []
    }
    this.handleModalShowHide = this.handleModalShowHide.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleFile = this.handleFile.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handlePost = this.handlePost.bind(this)
  }

  handlePost(data) {
    this.setState({
      loader: true,
      success: false,
      error: false
    })
    AuthService.postsuscriptoresMassive(data
    )
      .then(
        response => {
          this.setState({
            success: true,
            loader: false
          })
        },
        error => {
          let arrayExcel = [];

          if (error.response && error.response.status === 400) {
            if (Array.isArray(error?.response?.data?.data)) {
              error?.response?.data?.data.forEach(excel => {
                let arrayFields = [];
                excel.fields.forEach(listFields => {
                  arrayFields.push(listFields)
                });
                let textFields = arrayFields.join(" - ")
                arrayExcel.push('Faltan datos de la fila ' + (excel.row + 2) + ' en ' + textFields)
              });
              this.setState({
                success: false,
                error: true,
                errordata: arrayExcel
              })
            } else {
              this.setState({
                success: false,
                error: true,
                errordata: error?.response?.data?.data
              })
            }

          }

        }
      )
  }

  handleSubmit(data) {
    const users = data
    const usersSuscribe = {
      data: [

      ]
    }
    users.forEach(user => {
      const obj = {
        name: user.NOMBRE,
        email: user.CORREO,
        code: user.CODIGO,
        active: 1

      }
      usersSuscribe.data.push(obj)
    })
    this.handlePost(usersSuscribe)
  }

  handleModalShowHide(value) {
    this.props.handleModalShowHide(value)
    this.setState({
      success: false,
      loader: false,
      fileValidate: false,

    })
  }

  handleFile() {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true })
      /* Get first worksheet */
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws)
      /* Update state */
      this.setState({ data, cols: make_cols(ws['!ref']) }, () => {
        this.handleSubmit(data)
      })
    }

    if (rABS) {
      reader.readAsBinaryString(this.state.file)
    } else {
      reader.readAsArrayBuffer(this.state.file)
    };
  }

  handleInputChange(e) {
    this.setState({
      fileValidate: false,
    })
    const files = e.target.files
    if (files && files[0]) {
      this.setState({
        file: files[0],
        fileValidate: true
      })
    }
  }

  render() {
    return (
      <div>
        <Modal className='modal__suscribe' show={this.props.showHide}>
          {this.state.success
            ? <Modal.Header closeButton onClick={() => this.handleModalShowHide(true)}>
            </Modal.Header>
            : <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
            </Modal.Header>}
          <Modal.Body >
            {this.state.success
              ? <Container>
                <Row>
                  <Col className="success">

                    <img className="mb-4" src={Check} alt={Check} />

                    <div className='success__text modal__title'>¡Suscripción realizada!</div>
                  </Col>
                </Row>
              </Container>
              : <Container>
                {this.state.loader

                  ? (this.state.error
                    ? <div className="loader__container">
                      {Array.isArray(this.state.errordata) ?
                        <ul className='list-group list-group-flush'>  
                        
                        {this.state.errordata?.map((item, i) => {
                          return (
                            <li className='list-group-item text-start' key={i}> {item}</li>
                          )
                        })
                        }</ul>
                        : this.state.errordata}
                    </div>
                    : <div className="loader__container">
                      <div className="loader"></div>
                    </div>
                  )
                  : <React.Fragment>
                    <h4 className="modal__title">Suscribe a varios clientes</h4>
                    <div className="modal__body p-0" >
                      <div className="modal__descripcion">Les enviaremos notificaciones de las incidencias cuando sean reportadas, actualizadas o resueltas.</div>
                      <Form >
                        <Row className="justify-content-md-center">
                          <Col md={12}>
                            <Form.Group className="mb-3">
                              <Form.Label>Usuarios</Form.Label>

                              <Form.Control className='h-auto' style={{ minHeight: 'auto' }} type="file" accept={SheetJSFT} onChange={this.handleInputChange} name='name' aria-label="Usuarios" />

                              <Form.Label><a href={UsuariosPrueba} download={'plantilla_subida-masiva'}>Descarga ejemplo</a></Form.Label>
                            </Form.Group>
                          </Col>

                        </Row >
                      </Form ></div>

                    <Row className="d-flex justify-content-end modal__btn mb-0">

                      <Button className="btn btn-extra" onClick={() => this.handleModalShowHide()}>Cancelar</Button>
                      {
                        (this.state.fileValidate
                          ? <Button className='ml-24' variant="primary" onClick={() => this.handleFile()}>
                            Suscribir
                          </Button>
                          : <Button className='ml-24' variant="secondary">  Suscribir</Button>)
                      }

                    </Row>
                  </React.Fragment>
                }
              </Container>
            }
          </Modal.Body>

        </Modal>

      </div >
    )
  }
}

export default ModalSuscribe
