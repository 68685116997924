import React, { Component } from 'react';
import { Moment, BtnMore, BtnMinus } from '../../commonImports';
import ModalDelete from '../../modal/aplication/delete';
import ModalUpdate from '../../modal/aplication/update';
import ItemEditDelete from './itemsEditDelete';
import ListItemHistorico from './listItemHistorico';
import AuthService from '../../../services/auth.service';
import ListItem from './listItemComponent';

Moment.locale('es');

class ListItemSubGroup extends Component {
  state = {
    showHideDelete: false,
    showHideUpdate: false,
    component: [],
    active: false
  };

  componentDidMount() {
    this.handleClickgetComponents();
  }

  handleClickActive = () => {
    this.setState(prevState => ({
      active: !prevState.active
    }));
  };

  handleModalShowHidDelete = loader => {
    this.setState(prevState => ({
      showHideDelete: !prevState.showHideDelete
    }));

    if (loader) {
      this.props.ResetAllDataComponet(loader);
    }
  };

  handleModalShowHidUpdate = loader => {
    this.setState(prevState => ({
      showHideUpdate: !prevState.showHideUpdate
    }));

    if (loader) {
      this.props.ResetAllDataComponet(loader);
    }
  };

  handleClickgetComponents = () => {
    AuthService.getSubgruposComponents(this.props.item?.PK)
      .then(response => {
        this.setState({
          component: response.data.data.Items
        });
      })
      .catch(error => {
        // Manejar el error aquí
      });
  };

  render() {
    const { item, admin, ResetAllDataComponet } = this.props;
    const { showHideDelete, showHideUpdate, component, active } = this.state;

    return (
      <>
        {admin && (
          <>
            <ModalDelete
              item={item}
              showHide={showHideDelete}
              handleModalShowHide={this.handleModalShowHidDelete}
              name="subgrupo"
            />
            <ModalUpdate
              item={item}
              showHide={showHideUpdate}
              handleModalShowHide={this.handleModalShowHidUpdate}
              name="subgrupo"
            />
          </>
        )}

        <div className="card__list__container card__list__container--a">
          <div className="accordion__heading">
            <div className={`card__list__top no-pointer ${component.length === 0 ? 'no-pointer' : ''}`}>
              <div className="card__list__head">
                <div className="card__list__status__item card__list__status__item--b">
                  <div className="card__list__status__item--a">
                    <div className="card__list__title">
                      {parseInt(item?.index,10) + 1}. {item?.name}
                    </div>
                  </div>
                  {admin && (
                    <ItemEditDelete
                      handleModalShowHidUpdate={this.handleModalShowHidUpdate}
                      handleModalShowHidDelete={this.handleModalShowHidDelete}
                    />
                  )}
                </div>
                <div onClick={this.handleClickActive}>
                  {component.length !== 0 ? (
                    active ? (
                      <img data-testid="BtnMinus" className="card__list__btn pointer" src={BtnMinus} alt={BtnMinus} />
                    ) : (
                      <img data-testid="BtnMore" className="card__list__btn pointer" src={BtnMore} alt={BtnMore} />
                    )
                  ) : (
                    <div className="card__list__btn card__list__btn--op" />
                  )}
                </div>
              </div>
              <div className="card__list__body">
                <div className="status__btn__container">
                  <ListItemHistorico parent={item} item={item} />
                </div>
              </div>
            </div>
          </div>

          {active && (
            <div data-testid="accordion__panel" className="accordion__panel">
              {component.map((item, i) => (
                <ListItem
                  key={i}
                  admin={admin}
                  ResetAllDataComponet={ResetAllDataComponet}
                  parent={this.props.item}
                  item={item}
                />
              ))}
            </div>
          )}
        </div>
      </>
    );
  }
}

export default ListItemSubGroup;
