import axios from 'axios'
import config from '../config'
axios.defaults.adapter = require('axios/lib/adapters/http')
const axiosInstance = axios.create({
  baseURL: config.API_URL
})

axiosInstance.interceptors.response.use(response => {
  if (response.status === 200) {
    if (response?.config?.simplify === true) {
      return response.data?.data
    }
    return response
  }
  return Promise.reject(response)
}, error => {
  return Promise.reject(error)
})

export default axiosInstance
