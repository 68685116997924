import React from 'react'

function ListItemGroupsEditDelete(props) {
    return (
        <>
            <div className="card__list__update__item  pointer" onClick={() => props.handleClick()} >
                <div className="card__list__update__img">
                    <i className="icon-pencil-create"></i>
                </div>
                <div className="card__list__update__text">
                    Editar
                </div>
            </div>

            <div className="card__list__update__item  pointer" onClick={() => props.handleClickDelete()} >
                <div className="card__list__update__img">
                    <i className="icon-trash"></i>

                </div>
                <div data-testid="delete-button" className="card__list__update__text">
                    Eliminar
                </div>
            </div>

        </>
    )
}

export default ListItemGroupsEditDelete