import React from 'react'
import ModalDelete from '../../modal/aplication/delete'
import ListItemHistorico from './listItemHistorico'
import ModalUpdate from '../../modal/aplication/update'
import ItemEditDelete from './itemsEditDelete'
import Moment from 'moment'
Moment.locale('es')
class ListItemComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      showHideDelete: false,
      showHideUpdate: false
    }
    this.handleModalShowHidDelete = this.handleModalShowHidDelete.bind(this)
    this.handleModalShowHidUpdate = this.handleModalShowHidUpdate.bind(this)
  }
  handleModalShowHidDelete(loader) {
    this.setState({ showHideDelete: !this.state.showHideDelete })
    if (loader) {
      this.props.ResetAllDataComponet(loader)
    }
  }
  handleModalShowHidUpdate(loader) {
    this.setState({ showHideUpdate: !this.state.showHideUpdate })
    if (loader) {
      this.props.ResetAllDataComponet(loader)
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.admin &&
          <React.Fragment>
            <ModalDelete
              item={this.props.item}
              showHide={this.state.showHideDelete}
              handleModalShowHide={this.handleModalShowHidDelete}
              name='aplicación'
            />
            <ModalUpdate
              item={this.props.item}
              showHide={this.state.showHideUpdate}
              handleModalShowHide={this.handleModalShowHidUpdate}
              name='aplicación'
            />
          </React.Fragment>}
        <div className="card__list__container card__list__container--b">
          <div className='accordion__heading'>
            <div className="card__list__top no-pointer">
              <div className="card__list__head">
                <div className="card__list__status__item card__list__status__item--b">
                  <div className="card__list__status__item--a">
                    <div className="card__list__title">
                      {this.props.item?.name}
                    </div>
                  </div>
                  {this.props.admin &&
                    <ItemEditDelete handleModalShowHidUpdate={this.handleModalShowHidUpdate}
                      handleModalShowHidDelete={this.handleModalShowHidDelete} />
                  }
                </div>
                <div className="card__list__btn card__list__btn--op" />
              </div>
              <div className="card__list__body">
                <div className="status__btn__container status__btn__icon__container">
                  <ListItemHistorico parent={this.props.item} item={this.props.item} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default ListItemComponent