import React, { useState } from 'react';
import Modaleditincidentupdate from '../../modal/incident/editincidentupdate';
import Moment from 'moment';
Moment.locale('es');

const CardItemItem = (props) => {
  const [showHideeditincidentupdate, setShowHideEditIncidentUpdate] = useState(false);

  const handleClick = (value) => {
    setShowHideEditIncidentUpdate(!showHideeditincidentupdate);
    props.btnFuntioneditincidentupdate(value);
  };

  return (
    <React.Fragment>
      {props.admin && (
        <React.Fragment>
          <Modaleditincidentupdate
            item={props.item}
            showHide={showHideeditincidentupdate}
            parent={props.parent}
            handleModalShowHide={handleClick}
          />
        </React.Fragment>
      )}
      <div className="d-flex justify-content-between p-3 border-top">
        <div className="d-flex flex-wrap card__form__title">
          <div style={{ maxWidth: '121px', minWidth: '121px' }} className="pe-2">
            {props.item?.status === 'Investigating' && 'En revisión'}
            {props.item?.status === 'Identified' && 'Identificado'}
            {props.item?.status === 'Resolved' && 'Resuelto'}
          </div>
          <div className="fw-normal">
            <div className="mb-3">{props.item?.message}</div>
            <div className="card__list__detail fw-normal mb-3 ">
              <div className="text-uppercase">
                {Moment(props.item?.dateTimeHour).format('D MMM YYYY - h:mm:ss A')}
              </div>
              {props.admin && (
                <div>
                  <span>Actualizado por - {props.item?.user} </span>
                </div>
              )}
            </div>
          </div>
        </div>
        {props.admin && (
          <div className="card__list__update__text pointer" onClick={() => handleClick()}>
            Editar
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default CardItemItem;