import React from 'react'
import PropTypes from 'prop-types'

import { Button, Modal, Form } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Check from '../../../img/c-check.png'
import CardAddPoliticas from './cardAddPolice'
import CardDeletePoliticas from './cardDeletePolitica'

import AuthService from '../../../services/auth.service'

import Moment from 'moment'

Moment.locale('es')
class ModalCreateComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      id: ' ',
      subGrupos: [],
      name: '',
      grupo: '',
      subgrupo: '',
      createComponent: false,
      loaderPoliticaActual: false,
      PoliticasActual: [],
      loader: false,
      success: false
    }

    this.handleModalShowHide = this.handleModalShowHide.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangeDate = this.handleChangeDate.bind(this)
    this.handleChangeDateState = this.handleChangeDateState.bind(this)
    this.handleChangeDateState = this.handleChangeDateState.bind(this)

    this.handleClickgetPoliticaActual = this.handleClickgetPoliticaActual.bind(this)
  }

  handleClickgetPoliticaActual(loader) {
    if (loader) {
      this.setState({
        loaderPoliticaActual: true
      })
    }
    AuthService.getComponentesPoliticas(this.state.createComponent)
      .then(
        response => {
          this.setState({
            PoliticasActual: response.data.data.Items,
            loaderPoliticaActual: false
          })
        }

      )
  }

  handleSubmit() {
    this.setState({
      loader: true
    })
    const obj =
    {
      id: '',
      name: this.state.name,
      grupo: this.state.grupo,
      subgrupo: this.state.subgrupo
    }
    AuthService.postComponents(obj)
      .then(
        response => {
          const string = (this.state.grupo && this.state.subgrupo ? response.data.data : false)
          this.setState({
            success: true,
            loader: false,
            createComponent: string

          })
        },
        error => {

        }
      )
  }

  handleClickgetSubGrupos(id) {
    this.setState({

      subGrupos: []

    })
    AuthService.getGruposSubgrupos(id)
      .then(
        response => {
          this.setState({

            subGrupos: response.data.data.Items

          })
        },
        error => {

        }
      )
  }

  handleModalShowHide(value) {
    this.props.handleModalShowHide(value)
    this.setState({
      id: ' ',
      subGrupos: [],
      name: '',
      grupo: '',
      subgrupo: '',
      createComponent: false,
      loaderPoliticaActual: false,
      PoliticasActual: [],
      loader: false,
      success: false

    })
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value
    })
    if (value && name === 'grupo') {
      this.handleClickgetSubGrupos(value)
    }
  }

  handleChangeDate(date) {
    this.setState({
      startDate: date
    })
  }

  handleChangeDateState(date) {
    this.setState({
      startDateState: date
    })
  }

  render() {
    return (
      <div>
        <Modal data-testid="ModalCreateComponent" show={this.props.showHide}>
          {this.state.success
            ? <Modal.Header closeButton onClick={() => this.handleModalShowHide(true)}>
            </Modal.Header>
            : <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
            </Modal.Header>}
          <Modal.Body>
            {this.state.success
              ? <Container>
                {!this.state.createComponent
                  ? <Row>
                    <Col md={12} className="success">

                      <img className="mb-4" src={Check} alt={Check} />

                      <div className='success__text modal__title'>Nuevo {this.state.grupo && this.state.subgrupo ? 'componente' : (this.state.grupo ? 'subgrupo' : 'grupo')} creado</div>
                    </Col>
                  </Row>
                  : <Form>
                    <Row>

                      <Col md={12}>
                        <Row>

                          <h4 className="modal__title">Agrega políticas a la aplicación creada</h4>

                          <Col md={6}>
                            <CardAddPoliticas
                              reset={this.handleClickgetPoliticaActual}
                              parent={this.state.createComponent}
                            />
                          </Col>

                          <Col md={12}>

                            <Col md={6}>
                              <Form.Label>Politicas selecionadas</Form.Label>
                            </Col>
                            <Col md={6} />
                            <Col md={12}>
                              {this.state.loaderPoliticaActual
                                ? <div className="loader__container">
                                  <div className="loader"></div>
                                </div>
                                : <Row>
                                  {
                                    (this.state.PoliticasActual.map((item, i) => {
                                      return (
                                        <Col key={i} md={6}>

                                          <CardDeletePoliticas
                                            reset={this.handleClickgetPoliticaActual}
                                            parent={this.state.createComponent}
                                            item={item} />

                                        </Col >
                                      )
                                    })
                                    )
                                  }
                                </Row>
                              }

                            </Col ></Col >
                        </Row>
                        <Row className="d-flex justify-content-end modal__btn mb-0">
                          <Button className='btn btn-extra' variant="outline-secondary" onClick={() => this.handleModalShowHide(true)}>Cancelar</Button>
                        </Row>

                      </Col>

                    </Row>
                  </Form>
                }
              </Container>
              : <Container>
                {this.state.loader

                  ? <div className="loader__container">
                    <div className="loader"></div>
                  </div>
                  : <React.Fragment>
                    <h4 className="modal__title">Completa los datos   {this.state.grupo && this.state.subgrupo ? 'de la nueva aplicación' : (this.state.grupo ? 'del nuevo subgrupo' : 'del nuevo grupo')} </h4>
                    <Form >
                      <Row className="justify-content-md-center">
                        <Col md={6}>

                          <Form.Group className="mb-3" controlId="formGroupEmail">
                            <Form.Label>Nombre {this.state.grupo && this.state.subgrupo ? 'de la aplicación' : (this.state.grupo ? 'del subgrupo' : 'del grupo')}</Form.Label>
                            <Form.Control type="text" onChange={this.handleInputChange} name='name' />
                          </Form.Group>

                        </Col>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>Selecciona un grupo</Form.Label>
                            <Form.Select onChange={this.handleInputChange} name='grupo' aria-label="Selecciona Grupo">
                              <option value="" >Seleccione una opción</option>
                              {this.props.AllDataComponent && this.props.AllDataComponent.map((item, i) => {
                                return (
                                  <option key={i} value={item.SK}>{item.name}</option>

                                )
                              })
                              }

                            </Form.Select>
                          </Form.Group>

                        </Col>
                        {this.state.subGrupos.length > 0 &&
                          <Col md={6}>
                            <Form.Group>
                              <Form.Label>Selecciona un subgrupo</Form.Label>
                              <Form.Select onChange={this.handleInputChange} name='subgrupo' aria-label="Selecciona subgrupo">

                                <option value="" >Seleccione una opción</option>
                                {this.state.subGrupos.map((item, i) => {
                                  return (
                                    <option key={i} value={item.SK}>{item.name}</option>

                                  )
                                })
                                }

                              </Form.Select>
                            </Form.Group>

                          </Col>
                        }
                        <Col md={6}>
                        </Col>

                        <Row className="d-flex justify-content-end modal__btn mb-0">
                          <Button className='btn btn-extra' variant="outline-secondary" onClick={() => this.handleModalShowHide()}>Cancelar</Button>
                          {
                            (this.state.name)
                              ? <Button className='ml-24' variant="primary" onClick={() => this.handleSubmit()}>
                                Crear {this.state.grupo && this.state.subgrupo ? 'nueva aplicación' : (this.state.grupo ? 'nuevo subgrupo' : 'nuevo  grupo')}
                              </Button>
                              : <Button className="ml-24" variant="secondary">
                                Crear {this.state.grupo && this.state.subgrupo ? 'nueva aplicación' : (this.state.grupo ? 'nuevo subgrupo' : 'nuevo grupo')}
                              </Button>
                          }
                        </Row>

                      </Row>
                    </Form>
                  </React.Fragment>
                }
              </Container>
            }
          </Modal.Body>

        </Modal>

      </div >
    )
  }
}
ModalCreateComponent.propTypes = {
  handleModalShowHide: PropTypes.func,
  showHide: PropTypes.bool,
  AllDataComponent: PropTypes.any
}
export default ModalCreateComponent
