import React from 'react'
import { Tooltip } from 'react-tooltip'
import AuthService from '../../../services/auth.service'
import E_2 from '../../../img/e_2.png'
import E_1 from '../../../img/e_1.png'
import Moment from 'moment'

Moment.locale('es')

class ListItemHistorico extends React.Component {
  constructor() {
    super()
    this.state = {
      historico: [],
      loader: false,
      width: 0,
      height: 0
    }
    this.handleClickgetHistorico = this.handleClickgetHistorico.bind(this)
  }

  componentDidMount() {
    this.handleClickgetHistorico()
  }

  handleClickgetHistorico() {
    this.setState({
      loader: true

    })
    AuthService.getHistoricoComponents(this.props.item?.SK)
      .then(
        response => {
          const itemRows = []
          let itemHistorico = []
          const items = response.data.data

          for (const item of Object.keys(items)) {
            const element = items[item]
            itemRows.push(element)
          }

          if (window.innerWidth > 1200) {
            itemHistorico = itemRows
          } else {
            itemHistorico = itemRows.slice(itemRows.length - 30)
          }
          this.setState({
            historico: itemHistorico,
            loader: false

          })
        },
        error => {
          this.setState({
            historico: [],
            loader: false

          })
        }
      )
  }

  render() {
    return (
      <div data-testid="list-item-historico" className='d-flex flex-wrap w-100 '>

        {this.state.loader

          ? <div data-testid="loader" className="loader__container loader__container_min">
            <div className="loader"></div>
          </div>
          : this.state.historico.map((item, i) => {
            return (
              <React.Fragment key={i}>
                <Tooltip anchorSelect={'.global-' + this.props.parent.SK + i} place="top"
                  content={(
                    <div className="status__btn__text"   >
                      <div className="status__btn__title">
                        <div className={` status__btn__icon  ${item.alert === 2 ? 'error' : ''} ${item.alert === 1 ? '  alerta' : ''}`}></div>
                        {Moment(item.date).format(
                          'D MMMM  YYYY '

                        )}
                      </div>
                      {item.message.length === 0 ? <div className="status__btn__message">
                      No se registró incidencias
                      </div> : item.message.map((item, index) => {
                        return (
                          <div key={index} className="status__btn__message">
                            <div className="status__btn__message--a">
                              {item.impact === 'Critical'

                                ? <div className="status__item">
                                  <div className="status__img">
                                    <img src={E_1} alt="E_1" />
                                  </div>
                                  <div className="status__text">
                                    ESTADO CRÍTICO
                                  </div>
                                </div>
                                : <div className="status__item">
                                  <div className="status__img">
                                    <img src={E_2} alt="E_2" />
                                  </div>
                                  <div className="status__text">
                                    SERVICIO CON ALERTA
                                  </div>
                                </div>
                              }
                            </div>
                            <div className="status__btn__message--b px-2">
                              {item.name}

                            </div>
                            <div className="status__btn__message--c  px-2">
                              ({item.status ? item.status : 'sin estado'})
                            </div>
                            <div className="status__btn__message--d  px-2">
                              Duración:  {item.time}
                            </div>
                          </div>
                        )
                      })}


                    </div>
                  )}
                  direction="top"
                  tagName="span"
                  className="target status__btn__item"
                >

                </Tooltip>
                <div className={'global-' + this.props.parent.SK + i} >
                  <div className={` status__btn__icon  ${item.alert === 2 ? 'error' : ''} ${item.alert === 1 ? '  alerta' : ''}`}></div>
                </div>
              </React.Fragment >
            )
          })

        }
      </div >
    )
  }
}

export default ListItemHistorico
