import React from 'react'
import PropTypes from 'prop-types'

import T_a from '../../../img/t_a.png'
import T_v from '../../../img/t_v.png'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Form } from 'react-bootstrap'

class Card extends React.Component {
  render () {
    return (
            <React.Fragment>
                <Row className='mb-3' >
                    <Col xs={12} className='card__description__head card__description__head-b'>
                        <h1 >{this.props.title}</h1>
                    </Col>
                    <Col xs={12} className="card__description__body">
                        <div className="status__items">
                            <div className="status__item pl-0">
                                Leyenda:
                            </div>
                            <div className="status__item">
                                <div className="status__img">
                                    <img src={T_v} alt="T_v" />
                                </div>
                                <div className="status__text">
                                    RESUELTO
                                </div>
                            </div>
                            <div className="status__item">
                                <div className="status__img">
                                    <img src={T_a} alt="T_a" />
                                </div>
                                <div className="status__text">
                                    EN PROGRESO
                                </div>
                            </div>
                        </div> <Form.Group className="search" controlId="formGroupEmail">
                        <Form.Label>Buscador</Form.Label>
                        <div className='search__icon'>
                            <i className='icon-search'></i>
                        <Form.Control placeholder='Escribe una palabra' type="text" defaultChecked={this.props.searchText} onChange={this.props.handleChangeSearchText} />

                        </div>
                    </Form.Group>
                    </Col>

                </Row>
            </React.Fragment>
    )
  }
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  searchText: PropTypes.string,
  handleChangeSearchText: PropTypes.func
}

export default Card
