import React from 'react'
import { NavLink, useLocation } from 'react-router-dom';

const Nav = () => {
    const location = useLocation();

    return (
        <React.Fragment>
            <div data-testid="nav" className="nav" >
                <div className="nav__container content">
                    <div className="nav__items">

                        <NavLink to="/state_service" className={`nav__item ${location.pathname === '/' || location.pathname === '' ? 'active' : ''}`}>
                            Estado de Servicios
                        </NavLink>
                        <NavLink to="/incident_history" className={`nav__item ${location.pathname === '/incident_history' ? 'active' : ''}`}>
                            Histórico de Incidentes
                        </NavLink>
                    </div>

                </div>
            </div>

        </React.Fragment>
    )
}

export default Nav
