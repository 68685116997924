import Index from '../../pages/incident_history/index'

import React from 'react'
import AuthService from '../../services/auth.service'

import '../../css/app.scss'

class IHistory extends React.Component {
  constructor () {
    super()
    this.state = {
      loader: false

    }

    this.AllData = this.AllData.bind(this)
  }

  componentDidMount () {
    this.AllData()
  }

  async AllData () {
    this.setState({
      loader: true,
      error: false
    })
    try {
      const [
        { Items: aplication }
      ] = await Promise.all([
        AuthService.getComponents({ simplify: true })
      ])
      this.setState({
        aplication,
        loader: false
      })
    } catch (error) {
      this.setState({
        error: true,
        errordata: error?.response?.data?.data
      })
    }
  }

  render () {
    return (
        <Index
          admin={false}
          callAllDataIncident={this.AllData}
          aplication={this.state.aplication}
          loader={this.state.loader}
          error={this.state.error}
          errordata={this.state.errordata}
        />
    )
  }
}

export default IHistory
