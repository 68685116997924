import React, { useState, useEffect } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import CardDescription from '../../components/card/description';
import Modal from '../../components/modal/suscribe/create';
import ModalLoader from '../../components/modal/loader';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Body from './body';
import AuthService from '../../services/auth.service';

const IndexSuscribe = (props) => {
  const [showHide, setShowHide] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const [modalLoaderSuccess, setModalLoaderSuccess] = useState(false);
  const [suscriptores, setSuscriptores] = useState([]);
  const [currentTab, setCurrentTab] = useState('home');
  const [isSelectActualizar, setIsSelectActualizar] = useState(null);
  const [bodyLoader, setBodyLoader] = useState(false);
  const [localAllData, setLocalAllData] = useState(props.AllData); 


  useEffect(() => {
    setLocalAllData(props.AllData);
    setBodyLoader(true)
    setTimeout(() => {
      setBodyLoader(false)
    }, 350);
  }, [props.AllData,isSelectActualizar]);

  const handleModalShowHide = (loader) => {
    setShowHide(!showHide);
    ResetAllData(loader);

  };

  const handleModalShowHideLoader = (loader) => {
    setModalLoader(!modalLoader);
    ResetAllData(loader);

  };

  const ResetAllData = (loader) => {
    if (loader) {
      props.callAllData();
    }
  };

  const handleInputChangeCheckRCA = (value) => {
    const { name, id, checked } = value;
    const index = parseInt(id, 10) - 1;

    setSuscriptores((prevSuscriptores) => {
      const updatedSuscriptores = [...prevSuscriptores];
      const actualSuscriptor = updatedSuscriptores[index];

      if (actualSuscriptor?.id !== name) {
        updatedSuscriptores.push({ id: name, rca: checked });
      } else {
        actualSuscriptor.rca = checked;
      }
      return updatedSuscriptores;
    });
  };


  const handleSubmit = () => {
    setModalLoader(true);
    setModalLoaderSuccess(false);

    AuthService.patchSuscriptor({ items: suscriptores })
      .then(
        (response) => {
          setModalLoaderSuccess(true);
          setSuscriptores([]);
        },
        (error) => { }
      )

  };
  const onSelectTab = (keyName, ...args) => {
    if (currentTab === keyName) return;
    setCurrentTab(keyName);
    if (keyName !== 'profile') return;
  };

  const handleClickActualizar = (event) => {
    const { value } = event.target;
    const item = props.Plataformas.find((item) => value === item.PK);
    if (!item) {
      props.onFilterData({ idComponent: null });
    } else {
      props.onFilterData({ idComponent: item.PK });
    }
    setIsSelectActualizar(item?.PK);

  };

  return (
    <React.Fragment>
      <div className="flex">
        <CardDescription
          title="Suscripciones"
          btn={true}
          btn_text="Subida masiva"
          className="flex--end-j"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis ."
          type={2}
          btnFuntion={handleModalShowHide}
          admin={props.admin}
        />
      </div>
      <ModalLoader
        showHide={modalLoader}
        handleModalShowHide={handleModalShowHideLoader}
        success={modalLoaderSuccess}
      />
      <Modal
        showHide={showHide}
        handleModalShowHide={handleModalShowHide}
        ResetAllData={ResetAllData}
        AllData={localAllData}
      />
      {props.loader ? (
        props.error ? (
          <div className="loader__container" data-testid="loader">
            {props.errordata}
          </div>
        ) : (
          <div className="loader__container" data-testid="loader">
            <div className="loader"></div>
          </div>
        )
      ) : (
        <div className="nav-tabs-content">
          <Tabs
            defaultActiveKey={currentTab}
            transition={true}
            id="noanim-tab-example"
            className="mb-3"
            onSelect={onSelectTab}
          >
            <Tab eventKey="home" title="Pendientes">
              <Row className="card__list">
                {Array.isArray(localAllData) ? (
                  localAllData.map((item, i) => {
                    if (item.active === false) {
                      return (
                        <Body
                          key={i}
                          active={true}
                          admin={props.admin}
                          ResetAllData={ResetAllData}
                          item={item}
                          i={i}
                        />
                      );
                    }
                  })
                ) : null}
              </Row>
            </Tab>
            <Tab eventKey="profile" title="Suscritos">
              <Row className="card__list">
                <section className="card__header p-2">
                  <div className="card__header__client card__col">
                    <h2>Cliente</h2>
                  </div>
                  <div className="card__header__select card__col">
                    <Form.Group id="filtroApps" className="w-100 form-select-container">
                      <Form.Select className="w-100" onChange={handleClickActualizar}>
                        <option value="all">Todas las aplicaciones</option>
                        {props.Plataformas &&
                          props.Plataformas.length > 0 &&
                          props.Plataformas?.map((item, i) => {
                            return (
                              <option data-testid="card-platform" value={item.PK} key={i}>
                                {item.name}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="card__header__rca card__col card__col-b">
                    <h2>¿Debe recibir RCA?</h2>
                  </div>
                  <div className="card__col card__col-b">
                    <div className="mx-auto">
                      <h2>Retiro de subcripción</h2>
                    </div>
                  </div>
                </section>
                {bodyLoader ?
                  <div data-testid="loader" className="loader__container loader__container_min">
                    <div className="loader"></div>
                  </div> :
                  Array.isArray(localAllData) ? (
                    props.Plataformas && localAllData.map((item, i) => {
                      if (item.active) {
                        return (
                          <Body
                            key={i}
                            active={false}
                            plataformas={props.Plataformas}
                            admin={props.admin}
                            ResetAllData={ResetAllData}
                            item={item}
                            i={i}
                            handleInputChangeCheckRCA={handleInputChangeCheckRCA}
                          />
                        );
                      }
                    })
                  ) : null
                }
                <div className="btn__float-responsive">
                  <div className="btn__float-responsive__content">
                    <div className="btn__float-responsive__container">
                      {suscriptores.length === 0 ? (
                        <Button className="button-saved btn-secondary">Guardar Cambios</Button>
                      ) : (
                        <Button className="button-saved" onClick={handleSubmit}>
                          Guardar Cambios
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </Row>
            </Tab>
          </Tabs>
        </div>
      )}
    </React.Fragment>
  );
};

export default IndexSuscribe;