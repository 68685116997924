import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';

const Filter = (props) => {
    const [isAdminRoute, setIsAdminRoute] = useState(false);

    useEffect(() => {
      const interval = setInterval(() => {
        if (window.location) {
          setIsAdminRoute(window.location.pathname.includes('/admin'));
          clearInterval(interval);
        }
      }, 100);
  
      return () => {
        clearInterval(interval);
      };
    }, []);
  
        return (
            <React.Fragment>
                <div className="filter__container">

                    {props.admin &&
                        <Form.Group className="filter__item">
                            <Form.Label htmlFor="status-select">Publicado/Borrador</Form.Label>
                            <Form.Select
                                id="status-select"
                                value={props.published}
                                onChange={props.handleChangeStatus}
                                name="grupo"
                            >

                                <option value="">Todos</option>
                                <option value="true">Publicado</option>
                                <option value="false">Borrador</option>
                            </Form.Select>
                        </Form.Group>
                    }
                    <Form.Group className="filter__item">
                        <Form.Label htmlFor="aplication-select">Aplicación</Form.Label>
                        <Form.Select
                            id="aplication-select"
                            value={props.aplicationText}
                            onChange={props.handleChangeAplication}
                            name="grupo"
                        >
                            <option value="">Todas las aplicaciones</option>
                            {props.aplication &&
                                props.aplication.map((item, i) => {
                                    return (
                                        <option value={item.PK} key={i}>
                                            {item.name}
                                        </option>
                                    );
                                })}
                        </Form.Select>
                    </Form.Group>

                    <div className="filter__item filter__btn">

                        {props.published || props.aplicationText ? (
                            <div className="filter__btn__item">
                                <Button className="btn__close" onClick={() => props.clear()}>
                                    Limpiar
                                </Button>
                            </div>
                        ) : (
                            <div className="filter__btn__item">
                                <Button className="btn-disable-line">Limpiar</Button>
                            </div>
                        )}
                        {props.published || props.aplicationText ? (
                            <div className="filter__btn__item">
                                <Button className={`button-saved ${isAdminRoute ? '' : 'btn__gradient'}`} onClick={() => props.filter()}>
                                    Filtrar
                                </Button>
                            </div>
                        ) : (
                            <div className="filter__btn__item">
                                <Button className="button-saved btn-secondary">Filtrar</Button>
                            </div>
                        )}

                    </div>
                </div>
            </React.Fragment>
        );
    
}

export default Filter;
