import React from 'react'
import { FormGroup, Form } from 'react-bootstrap'

const Table = (props) => {
    const changeCheck = (event) => {
        const target = event.target;
        props.handleInputChangeCheckRCA(target)
    }


    return (
        <div className='table__container'>
            <div className='table__head'>
                <div className='table__col-a'>
                    Clientes
                </div>
                <div className='table__col-b'>
                    Enviar RCA
                </div>
            </div>
            <div className='table__body'>
                {props.subscribers?.map((item, i) => {
                    return (
                        <React.Fragment key={i}>
                            {item.active
                                ? <div className='table__col  mb-2'>
                                    <div className='table__col-a'>
                                        <div className='table__col__title mb-1'>
                                            {item.name}
                                            {item.rcasent &&
                                                <div className='badge'>
                                                    RCA <span className='text-lowercase'>enviado</span>
                                                </div>
                                            }

                                        </div>
                                        <div className='table__col__description'>
                                            {item.email}
                                        </div>
                                    </div>
                                    <div className='table__col-b'>
                                        <FormGroup>
                                            <div className='card__form__item' >
                                                <Form.Check
                                                    type="checkbox"
                                                    inline
                                                    className="btn__checkbox active"
                                                    name={item.PK}
                                                    onChange={changeCheck}
                                                    defaultChecked={!props.subscribers.some((subscriber) => subscriber.rcasent === true) && item.rca}
                                                    checked={item.checked}
                                                    id={'add_subscribers_' + i} />
                                            </div>
                                        </FormGroup>
                                    </div>
                                </div>
                                : ''}
                        </React.Fragment>
                    )
                })}

            </div>

        </div>
    )
}
export default Table
