import React from 'react'
import PropTypes from 'prop-types'

import { Button, Modal, Form } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Check from '../../../img/c-check.png'

import AuthService from '../../../services/auth.service'

import 'react-datepicker/dist/react-datepicker.css'
import Moment from 'moment'

Moment.locale('es')
class ModalMaintenanceDelete extends React.Component {
  constructor () {
    super()
    this.state = {
      loader: false,
      success: false
    }
    this.handleModalShowHide = this.handleModalShowHide.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit () {
    this.setState({
      loader: true,
      success: false
    })
    AuthService.deleteMantenimientos(this.props.maintenance.PK)
      .then(
        _response => {
          this.setState({
            success: true,
            loader: false
          })
        },
        error => {
          this.setState({
            success: false,
            loader: false
          })
        }
      )
  }

  handleModalShowHide (value) {
    this.props.handleModalShowHide(value)
    this.setState({
      success: false
    })
  }

  render () {
    return (
            <div>
                <Modal data-testid="ModalMaintenanceDelete" show={this.props.showHide} data-show={true}>
                    {this.state.success
                      ? <Modal.Header closeButton onClick={() => this.handleModalShowHide(true)}>
                        </Modal.Header>
                      : <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                        </Modal.Header>}

                    <Modal.Body>
                        {this.state.success
                          ? <Container>
                                <Row>
                                    <Col className="success">

                                        <img className="mb-4" src={Check} alt={Check} />

                                        <div className='success__text modal__title'>El mantenimiento ha sido eliminado con éxito.</div>
                                    </Col>
                                </Row>
                            </Container>
                          : <Container>
                                {this.state.loader
                                  ? <div className="loader__container">
                                        <div className="loader"></div>
                                    </div>
                                  : <React.Fragment>

                                        <h4 className="modal__title">Eliminar Mantenimiento</h4>
                                        <Form >
                                            <Row className="justify-content-md-center">
                                                <div className="justify-content-md-center d-flex">¿Confirma que desea eliminar el mantenimiento programado?</div>

                                                <Row className="d-flex justify-content-end modal__btn mb-0">

                                                    <Button className='btn btn-extra' variant="outline-secondary" onClick={() => this.handleModalShowHide()}>Cancelar</Button>
                                                    <Button className='ml-24' variant="primary" onClick={() => this.handleSubmit()} data-testid="btn-delete">Sí, eliminar</Button>
                                                </Row>

                                            </Row>
                                        </Form>
                                    </React.Fragment>}
                            </Container>
                        }
                    </Modal.Body>

                </Modal>

            </div >
    )
  }
}

ModalMaintenanceDelete.propTypes = {
  handleModalShowHide: PropTypes.func,
  showHide: PropTypes.bool,
  maintenance: PropTypes.any
}
export default ModalMaintenanceDelete
