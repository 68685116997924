import React from 'react'

function ItemEditDelete(props) {
    return (

        <div className="card__list__updates">
            <div className="card__list__update__item  pointer" onClick={() => props.handleModalShowHidUpdate()} >
                <div className="card__list__update__img">
                    <i className="icon-pencil-create"></i>
                </div>
                <div className="card__list__update__text">
                    Editar
                </div>
            </div>
            <div className="card__list__update__item  pointer" onClick={() => props.handleModalShowHidDelete()} >
                <div className="card__list__update__img">
                    <i className="icon-trash"></i>

                </div>
                <div className="card__list__update__text">
                    Eliminar
                </div>
            </div>
        </div>


    )
}

export default ItemEditDelete