import React from 'react';
import 'react-accessible-accordion/dist/fancy-example.css';
import Moment from 'moment';
import { Form } from 'react-bootstrap';
import DelayedCheckbox from './delayedCheckbox';
Moment.locale('es');

const CardItem = (props) => {
  const handleInputChangeCheck = (event) => {
    const target = event.target;
    props.handleInputChangeCheckRCA({ name: target.name, id: target.id, checked: target.checked });
  };

  const handleClick = () => {
    props.btnFuntion();
  };

  const handleClickDelete = () => {
    props.btnFuntionDelete();
  };

  const handleModal = () => {
    props.btnFuntionModal();
  };

  return (
    <React.Fragment>
      <div key={props.i} data-testid="list-component" className=" card__list__top--b border-bottom border-top-0 border-end-0 border-start-0">
        <div className="card__list__head card__col flex--start-j ">
          <div className="card__list__title mt-0">{props.item?.name}</div>
          <div>{props.item?.PK}</div>
          <div>{props.item?.email}</div>
          <div>{props.item?.code}</div>
        </div>
        {props.approbate && (
          <article className="card__list__rca card__col flex--start-j">
            <Form.Group data-testid="ver-aplicaciones-button" className="card__list__rca__items" onClick={handleModal}>
              <Form.Label className="label">
                {props.plataformas.some((plataforma) => plataforma.checked) ? (
                  <React.Fragment><i className="icon-see"></i>Ver aplicaciones</React.Fragment>
                ) : (
                  <React.Fragment>
                    <i className="icon-more"></i>
                    Agregar aplicaciones
                  </React.Fragment>
                )}
              </Form.Label>
            </Form.Group>
          </article>
        )}

        {props.approbate && (
          <DelayedCheckbox
            handleInputChangeCheck={handleInputChangeCheck}
            props={{
              item: props.item,
              rca: props.item.rca,
              i: props.i,
              plataformas: props.plataformas
            }}
          />
        )}

        {props.active ? (
          <div className="card__list__btn--none gap-4 flex--end-j h-100 d-flex align-items-center">
            <div onClick={handleClickDelete} className="btn btn-extra">
              Rechazar
            </div>
            <div onClick={handleClick} className="btn">
              Aprobar
            </div>
          </div>
        ) : (
          <div className="card__col card__col-b p-0">
            <div className="card__list__btn--none h-100  d-flex align-items-center flex--start-j mx-auto">
              <div onClick={handleClickDelete} className="btn btn-extra">
                Retirar
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default CardItem;