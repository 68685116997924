import React from 'react'
import Moment from 'moment'
import UpdateMantanient from '../../modal/aplication/updateMantanient'
import DeleteMantanient from '../../modal/aplication/deleteMantanient'
Moment.locale('es')
class Maintenance extends React.Component {
    constructor() {
        super()
        this.state = {
            showHideUpdateMaintenance: false,
            showHideDeleteMaintenance: false

        }
        this.handleModalShowHideUpdateMaintenance = this.handleModalShowHideUpdateMaintenance.bind(this)
        this.handleModalShowHideDeleteMaintenance = this.handleModalShowHideDeleteMaintenance.bind(this)
    }

    handleModalShowHideUpdateMaintenance(loader) {
        this.setState({ showHideUpdateMaintenance: !this.state.showHideUpdateMaintenance })
        if (loader) {
            this.props.ResetAllDataComponet(loader)
        }
    }

    handleModalShowHideDeleteMaintenance(loader) {
        this.setState({ showHideDeleteMaintenance: !this.state.showHideDeleteMaintenance })
        if (loader) {
            this.props.ResetAllDataComponet(loader)
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className='maintenance__container mb-4'>
                    <div className='maintenance__head'>

                        <div className='maintenance__date'>{Moment(this.props?.maintenance?.startDate).format('D MMMM YYYY HH:mm')} <span> a </span>
                            {Moment(this.props?.maintenance?.startDate).format('D MMMM YYYY') === Moment(this.props?.maintenance?.endDate).format('D MMMM YYYY') ?
                                Moment(this.props?.maintenance?.endDate).format('HH:mm') : Moment(this.props?.maintenance?.endDate).format('D MMMM YYYY HH:mm')}</div>
                    </div>
                    <div className='maintenance__body'>
                        <div className='maintenance__description'>
                            {this.props?.maintenance?.description}
                        </div>
                        <div className='maintenance__description__detail'>
                            Duración: {this.props?.maintenance?.duration}
                        </div>
                        {this.props.admin &&
                            <React.Fragment>

                                <div className='maintenance__description__detail'>
                                    Programado el {Moment(this.props?.maintenance?.createdAt).format(
                                        'D MMMM YYYY HH:mm'
                                    )}
                                </div>
                                {
                                    this.props?.maintenance?.editDate &&
                                    <div className='maintenance__description__detail'>
                                        Editado el {Moment(this.props?.maintenance?.editDate).format(
                                            'D MMMM YYYY HH:mm'
                                        )}
                                    </div>
                                }

                            </React.Fragment>
                        }

                        <div className='maintenance__detail'>
                            * El mantenimiento programado puede estar sujeto a cambios debido a circunstancias imprevistas.
                        </div>
                        {(this.props.btnEdit && this.props.btnDelete && this.props.admin) &&
                            <div className='maintenance__btn'>
                                <div className="card__list__updates">
                                    <div className="card__list__update__item  pointer" onClick={() => this.handleModalShowHideUpdateMaintenance()} >
                                        <div className="card__list__update__img">
                                            <i className="icon-pencil-create"></i>
                                        </div>
                                        <div className="card__list__update__text">
                                            Editar
                                        </div>
                                    </div>
                                    <div className="card__list__update__item  pointer" onClick={() => this.handleModalShowHideDeleteMaintenance()} >
                                        <div className="card__list__update__img">
                                            <i className="icon-trash"></i>
                                        </div>
                                        <div className="card__list__update__text">
                                            Eliminar
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <UpdateMantanient
                    showHide={this.state.showHideUpdateMaintenance}
                    handleModalShowHide={this.handleModalShowHideUpdateMaintenance}
                    ResetAllDataComponet={this.props.ResetAllDataComponet}
                    AllDataComponent={this.props.AllDataComponent}
                    maintenance={this.props?.maintenance}
                />
                <DeleteMantanient
                    showHide={this.state.showHideDeleteMaintenance}
                    handleModalShowHide={this.handleModalShowHideDeleteMaintenance}
                    ResetAllDataComponet={this.props.ResetAllDataComponet}
                    AllDataComponent={this.props.AllDataComponent}
                    maintenance={this.props?.maintenance}
                />
            </React.Fragment>
        )
    }
}

export default Maintenance
