import Moment from 'moment'
Moment.locale('es')

export function getTimeHourFormat (dateTimeHour) {
  const dateYYYY = parseInt(Moment(dateTimeHour).format('YYYY'),10)
  const dateMMMM = parseInt(Moment(dateTimeHour).format('MM'),10)
  const dateDD = parseInt(Moment(dateTimeHour).format('DD'),10)
  const datehh = parseInt(Moment(dateTimeHour).format('HH'),10)
  const datemm = parseInt(Moment(dateTimeHour).format('mm'),10)
  const datess = parseInt(Moment(dateTimeHour).format('ss'),10)
  const union = dateMMMM + '/' + dateDD + '/' + dateYYYY + ' ' + datehh + ':' + datemm + ':' + datess
  const dateTimeHourFormat = new Date(union)
  return dateTimeHourFormat
}
