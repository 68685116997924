import React, { useState, useEffect } from 'react';
import AdminRouter from './AdminRouter';
import PublicRouter from './PublicRouter';

const App = () => {
  const [isAdminRoute, setIsAdminRoute] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.location) {
        setIsAdminRoute(window.location.pathname.includes('/admin'));
        clearInterval(interval);
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      {isAdminRoute ? (
        <AdminRouter />
      ) : (
        <PublicRouter />
      )}
    </div>
  );
};

export default App;
