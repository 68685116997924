import React from 'react'
import PropTypes from 'prop-types'

import Card from 'react-bootstrap/Card'
import CloseButton from 'react-bootstrap/CloseButton'

import ModalDeletePoliticas from './deletPoliticas'

class CardDeleteComponent extends React.Component {
  constructor () {
    super()
    this.state = {

      showHide: false,
      polices: []

    }

    this.handleModalShowHide = this.handleModalShowHide.bind(this)
  }

  handleModalShowHide (loader) {
    this.setState({ showHide: !this.state.showHide })
    if (loader) {
      this.props.reset(loader)
    }
  }

  render () {
    return (
            <React.Fragment>
                <ModalDeletePoliticas
                    data-testid="deletePoliceModal"
                    key={this.props.key}
                    item={this.props.item}
                    showHide={this.state.showHide}
                    handleModalShowHide={this.handleModalShowHide}
                    parent={this.props.parent}
                />
                <Card key={this.props.key} bg="secondary" className="mb-3 card__component " body >{this.props.item && this.props.item?.name}
                    <CloseButton data-testid="button-deletePoliceModal" onClick={() => this.handleModalShowHide()} />
                </Card>
            </React.Fragment>
    )
  }
}
CardDeleteComponent.propTypes = {
  parent: PropTypes.any.isRequired,
  reset: PropTypes.func,
  item: PropTypes.any,
  keu: PropTypes.any
}
export default CardDeleteComponent
