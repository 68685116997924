import React from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Check from '../../../img/c-check.png'
import AuthService from '../../../services/auth.service'
import ExpandingInput from '../../input/expandingInput';
import DatePicker from 'react-datepicker'
import ModalAddTemplate from './addtemplate'
import 'react-datepicker/dist/react-datepicker.css'
import Moment from 'moment'
Moment.locale('es')
class ModalCreateIncident extends React.Component {
  constructor() {
    super()
    this.state = {
      id: ' ',
      startDate: new Date(),
      name: '',
      status: '',
      impact: '',
      message: '',
      messageStatus: '',
      loader: false,
      success: false,
      showHideAddtemplate: false,
    }
    this.handleModalShowHide = this.handleModalShowHide.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleChangeDate = this.handleChangeDate.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleModalShowHideAddtemplate = this.handleModalShowHideAddtemplate.bind(this)
    this.handleValueAddtemplate = this.handleValueAddtemplate.bind(this)

  }

  handleSubmit() {
    this.setState({
      loader: true
    })
    const obj = {
      name: this.state.name,
      status: this.state.status,
      impact: this.state.impact,
      message: this.state.message,
      dateTimeHour: Moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss'),

    }
    const objActualizar = {
      status: this.state.status,
      message: this.state.messageStatus,
      dateTimeHour: Moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss'),

    }

    AuthService.potIncidente(obj)
      .then(
        response => {
          const id = response.data.data
          AuthService.putActualizacionIncidente(id, { ...objActualizar }).then(
            response => {
              AuthService.postNotificacion({
                id,
                status: this.state.status,
                type: 0
              })
                .then(
                  response => {
                    this.setState({
                      id: ' ',
                      startDate: new Date(),
                      name: '',
                      status: '',
                      impact: '',
                      message: '',
                      messageStatus: '',
                      loader: false,
                      success: true,
                      showHideAddtemplate: false,
                      textA: false,
                      textB: false,
                      textC: false

                    })
                  }

                )
            }

          )
        },
        error => {

        }
      )
  }

  handleModalShowHide(value) {
    this.props.handleModalShowHide(value)
    this.setState({
      id: ' ',
      startDate: new Date(),
      name: '',
      status: '',
      impact: '',
      message: '',
      messageStatus: '',
      loader: false,
      success: false,
      showHideAddtemplate: false,
      textA: false,
      textB: false,
      textC: false

    })
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value
    })
  }

  handleChangeDate(date) {
    this.setState({
      startDate: date
    })
  }
  handleModalShowHideAddtemplate() {
    this.setState({ showHideAddtemplate: !this.state.showHideAddtemplate })
  }
  handleValueAddtemplate(value) {
    this.setState({ message: value })
  }

  render() {
    return (
      <div data-testid="modal-create-incident">
        <ModalAddTemplate handleValueAddtemplate={this.handleValueAddtemplate} handleModalShowHideAddtemplate={this.handleModalShowHideAddtemplate} showHideAddtemplate={this.state.showHideAddtemplate} />
        <Modal show={this.props.showHide} className={this.state.showHideAddtemplate && 'd-none'}>
          {this.state.success
            ? <Modal.Header closeButton onClick={() => this.handleModalShowHide(true)} data-testid="closeButton">
            </Modal.Header>
            : <Modal.Header closeButton onClick={() => this.handleModalShowHide()} data-testid="closeButton">
            </Modal.Header>}

          <Modal.Body>
            {this.state.success
              ? <Container>
                <Row>
                  <Col className="success">

                    <img className="mb-4" src={Check} alt={Check} />

                    <div className='success__text modal__title'>Nuevo incidente registrado con éxito.</div>
                    <div className="mt-2 card__list__detail">
                      *El nuevo incidente es registrado como un borrador. Para verlo, ve al Historial de Incidentes.
                    </div>
                  </Col>
                </Row>
              </Container>
              : <Container>
                {this.state.loader
                  ? <div className="loader__container">
                    <div className="loader"></div>
                  </div>
                  : <React.Fragment>

                    <h4 className="modal__title">Completa los datos del nuevo incidente </h4>
                    <Form >
                      <Row className="justify-content-md-center">
                        <Col md={6}>

                          <Form.Group className="mb-3" controlId="formGroupEmail">
                            <Form.Label>Nombre del incidente</Form.Label>
                            <Form.Control type="text" onChange={this.handleInputChange} name='name' />
                          </Form.Group>

                        </Col>
                        <Col md={6}>

                          <Form.Group className="mb-3">
                            <Form.Label htmlFor="1">Fecha del incidente</Form.Label>

                            <DatePicker id="1" className='form-select '
                              selected={this.state.startDate}
                              onChange={this.handleChangeDate}
                              showTimeSelect
                              timeFormat="HH:mm"
                              dateFormat="dd/MM/yyyy HH:mm"
                            />

                          </Form.Group>
                        </Col>
                        <Col sm={12}>

                          <Form.Group className="mb-3 " >

                            <Row className='mb-3' >
                              <Col xs={12} md={9}>
                                
                                <Form.Group >
                                  <Form.Label htmlFor="descripcionIncidente">Descripción del incidente</Form.Label>
                                  <ExpandingInput
                                    id="descripcionIncidente" 
                                    name="message"
                                    value={this.state.message}
                                    initialHeight={40}
                                    onChange={this.handleInputChange}
                                    maxRows={5}
                                  />
                                </Form.Group>
                                
                              </Col>

                              <Col xs={12} md={3}>
                                <Form.Label></Form.Label>

                                <Button onClick={this.handleModalShowHideAddtemplate} className=" btn btn-extra" data-testid="btn plantilla">
                                  <i className='mr-10 icon-plantilla'></i>
                                  Usar plantilla</Button>
                              </Col>
                            </Row>

                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>Criticidad</Form.Label>
                            <Form.Select onChange={this.handleInputChange} name='impact' aria-label="Criticidad">
                              <option >Seleccione una opción</option>
                              <option value="Warning">Alerta </option>
                              <option value="Critical">Critico </option>

                            </Form.Select>
                          </Form.Group>

                        </Col>

                        <Col md={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>Estado</Form.Label>
                            <Form.Select onChange={this.handleInputChange} name='status' aria-label="Estado">
                              <option >Seleccione una opción</option>
                              <option value="Investigating">En revisión</option>
                              <option value="Identified">Identificado</option>
                              <option value="Resolved">Resuelto</option>

                            </Form.Select>
                          </Form.Group>

                        </Col>
                        <Col sm={12}>

                          <Form.Group className="mb-3" >
                            <Form.Label>Descripción del estado </Form.Label>
                            <Form.Control type="text" onChange={this.handleInputChange} name='messageStatus' />
                          </Form.Group>
                        </Col>

                        <Row className="d-flex justify-content-end modal__btn mb-0">

                          <Button className="btn btn-extra" onClick={() => this.handleModalShowHide()}>Cancelar</Button>

                          {
                            (this.state.name &&
                              this.state.status &&
                              this.state.impact &&
                              this.state.message)
                              ? <Button className='ml-24' variant="primary" onClick={() => this.handleSubmit()} data-testid="create-btn" >
                                Registrar nuevo incidente
                              </Button>
                              : <Button className='ml-24' variant="secondary"> Registrar nuevo incidente</Button>
                          }
                        </Row>

                      </Row>
                    </Form>
                  </React.Fragment>}
              </Container>
            }
          </Modal.Body>

        </Modal>
      </div >
    )
  }
}
export default ModalCreateIncident