import React from 'react'

import AuthService from '../../services/auth.service'

import 'react-accessible-accordion/dist/fancy-example.css'

import Moment from 'moment'

Moment.locale('es')
class CardProgressList extends React.Component {
  constructor() {
    super()
    this.state = {
      aIncidentes: [{ status: '', message: '', dateTimeHour: '' }],
      aIncidentesB: [{ status: '', message: '', dateTimeHour: '' }],
      loader: false
    }
  }

  componentDidMount() {
    this.handleClicItemsUpdates()
  }

  handleClicItemsUpdates() {
    if (this.props.item) {
      AuthService.getActualizacionesIncidentes(this.props.item?.SK)
        .then(
          response => {
            const list = response.data.data.Items

            const sortedArray = list.sort((a, b) => {
              return Moment(b.dateTimeHour).diff(a.dateTimeHour)
            })

            this.setState({

              aIncidentes: response?.data?.data?.Items,
              aIncidentesB: sortedArray,
              loader: true

            })
          },
          error => {
          }
        )
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.loader
          ? <div className="loader__container">
            <div className="loader"></div>
          </div>
          : (this.state.aIncidentesB[0] && this.state.aIncidentesB[0].dateTimeHour &&
            <div data-testid="card" className="d-flex flex-wrap card__form__title border-top p-3 ">
              <div style={{ maxWidth: '121px', minWidth: '121px' }} className='pe-2 '>
                {this.state.aIncidentesB[0].status === 'Investigating' && 'En revisión'}
                {this.state.aIncidentesB[0].status === 'Identified' && 'Identificado'}
                {this.state.aIncidentesB[0].status === 'Resolved' && 'Resuelto'}
              </div>
              <div className='fw-normal'  >
                <div className='mb-3' >
                  {this.state.aIncidentesB[0].message}
                </div>
                <div className='card__list__detail fw-normal mb-3 '>
                  <div className='text-uppercase'>
                    {Moment(this.state.aIncidentesB[0].dateTimeHour).format(
                      'D MMM YYYY - h:mm:ss A'
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
      </React.Fragment>
    )
  }
}

export default CardProgressList
