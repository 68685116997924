import React from 'react';

const Pagination = (props) => {
  const {
    onPageChange,
    totalPages,
    numberPage,
  } = props;

  const paginationRange = Array(totalPages).fill().map((_, index) => index + 1);

  const onNext = () => {
    onPageChange(numberPage + 1);
  };

  const onPrevious = () => {
    onPageChange(numberPage - 1);
  };

  if (paginationRange?.length < 2) {
    return null;
  }
  return (
    <ul data-testid="pagination" className="pagination">
      {numberPage > 1 && (
        <li
          className={`pagination__before ${numberPage > 1 ? 'active pointer' : ''}`}
          onClick={onPrevious}
          data-testid="previous-button"
        >
          <i className="icon-left"></i>
        </li>
      )}

      <li
        data-testid="first-button"
        className={`pagination__box pointer ${numberPage === 1 ? 'active' : '' }`}
        onClick={() =>
          onPageChange(
            numberPage > 1 ? paginationRange[numberPage - 1] - 1 : paginationRange[numberPage - 1]
          )
        }
      >
        {numberPage > 1 ? paginationRange[numberPage - 1] - 1 : paginationRange[numberPage - 1]}
      </li>

      <li
        data-testid="second-button"
        className={`pagination__box pointer ${numberPage === 1 ? 2 : (paginationRange[numberPage - 1]) === numberPage ? 'active' : ''}`}
        onClick={() =>
          onPageChange(numberPage === 1 ? 2 : paginationRange[numberPage - 1])
        }
      >
        {numberPage === 1 ? 2 : paginationRange[numberPage - 1]}
      </li>

      {numberPage < paginationRange.length && (
        <li
          className={`pagination__after ${numberPage < totalPages ? 'active pointer' : ''}`}
          onClick={onNext}
          data-testid="next-button"
        >
          <i className="icon-right"></i>
        </li>
      )}
    </ul>
  );
};

export default Pagination;
