import React, { useState } from 'react';
import '../../../src/css/app.scss';
import Moment from 'moment';
import CardItem from '../../components/card/incident/listItem';
import ModalUpdate from '../../components/modal/incident/update';
import Modalincidentupdate from '../../components/modal/incident/incidentupdate';
import ModalAddRCA from '../../components/modal/incident/addRCA';
import ModalDelete from '../../components/modal/components/delete';
Moment.locale('es');

function Body(props) {
  const [showHideUpdate, setShowHideUpdate] = useState(false);
  const [showHideDelete, setShowHideDelete] = useState(false);
  const [showHideincidentupdate, setShowHideincidentupdate] = useState(false);
  const [showHideAddRCA, setShowHideAddRCA] = useState(false);
  const [subscribersData, setSubscribersData] = useState([]);

  const handleModalShowHidUpdate = (loader) => {
    setShowHideUpdate(!showHideUpdate);
    if (loader) {
      props.resetAllDataIncident(loader);
    }
  }

  const handleModalShowHidDelete = (loader) => {
    setShowHideDelete(!showHideDelete);
    if (loader) {
      props.resetAllDataIncident(loader);
    }
  }

  const handleModalShowHidincidentupdate = (loader) => {
    setShowHideincidentupdate(!showHideincidentupdate);
    if (loader) {
      props.resetAllDataIncident(loader);
    }
  }

  const handleModalShowHideditincidentupdate = (loader) => {
    if (loader) {
      props.resetAllDataIncident(loader);
    }
  }

  const handleModalShowHideAddRCA = (loader) => {
    setShowHideAddRCA(!showHideAddRCA);
    if (loader) {
      props.resetAllDataIncident(loader);
    }
  }

  const getData = (data) => {
    setSubscribersData(data)
  }

  return (
    <React.Fragment>
      <CardItem
        item={props.item}
        btnFuntion={handleModalShowHidUpdate}
        btnFuntionDelete={handleModalShowHidDelete}
        btnFuntionActualizar={handleModalShowHidincidentupdate}
        btnFuntioneditincidentupdate={handleModalShowHideditincidentupdate}
        btnFuntionAddRCA={handleModalShowHideAddRCA}
        admin={props.admin}
        subscribersData={subscribersData}
      />
      {props.admin && (
        <React.Fragment>
          <ModalUpdate
            item={props.item}
            showHide={showHideUpdate}
            handleModalShowHide={handleModalShowHidUpdate}
          />
          <ModalDelete
            type='incident'
            item={props.item}
            showHide={showHideDelete}
            handleModalShowHide={handleModalShowHidDelete}
          />
          <Modalincidentupdate
            item={props.item}
            showHide={showHideincidentupdate}
            handleModalShowHide={handleModalShowHidincidentupdate}
          />
          <ModalAddRCA
            item={props.item}
            showHide={showHideAddRCA}
            handleModalShowHide={handleModalShowHideAddRCA}
            getData={getData}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default Body;
