import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, Form } from 'react-bootstrap'
import ExpandingInput from '../../input/expandingInput';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Check from '../../../img/c-check.png'
import AuthService from '../../../services/auth.service'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Moment from 'moment'

Moment.locale('es')
class ModalMaintenance extends React.Component {
  constructor() {
    super()
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      description: '',
      loader: false,
      success: false
    }

    this.handleModalShowHide = this.handleModalShowHide.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleChangeStartDate = this.handleChangeStartDate.bind(this)
    this.handleChangeEndDateDate = this.handleChangeEndDateDate.bind(this)
    this.handleSubmitCreate = this.handleSubmitCreate.bind(this)
  }

  handleSubmitCreate() {
    this.setState({
      loader: true
    })
    const obj = {
      startDate: Moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss'),
      endDate: Moment(this.state.endDate).format('YYYY-MM-DD HH:mm:ss'),
      description: this.state.description
    }

    AuthService.postMantenimientos(obj)
      .then(
        _response => {
          this.setState({
            success: true,
            loader: false,
            description: '',
            startDate: new Date(),
            endDate: new Date()
          })
        },
        error => {
          this.setState({
            success: false,
            loader: false,
            startDate: new Date(),
            endDate: new Date(),
            description: ''
          })
        }
      )
  }

  handleModalShowHide(value) {
    this.props.handleModalShowHide(value)
    this.setState({
      success: false
    })
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }

  handleChangeStartDate(date) {
    this.setState({
      startDate: date
    })
  }

  handleChangeEndDateDate(date) {
    this.setState({
      endDate: date
    })
  }

  render() {
    return (
      <div>
        <Modal data-testid="ModalMaintenance" show={this.props.showHide}>
          {this.state.success
            ? <Modal.Header closeButton onClick={() => this.handleModalShowHide(true)}>
            </Modal.Header>
            : <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
            </Modal.Header>}

          <Modal.Body>
            {this.state.success
              ? <Container>
                <Row>
                  <Col className="success">

                    <img className="mb-4" src={Check} alt={Check} />

                    <div className='success__text modal__title'>¡Mantenimiento programado con éxito!</div>
                  </Col>
                </Row>
              </Container>
              : <Container>
                {this.state.loader
                  ? <div className="loader__container">
                    <div className="loader"></div>
                  </div>
                  : <React.Fragment>

                    <h4 className="modal__title">Programar Mantenimiento</h4>
                    <Form >
                      <Row className="justify-content-md-center">
                        <Col md={6}>

                          <Form.Group className="mb-3">
                            <Form.Label htmlFor="1">Desde</Form.Label>

                            <DatePicker id="1" className='form-select '
                              selected={this.state.startDate}
                              onChange={this.handleChangeStartDate}
                              minDate={new Date()}
                              showTimeSelect
                              timeFormat="HH:mm"
                              dateFormat="dd/MM/yyyy HH:mm"
                            />

                          </Form.Group>
                        </Col>
                        <Col md={6}>

                          <Form.Group className="mb-3">
                            <Form.Label htmlFor="1">Hasta</Form.Label>

                            <DatePicker id="1" className='form-select '
                              selected={this.state.endDate}
                              onChange={this.handleChangeEndDateDate}
                              minDate={this.state.startDate}
                              showTimeSelect
                              timeFormat="HH:mm"
                              dateFormat="dd/MM/yyyy HH:mm"
                            />

                          </Form.Group>
                        </Col>
                        <Col sm={12}>
                          <Form.Group >
                            <Form.Label>Mensaje</Form.Label>
                            <ExpandingInput name={"description"} value={this.state.description} initialHeight={40} onChange={this.handleInputChange} maxRows={5} />
                          </Form.Group>
                        </Col>
                        <Row className="d-flex justify-content-end modal__btn mb-0">

                          <Button className='btn btn-extra' variant="outline-secondary" onClick={() => this.handleModalShowHide()}>
                            Cancelar
                          </Button>
                          {
                            (this.state.description)
                              ? <Button className='ml-24' variant="primary" onClick={() => this.handleSubmitCreate()}>
                                Programar
                              </Button>
                              : <Button className='ml-24' variant="secondary">Programar</Button>

                          }
                        </Row>

                      </Row>
                    </Form>
                  </React.Fragment>}
              </Container>
            }
          </Modal.Body>

        </Modal>

      </div >
    )
  }
}
ModalMaintenance.propTypes = {
  handleModalShowHide: PropTypes.func,
  showHide: PropTypes.bool
}
export default ModalMaintenance