import React from 'react'
import PropTypes from 'prop-types'

import T_a from '../../img/t_a.png'
import T_v from '../../img/t_v.png'
import E_1 from '../../img/e_1.png'
import E_2 from '../../img/e_2.png'
import E_3 from '../../img/e_3.png'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

class Card extends React.Component {
  constructor () {
    super()
    this.handleClick = this.handleClick.bind(this)
    this.handleClick2 = this.handleClick2.bind(this)
  }

  handleClick () {
    this.props.btnFuntion()
  }

  handleClick2 () {
    this.props.btnFuntion2()
  }

  render () {
    return (
            <React.Fragment>

                <div className="card__description" >
                    <div className='card__description__head'>
                        <h1 >{this.props.title}</h1>
                    </div>

                    <div className={'card__description__body ' + (this.props?.className || '')} >
                        <div className="card__description__head">
                            {this.props.text && !this.props.admin && <div className="card__description__text">
                                {this.props.text}

                            </div>
                            }
                            {this.props.admin &&
                                <Row className={(this.props.btnIncident ? 'head-container-btn' : '')}>
                                    {this.props.btn &&
                                        <Col className={(this.props.btnIncident ? 'mb-3 mt-3' : '')} xl={6} lg={12}>
                                            <div onClick={() => this.handleClick()} className="btn"> {this.props.btn_text}</div>
                                        </Col>
                                    }
                                    {this.props.btnIncident &&
                                        <Col className={(this.props.btnIncident ? 'mb-3 mt-3 btn-2' : '')} xl={6} lg={12}>
                                            <div onClick={() => this.handleClick2()} className="btn"> {this.props.btn_textIncident}</div>
                                        </Col>
                                    }
                                </Row>
                            }
                        </div>

                    </div>
                    {this.props.type === 0 &&
                            <div className="card__description__body">
                                <div className="status__items">
                                    <div className="status__item">
                                    Leyenda:
                                    </div>
                                    <div className="status__item">
                                        <div className="status__img">
                                            <img src={T_v} alt="T_v" />
                                        </div>
                                        <div className="status__text">
                                            RESUELTO
                                        </div>
                                    </div>
                                    <div className="status__item">
                                        <div className="status__img">
                                            <img src={T_a} alt="T_a" />
                                        </div>
                                        <div className="status__text">
                                            EN PROGRESO
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {this.props.type === 1 &&
                            <div className="card__description__body">
                                <div className="status__label">Registro de los últimos {window.innerWidth > 1200 ? '60' : '30'} días</div>
                                <div className="status__items">
                                    <div className="status__item">
                                        <div className="status__img">
                                            <img src={E_3} alt="E_3" />
                                        </div>
                                        <div className="status__text">
                                            Servicio Estable
                                        </div>
                                    </div>
                                    <div className="status__item">
                                        <div className="status__img">
                                            <img src={E_2} alt="E_2" />
                                        </div>
                                        <div className="status__text">
                                            Servicio con Alerta
                                        </div>
                                    </div>
                                    <div className="status__item">
                                        <div className="status__img">
                                            <img src={E_1} alt="E_1" />
                                        </div>
                                        <div className="status__text">
                                            Estado Crítico
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                </div>
            </React.Fragment>
    )
  }
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  btn: PropTypes.any,
  btn_text: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.number,
  btnFuntion: PropTypes.func,
  admin: PropTypes.bool,
  btnFuntion2: PropTypes.func,
  btnIncident: PropTypes.any,
  btn_textIncident: PropTypes.string
}

export default Card
