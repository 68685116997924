import { Form } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import DatePicker from 'react-datepicker';

function ModalEdit(props) {
  const handleSubmit = props.handleSubmit;
  const handleChangeDate = props.handleChangeDate;
  const startDate = props.startDate;
  const hasDefaultValue = !!props.item?.status && !!props.item?.dateTimeHour;

  return (
    <>
      <Col md={6}>
        <Form.Group>
          <Form.Label>Estado</Form.Label>
          <Form.Select className={props.disabled ? 'select-disabled' : ''} disabled={props.disabled || false}
            onChange={handleSubmit} defaultValue={hasDefaultValue ? props.item.status : ''} name='status'>
            <option>Seleccione una opción</option>
            <option value="Investigating">En revisión</option>
            <option value="Identified">Identificado</option>
            <option value="Resolved">Resuelto</option>
          </Form.Select>
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group className="mb-3">
          <Form.Label>Fecha del estado</Form.Label>
          <DatePicker
            className={props.disabled ? 'form-select select-disabled' : 'form-select'}
            disabled={props.disabled || false}
            id="1"
            selected={startDate}
            onChange={handleChangeDate}
            showTimeSelect
            timeFormat="HH:mm"
            dateFormat="dd/MM/yyyy HH:mm"
            defaultValue={hasDefaultValue ? new Date(props.item.dateTimeHour) : null}
          />
        </Form.Group>
      </Col>
    </>
  );
}

export default ModalEdit;