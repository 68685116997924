import React, { useState, useEffect, useRef, useCallback } from 'react';

const ExpandingInput = ({ value, onChange, maxRows, initialHeight, name }) => {
  const [text, setText] = useState(value);
  const textareaRef = useRef(null);
  const isInitialMount = useRef(true); 
  const [isFocused, setIsFocused] = useState(false);


  const adjustTextareaHeight = useCallback(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = `${initialHeight}px`;
      const newHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = `${newHeight}px`;
    }
  }, [initialHeight]);

  const handleChange = (event) => {
    const newText = event.target.value;
    setText(newText);
    adjustTextareaHeight();
    onChange(event);
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setText(value);
      setTimeout(() => {
        adjustTextareaHeight();
      }, 50);
    }
  }, [value, adjustTextareaHeight]);

  const handleTextareaFocus = () => {
    setIsFocused(true);
    adjustTextareaHeight();
  };

  const handleTextareaBlur = () => {
    setIsFocused(false);
  };


  return (
    <div className={`border-special ${isFocused ? 'border-special__focus' : ''}`}>
      <textarea
        ref={textareaRef}
        className="w-100"
        type="text"
        value={text}
        onChange={handleChange}
        onFocus={handleTextareaFocus}
        onBlur={handleTextareaBlur}

        name={name}
        aria-label="Mensaje"
        style={{
          maxHeight: `${maxRows * 20}px`,
          overflow: 'hidden',
        }}
      />
    </div>
  );
};

export default ExpandingInput;
