import React, { useEffect, useState } from "react";
import { Amplify, Auth, Hub } from "aws-amplify";
import LoginFederated from "./loginFederated";
import awsExports from '../../../aws-exports.js'

import Header from './header'
import Footer from './footer'
import Nav from './nav'
import '../../../css/app.scss'
 

Amplify.configure({...awsExports,})
const federatedIdName =awsExports.federated_Id_name;
const Layout = (props) => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          setToken("grating...");
          getToken().then(userToken => setToken(userToken.idToken.jwtToken));
          break;
        case "signOut":
          setToken(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
        default:
          break;
      }
    });

  }, []);
  function getToken() {
    return Auth.currentSession()
      .then(session => session)
      .catch(err => console.log(err));
  }
  
  return (
    <React.Fragment>

      {token ? (
        <React.Fragment>
          <Header signOut={() => Auth.signOut()} />
          <Nav/>
          <div className="content body">
            {props.children}
          </div>
          <Footer />
        </React.Fragment>
      ) : (
        <LoginFederated federatedIdName={federatedIdName} />
      )}
    </React.Fragment>
  )
}

export default Layout
