import React from 'react'
import { createRoot } from 'react-dom/client'

import { createBrowserHistory } from 'history'
import App from './app/index'
import '../src/css/app.scss'

import * as serviceWorker from './serviceWorker'
import '../src/css/fonts/object/Objective_OTF/Objective-Regular.otf'

/*  import './mocks/browser'
 */ 
const history = createBrowserHistory()

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript

if (root) {
  // 1. Set up the browser history with the updated location
  // (minus the # sign)
  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1]
  if (path) {
    history.replace(path)
  }

  // 2. Render our app
  root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
  )
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
