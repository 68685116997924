import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'

class Month extends React.Component {
  render () {
    return (
            <React.Fragment>
                <div className="mounth__container" >
                {this.props.children}
                </div>
            </React.Fragment>
    )
  }
}

export default Month
