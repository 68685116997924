import React from 'react'
import PropTypes from 'prop-types'

import { Form } from 'react-bootstrap'
import AuthService from '../../../services/auth.service'

import BtnMore from '../../../img/btn_more_blue.png'

import ModalAddComponent from './addPolice'

class CardAddPoliticas extends React.Component {
  constructor () {
    super()
    this.state = {
      polices: [],

      showHide: false

    }

    this.handleClickgetComponents = this.handleClickgetComponents.bind(this)
    this.handleModalShowHide = this.handleModalShowHide.bind(this)
  }

  componentDidMount () {
    this.handleClickgetComponents()
  }

  handleClickgetComponents () {
    AuthService.getPolices()
      .then(

        response => {
          this.setState({
            polices: response.data.data.policies
          })
        }

      )
  }

  handleModalShowHide (loader) {
    this.setState({ showHide: !this.state.showHide })
    if (loader) {
      this.props.reset(loader)
    }
  }

  render () {
    return (
            <React.Fragment>
                <ModalAddComponent
                    data-testid="addPoliceModal"
                    item={this.state.polices}
                    showHide={this.state.showHide}
                    handleModalShowHide={this.handleModalShowHide}
                    parent={this.props.parent}
                />
                <Form.Label>Agregar Politica</Form.Label>
                <Form.Group data-testid="button-addPoliceModal"
                    onClick={() => this.handleModalShowHide()} className="mb-3" >
                    <img className='pointer' src={BtnMore} alt={BtnMore} />
                </Form.Group>

            </React.Fragment>
    )
  }
}
CardAddPoliticas.propTypes = {
  parent: PropTypes.any.isRequired,
  reset: PropTypes.func
}
export default CardAddPoliticas
