import React from 'react'
import { Modal } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import T_a from '../../../img/t_a.png'

import AuthService from '../../../services/auth.service'

import 'react-datepicker/dist/react-datepicker.css'
import Moment from 'moment'

import ModalRowDelete from './modalRowDelete'

Moment.locale('es')
class ModalDeleteIncident extends React.Component {
  constructor () {
    super()
    this.state = {
      id: ' ',
      startDate: new Date(),
      startDateState: new Date(),
      name: '',
      status: '',
      impact: '',
      message: '',
      success: false,
      loader: false
    }

    this.handleModalShowHide = this.handleModalShowHide.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  // componentWillReceiveProps (props) {
  //   this.setState({

  //     id: props.item.SK,
  //     name: props.item.name,
  //     status: props.item.status,
  //     impact: props.item.impact,
  //     message: props.item.message
  //   })
  // }

  handleSubmit () {
    this.setState({
      loader: true
    })
    const deleteFunction = this.props.type === 'incident' ? AuthService.deleteIncidentes : AuthService.deleteComponents
    deleteFunction(this.props.item?.SK
    )
      .then(
        response => {
          this.setState({
            success: true,
            loader: false
          })
        },
        error => {
        }
      )
  }

  handleModalShowHide (value) {
    this.setState({
      success: false
    })
    this.props.handleModalShowHide(value)
  }

  render () {
    return (
            <div data-testid="modal-delete">
                <Modal data-testid="ModalDeleteComponent" className='modal__delete ' show={this.props.showHide}>
                    {this.state.success
                      ? <Modal.Header closeButton onClick={() => this.handleModalShowHide(true)}>
                        </Modal.Header>
                      : <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                        </Modal.Header>}
                    <Modal.Body >
                        {this.state.success
                          ? <Container>
                                <Row>
                                    {this.props.type === 'incident'
                                      ? <Col className="success">

                                    <i className="icon-trash"></i>

                                        <div className='success__text modal__title'>Incidente eliminado</div>
                                    </Col>
                                      : <Col className="success">

                                    <img className="mb-4" src='' alt='' />

                                    <div className='success__text modal__title'>{this.props.name} eliminado</div>
                                    </Col>}
                                </Row>
                            </Container>
                          : <Container>
                                {this.state.loader

                                  ? <div data-testid ="delete_loader"className="loader__container">
                                <div className="loader"></div>
                            </div>
                                  : <React.Fragment>
                                {this.props.type === 'incident'
                                  ? <h4 className="modal__title">¿Confirma que desea
                                    eliminar el incidente? </h4>
                                  : <h4 className="modal__title">¿Confirma que desea
                                    eliminar el {this.props.name} ? </h4>}
                                <div className='card__list__container'>
                                    <div className='accordion__heading'>
                                        {this.props.type === 'incident'
                                          ? <div className="card__list__top" >

                                            <div className="card__list__head">
                                                <div className="card__list__status__item">
                                                    <div className="card__list__status__item--a">
                                                        <div className="card__list__status__img">
                                                            <img src={T_a} alt="T_a" />
                                                        </div>
                                                        {this.props.item?.dateTimeHour &&
                                                            <div className="card__list__status__text"> {Moment(this.props.item?.dateTimeHour).format(
                                                              'D MMMM  YYYY - h:mm:ss A'

                                                            )} </div>}

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card__list__body">
                                                <div className="card__list__title">{this.props.item?.name}
                                                </div >
                                                <div className="card__list__text">{this.props.item?.message}</div>
                                            </div>
                                        </div>
                                          : <div className="card__list__top" >

                                        <div className="card__list__body">
                                            <div className="card__list__title">{this.props.item?.name}
                                            </div >
                                            <div className="card__list__text">{this.props.item?.message}</div>
                                        </div>
                                    </div>}
                                    </div>
                                </div>
                                <ModalRowDelete handleModalShowHide={this.handleModalShowHide} handleSubmit={this.handleSubmit}/>
                              
                                </React.Fragment>
                                }
                            </Container>
                        }
                    </Modal.Body>

                </Modal>

            </div >
    )
  }
}

export default ModalDeleteIncident
