import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import My404Component from '../typeUsers/admin/My404Component';
import IHistoryUser from '../typeUsers/user/incident_history';
import ServiceStatusUser from '../typeUsers/user/service_status';
import Layout from './layout/user/index';

const PublicRouter = () => {
  return (
    <Router>
      <Layout>
        <Switch>
          <Route path="/" exact component={ServiceStatusUser} />
          <Route path="/incident_history" exact component={IHistoryUser} />
          <Route path="/state_service" exact component={ServiceStatusUser} />
          <Route component={My404Component} /> {/* 404 route */}
        </Switch>
      </Layout>
    </Router>
  );
};

export default PublicRouter;
