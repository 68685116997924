import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Container, Row, Col } from 'react-bootstrap';
import Check from '../../../img/c-check.png';
import T_v from '../../../img/t_v.png';

import File from '../../file/index';
import Table from './table/index';

import AuthService from '../../../services/auth.service';

import Moment from 'moment';
Moment.locale('es');

const ModalAddRCA = ({ showHide, handleModalShowHide, item, getData }) => {
  const [state, setState] = useState({
    validate: false,
    suscriptores: [],
    file: '',
    success: false,
    loader: false,
    step: 0,
  });
  const [subscribersData, setSubscribersData] = useState([]);
  const [loaderSubscribersData, loaderSetSubscribersData] = useState(false);


  useEffect(() => {
    async function fetchData() {
      loaderSetSubscribersData(true)
      try {
        const response = await AuthService.getsuscriptoresIncidenteId(item.PK);
        setSubscribersData(response.data.data);
        getData(response.data.data)
        loaderSetSubscribersData(false)
        if (!response.data.data.some((subscriber) => subscriber.rcasent === true)) {
          const arrayOfObjects = response.data.data.map((item) => ({
            id: item.PK,
            checked: item.rca,
          }));
          setState((prevState) => ({ ...prevState, suscriptores: arrayOfObjects }));
        }
      } catch (error) {
        console.error('Error fetching subscribers data:', error);
        loaderSetSubscribersData(false)
      }
    }
    fetchData()

  }, []);
  const handleInputChangeFile = (value, status) => {
    setState((prevState) => ({ ...prevState, validate: status, file: value }));
  };
  const handleInputChangeCheckRCA = (value) => {
    const target = value;
    const name = target.name;
    const listSuscriptores = [...state.suscriptores];
    const indexInList = listSuscriptores.findIndex((item) => item.id === name);
    if (indexInList === -1) {
      listSuscriptores.push({ id: name, checked: target.checked });
    } else {
      listSuscriptores[indexInList].checked = target.checked;
    }
    setState((prevState) => ({ ...prevState, suscriptores: listSuscriptores }));
  };


  const previous = () => {
    if (state.step >= 1) {
      setState((prevState) => ({ ...prevState, step: prevState.step - 1 }));
    }
  };

  const next = () => {
    if (state.step <= 2) {
      setState((prevState) => ({ ...prevState, step: prevState.step + 1 }));
    }
  };

  const handleSubmit = async () => {
    setState((prevState) => ({ ...prevState, loader: true }));
    const rcaSuscriptores = [];
    state.suscriptores.forEach((item) => {
      if (item.checked) {
        rcaSuscriptores.push(item.id);
      }
    });
    const obj = {
      id: item?.PK,
      rcaSuscriptores,
      rca: state.file?.file64,
      rcaNombre: state.file?.fileName,
    };
    try {
      await AuthService.postIncidenteRca(obj);
      setState((prevState) => ({ ...prevState, success: true, validate: false, loader: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, success: true, validate: false, loader: false }));
    }
  };

  const renderCardDetails = () => (
    <div className='accordion__heading'>
      <div className="card__list__top no-pointer">
        <div className="card__list__head card__list__head-a">
          <div className="card__list__status__item">
            <div className="card__list__status__item--a">
              <div className="card__list__status__img">
                <img src={T_v} alt="T_v" />
              </div>
              <div className="card__list__status__text">
                {Moment(item?.dateTimeHour).format('D MMMM YYYY - h:mm:ss A')}
              </div>
            </div>
          </div>
        </div>
        <div className="card__list__body card__list__body-a">
          <div className="card__list__title">
            {item?.name}
            <span>- </span>
            {item?.published ? 'Publicado' : 'Borrador'}
          </div>
          <div className="card__list__text">{item?.message}</div>
        </div>
      </div>
    </div>
  );

  const renderPreviousButton = (
    <Button
      className='btn btn-extra'
      variant="outline-secondary"
      onClick={() => previous()}
    >
      Anterior
    </Button>
  );

  const renderNextButton = (
    <Button
      className='btn btn-extra'
      variant="outline-secondary"
      onClick={() => next()}
    >
      Siguiente
    </Button>
  );

  const renderSendButton = (
    <Button
      className='ml-24'
      variant="primary"
      onClick={() => handleSubmit()}
    >
      {state.step === 2 ? 'Sí, enviar' : 'Enviar documento'}
    </Button>
  );

  return (
    <div data-testid="modal-add-rca">
      <Modal show={showHide}>
        <Modal.Header closeButton onClick={() => handleModalShowHide(state.success)}>
        </Modal.Header>
        <Modal.Body>
          {state.success ? (
            <Container>
              <Row>
                <Col className="success">
                  <img className="mb-4" src={Check} alt={Check} />
                  <div className='success__text modal__title'>RCA enviado con éxito.</div>
                </Col>
              </Row>
            </Container>
          ) : (
            <Container>
              {state.loader ? (
                <div className="loader__container">
                  <div className="loader"></div>
                </div>
              ) : (
                <>

                  {subscribersData.some((subscriber) => {
                    return subscriber.rcasent === true;
                  }) ?
                    <h4 className="modal__title mb-24">
                      Reenviar RCA
                    </h4> :
                    <h4 className="modal__title">
                      Adjuntar y enviar RCA </h4>

                  }


                  <Form>
                    <Row className="justify-content-md-center">
                      {!subscribersData.some((subscriber) => {
                        return subscriber.rcasent === true;
                      }) && (
                          <Col md={12}>
                            {renderCardDetails()}
                          </Col>
                        )}

                      <Col md={12}>

                        {
                          !subscribersData.some((subscriber) => subscriber.rcasent === true) && (
                            state.step < 2 && (
                              <div className="steps__container">
                                <div className="d-flex justify-content-center steps__detail">
                                  Paso {state.step + 1} de 2
                                </div>
                                <div className="d-flex justify-content-center steps__title">
                                  PASO {state.step + 1}
                                  {state.step === 0 ? (
                                    <div className="steps__title__detail">Agrega a los destinatarios</div>
                                  ) : (
                                    <div className="steps__title__detail">Adjunta el RCA</div>
                                  )}
                                </div>
                              </div>
                            )
                          )
                        }
                        <div className={state.step === 0 ? '' : 'd-none'}>
                          {loaderSubscribersData ? (
                            <div className="loader__container">
                              <div className="loader"></div>
                            </div>
                          ) :
                            <Table
                              handleInputChangeCheckRCA={handleInputChangeCheckRCA}
                              incident={item}
                              subscribers={subscribersData}
                            />
                          }

                        </div>
                        <div className={state.step === 1 ? '' : 'd-none'}>
                          <File
                            handleInputChangeFile={handleInputChangeFile}
                            step={state.step}
                            incident={item}
                          />
                        </div>
                        <div
                          style={{ border: '0', borderTop: 'solid 1px #BCBCBC' }}
                          className={`file__select ${state.step !== 2 ? 'd-none' : ''}`}
                        >
                          ¿Confirma que desea enviar el documento “
                          <span className="bold">{state.file?.fileName}</span>” para el incidente{' '}
                          <span className="bold">“{item?.name}”</span>?
                        </div>
                      </Col>

                      {
                        subscribersData.some((subscriber) => subscriber.rcasent === true) ? (
                          <Row className="d-flex justify-content-end modal__btn mb-0">
                            {(state.suscriptores.some((suscriptor) => suscriptor.checked === true) ? renderSendButton : <Button className="ml-24" variant="secondary">Enviar documento</Button>)}
                          </Row>
                        ) : (
                          <Row className="d-flex justify-content-end modal__btn mb-0">
                            {state.step > 0 ? (
                              state.step === 2 ? (
                                renderSendButton
                              ) : (
                                renderPreviousButton
                              )
                            ) : state.suscriptores.some((suscriptor) => suscriptor.checked === true) ? (
                              renderNextButton
                            ) : (
                              <Button variant="secondary">Siguiente</Button>
                            )}

                            {state.step > 0 && (state.validate ? renderSendButton : <Button className="ml-24" variant="secondary">Enviar documento</Button>)}

                          </Row>
                        )
                      }

                    </Row>
                  </Form>
                </>
              )}
            </Container>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalAddRCA;