import React, { useEffect, useState } from "react";

import Index from '../../pages/service_status/index'
import AuthService from '../../services/auth.service'
import './i18n.js'
import '../../css/app.scss'


const ServiceStatus = (props) => {
  const [data, setdata] = useState([])
  const [incident, setincident] = useState([])
  const [maintenance, setmaintenance] = useState([])
  const [loader, setloader] = useState(false)
  const [textError, setTextError] = useState('');

  const AllDataComponent = async () => {
    setloader(true)
    try {
      const [
        grup,
        incident,
        maintenance
      ] = await Promise.all([
        AuthService.getGrupos({
          validateStatus: function (status) {
            return status < 500 
          }
        }),
        AuthService.getIncidentes('published=true+&limit=5'),
        AuthService.getmantenimientos()
      ])
      const allGrupo = grup.data.data.Items
      const allIncidente = incident.data.data.Items
      const allMantenimientos = maintenance.data.data.Items
      setdata(allGrupo)
      setincident(allIncidente)
      setmaintenance(allMantenimientos)
      setloader(false)
    } catch (error) {
      setdata([])
      setincident([])
      setmaintenance([])
      setloader(true)
      setTextError('Oops, algo salió mal, intenta refrescar la página o volver a iniciar sesión.');

    }
  }

  useEffect(() => {

    AllDataComponent();

  }, []);


  return (
    <React.Fragment>
      {textError && <div className="error-message mb-2">{textError}</div>}
      {data &&
        <Index
          callAllDataComponent={AllDataComponent}
          AllDataComponent={data}
          Maintenance={maintenance}
          admin={true}
          AllDataIncident={incident}
          loader={loader}
        />}
    </React.Fragment>
  );
}

export default ServiceStatus
