import React, { useEffect, useState } from 'react';
import CardDescription from '../../components/card/incident/description';
import Filter from '../../components/card/incident/filter';
import FilterQuarterly from '../../components/card/incident/filterQuarterly';
import Pagination from '../../components/Pagination';
import AuthService from '../../services/auth.service';
import '../../../src/css/app.scss';
import Body from './body';

const Index = (props) => {
  const [showHide, setShowHide] = useState(false);
  const [aplicationText, setAplicationText] = useState('');
  const [searchText, setSearchText] = useState('');
  const [published, setPublished] = useState('');
  const [filterQuarterly, setFilterQuarterly] = useState('');
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPageLimit] = useState(10);
  const [numberPage, setNumberPage] = useState(1);
  const [numberPageList, setNumberPageList] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState('');
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [errorData, setErrorData] = useState('');
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (numberPage !== 1) {
      if (numberPageList[numberPage] === undefined) {
        const number = [...numberPageList];
        number[numberPage] = { lastEvaluatedKey };
        setNumberPageList(number);
        filterPage(`&nextToken=${lastEvaluatedKey}`);
      } else {
        filterPage(`&nextToken=${numberPageList[numberPage].lastEvaluatedKey}`);
      }
    } else if (filterQuarterly.startDateSearch) {
      filter();
    }

    // Esta función se ejecutará cuando el efecto se limpie
    return () => {
      setTimeout(() => {
        scrollToTop()
      }, 100);
    };
  }, [numberPage, searchText, filterQuarterly]);

  const resetAllDataIncident = (loader) => {
    if (loader) {
      filterPage();
      setNumberPage(1);
    }
  };

  const handleModalShowHide = (loader) => {
    setShowHide(!showHide);

    if (loader) {
      resetAllDataIncident(loader);
    }
  };

  const filter = async () => {
    setLoader(true);
    const text = `published=${published}&componentId=${aplicationText}&search=${searchText}&startDate=${filterQuarterly.startDateSearch}&endDate=${filterQuarterly.endDateSearch}`;

    if (filterQuarterly.startDateSearch) {
      try {
        const response = await (props.admin
          ? AuthService.getIncidentesAdminSearch(`${text}+&limit=${currentPageLimit}`)
          : AuthService.getIncidentes(`${text}+&limit=${currentPageLimit}`));

        const data = response.data.data;
        setTotalPages(data.TotalPages);
        setCurrentItems(data.Items);
        setLastEvaluatedKey(data.LastEvaluatedKey);
        setNumberPage(1);
        setLoader(false);
      } catch (error) {
        setCurrentItems([]);
        setErrorData(error?.data?.data);
        setError(true);
        setLoader(false);
      }
    }
  };

  const filterPage = async (value) => {
    setLoader(true);
    let text = `published=${published}&componentId=${aplicationText}&search=${searchText}&startDate=${filterQuarterly.startDateSearch}&endDate=${filterQuarterly.endDateSearch}`;
    text += value;

    try {
      const response = await (props.admin
        ? AuthService.getIncidentesAdminSearch(`${text}+&limit=${currentPageLimit}`)
        : AuthService.getIncidentes(`${text}+&limit=${currentPageLimit}`));

      const data = response.data.data;
      setTotalPages(data.TotalPages);
      setCurrentItems(data.Items);
      setLastEvaluatedKey(data.LastEvaluatedKey);
      setLoader(false);
    } catch (error) {
      setCurrentItems([]);
      setErrorData(error?.data?.data);
      setError(true);
      setLoader(false);
    }
  };

  const clear = async () => {
    setLoader(true);
    setAplicationText('');
    setPublished('');
    const text = `startDate=${filterQuarterly.startDateSearch}&endDate=${filterQuarterly.endDateSearch}`;

    try {
      const response = await (props.admin
        ? AuthService.getIncidentesAdminSearch(`${text}+&limit=${currentPageLimit}`)
        : AuthService.getIncidentes(`${text}+&limit=${currentPageLimit}`));
      const data = response.data.data;
      setCurrentItems(data.Items);
      setLastEvaluatedKey(data.LastEvaluatedKey);
      setLoader(false);
      setNumberPage(1);
    } catch (error) {
      setCurrentItems([]);
      setErrorData(error?.data?.data);
      setError(true);
      setLoader(false);
    }
  };

  const handleChangeFilterQuarterly = (value) => {
    setFilterQuarterly(value);
  };

  const handleChangeSearchText = (event) => {
    setSearchText(event.target.value);
  };

  const handleChangeAplication = (event) => {
    setAplicationText(event.target.value);
  };

  const handleChangeStatus = (event) => {
    setPublished(event.target.value);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <React.Fragment>
      <CardDescription
        title="Histórico de Incidentes"
        btn={false}
        btn_text="Nuevo incidente"
        text="REGISTRO DEL ÚLTIMO MES"
        type={0}
        btnFunction={handleModalShowHide}
        admin={props.admin}
        searchText={searchText}
        handleChangeSearchText={handleChangeSearchText}
      />
      <Filter
        aplication={props.aplication}
        published={published}
        aplicationText={aplicationText}
        filter={filter}
        clear={clear}
        admin={props.admin}
        handleChangeAplication={handleChangeAplication}
        handleChangeStatus={handleChangeStatus}
      />
      <FilterQuarterly admin={props.admin}
        handleChangeFilterQuarterly={handleChangeFilterQuarterly} />
      {loader ? (
        error ? (
          <div className="loader__container" data-testid="loader">
            {errorData}
          </div>
        ) : (
          <div className="loader__container" data-testid="loader">
            <div className="loader"></div>
          </div>
        )
      ) : (
        <div className="card__list">
          {currentItems &&
            currentItems.map((item, i) => (
              <Body
                admin={props.admin}
                resetAllDataIncident={resetAllDataIncident}
                key={i}
                item={item}
                i={i}
              />
            ))}
          {currentItems && (
            <Pagination
              totalPages={totalPages}
              numberPage={numberPage}
              onPageChange={(page) => {
                setNumberPage(page);
              }}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default Index;