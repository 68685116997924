import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import 'moment/locale/es';
import Moment from 'moment';
Moment.locale('es');

class FilterQuarterly extends Component {
  constructor() {
    super();
    this.state = {
      startDate: new Date(),
      startDateSearch: '',
      endDateSearch: '',
      stateMonth: 0,
      months: [
        { startDate: '1', endDate: '3' },
        { startDate: '4', endDate: '6' },
        { startDate: '7', endDate: '9' },
        { startDate: '10', endDate: '12' },
      ],
    };
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
    this.isMonthLessThanOrEqualAndCurrentYearOrPreviousYear = this.isMonthLessThanOrEqualAndCurrentYearOrPreviousYear.bind(this);
  }

  componentDidMount() {
    const currentMonth = parseInt(Moment(new Date()).format('M'), 10);
    this.state.months.forEach((month, i) => {
      const monthStartDate = parseInt(month.startDate, 10);
      const monthEndDate = parseInt(month.endDate, 10);
      if (currentMonth >= monthStartDate && currentMonth <= monthEndDate) {
        this.handleChangeDateSearch(i, this.state.startDate);
        this.setState({
          stateMonth: i,
        });
      }
    });
  }

  handleChangeDateSearch(month, date) {
    if (month === 0) {
      this.setState({
        startDateSearch: `${Moment(date).format('YYYY')}-01-01`,
        endDateSearch: `${Moment(date).format('YYYY')}-03-31`,
      });
      this.props.handleChangeFilterQuarterly &&
        this.props.handleChangeFilterQuarterly({
          startDateSearch: `${Moment(date).format('YYYY')}-01-01`,
          endDateSearch: `${Moment(date).format('YYYY')}-03-31`,
        });
    }
    if (month === 1) {
      this.props.handleChangeFilterQuarterly &&
        this.props.handleChangeFilterQuarterly({
          startDateSearch: `${Moment(date).format('YYYY')}-04-01`,
          endDateSearch: `${Moment(date).format('YYYY')}-06-30`,
        });
    }
    if (month === 2) {
      this.props.handleChangeFilterQuarterly &&
        this.props.handleChangeFilterQuarterly({
          startDateSearch: `${Moment(date).format('YYYY')}-07-01`,
          endDateSearch: `${Moment(date).format('YYYY')}-09-30`,
        });
    }
    if (month === 3) {
      this.props.handleChangeFilterQuarterly &&
        this.props.handleChangeFilterQuarterly({
          startDateSearch: `${Moment(date).format('YYYY')}-10-01`,
          endDateSearch: `${Moment(date).format('YYYY')}-12-31`,
        });
    }
  }

  handleChangeDate(date) {
    this.setState({
      startDate: date,
    });
    this.handleChangeDateSearch(this.state.stateMonth, date);
  }

  previous() {
    let month = 0;
    if (this.state.stateMonth >= 1) {
      month = this.state.stateMonth - 1;
      this.setState({
        stateMonth: month,
      });
    } else {
      month = this.state.stateMonth;
      this.setState({
        stateMonth: month,
      });
    }
    this.handleChangeDateSearch(month, this.state.startDate);
  }

  next() {
    let month = 0;
    if (this.state.stateMonth <= 2) {
      month = this.state.stateMonth + 1;
      this.setState({
        stateMonth: month,
      });
    } else {
      month = this.state.stateMonth;
      this.setState({
        stateMonth: month,
      });
    }
    this.handleChangeDateSearch(month, this.state.startDate);
  }

  isMonthLessThanOrEqualAndCurrentYearOrPreviousYear() {
    const currentMonth = Moment().month();
    const currentYear = Moment().year();
    const { startDate, stateMonth, months } = this.state;
  
    const formattedMonth = parseInt(months[stateMonth].endDate, 10);
    const formattedYear = Moment(startDate).year();
  
    return currentMonth >= formattedMonth && formattedYear <= currentYear
  }
  isYearLessThanOrEqualCurrent() {
    const currentYear = Moment().year();
    const { startDate, stateMonth } = this.state;
  
    const formattedYear = Moment(startDate).year();
  
    return  formattedYear <= currentYear && stateMonth !== 0  }
  
  
  render() {
    return (
      <React.Fragment>
        <div className="filter__quarterly__container">
          <div className="filter__quarterly__item filter__quarterly__mounth filter-quarterly-state">
            <div
              className={`filter__quarterly__mounth__arrow ${this.isYearLessThanOrEqualCurrent() ? 'active' : ''
            }`}
              onClick={() => {
                if (this.isYearLessThanOrEqualCurrent() ) {
                  this.previous();
                }
              }}
            >
              <i className="icon-left"></i>
            </div>
            <div className="filter__quarterly__mounth__text">
              {Moment(this.state.months[this.state.stateMonth].startDate).format('MMMM')}{' '}−{' '}{Moment(this.state.months[this.state.stateMonth].endDate).format('MMMM')}
            </div>
            <div
              className={`filter__quarterly__mounth__arrow ${this.isMonthLessThanOrEqualAndCurrentYearOrPreviousYear() ? 'active' : ''
                }`}
              onClick={() => {
                if (this.isMonthLessThanOrEqualAndCurrentYearOrPreviousYear() ) {
                  this.next();
                }
              }}
            >
              <i className="icon-right"></i>
            </div>
          </div>

          <Form.Group className="filter__quarterly__item filter__quarterly__year">
            <DatePicker
              id="1"
              className="form-select"
              selected={this.state.startDate}
              onChange={this.handleChangeDate}
              showYearPicker
              dateFormat="yyyy"
            />
          </Form.Group>
        </div>
      </React.Fragment>
    );
  }
}

export default FilterQuarterly;
