import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Check from '../../../img/c-check.png'
import AuthService from '../../../services/auth.service'
import 'react-datepicker/dist/react-datepicker.css'
import Moment from 'moment'

Moment.locale('es')
class ModalActive extends React.Component {
  constructor() {
    super()
    this.state = {
      success: false,
      loader: false
    }
    this.handleModalShowHide = this.handleModalShowHide.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit() {
    const obj = {
      name: this.props.item?.name,
      email: this.props.item?.email,
      code: this.props.item?.code,
      active: 1
    }
    this.setState({
      loader: true
    })
   AuthService.patchSuscriptorID(this.props.item?.PK, obj)
      .then(
        response => {
          this.setState({
            success: true,
            loader: false
          })
        },
        error => {
          this.setState({
            loader: false
          })
        }
      )
  }
  handleModalShowHide(value) {
    this.setState({
      success: false
    })
    this.props.handleModalShowHide(value)
  }
  render() {
    return (
      <div>
        <Modal className='modal__delete ' show={this.props.showHide}>
          {this.state.success
            ? <Modal.Header closeButton onClick={() => this.handleModalShowHide(true)}>
            </Modal.Header>
            : <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
            </Modal.Header>}
          <Modal.Body >
            {this.state.success
              ? <Container>
                <Row>
                  <Col className="success">
                    <img className="mb-4" src={Check} alt={Check} />
                    <div className='success__text modal__title'>Suscripción aceptada</div>
                  </Col>
                </Row>
              </Container>
              : <Container>
                {this.state.loader
                  ? <div className="loader__container">
                    <div className="loader"></div>
                  </div>
                  : <React.Fragment>
                    <h4 className="modal__title">¿Confirma que desea aprobar la petición de suscripción? </h4>
                    <div className="card__list__title">
                      {this.props.item?.name}
                    </div>
                    <div>
                      {this.props.item?.PK}
                    </div>
                    <div>
                      {this.props.item?.email}
                    </div>
                    <div>
                      {this.props.item?.code}
                    </div>
                    <Row className="d-flex justify-content-end modal__btn mb-0">
                      <Button className="btn btn-extra" onClick={() => this.handleModalShowHide()}>Cancelar</Button>
                      <Button className='ml-24' variant="primary" onClick={() => this.handleSubmit()}>
                        Sí, aprobar
                      </Button>
                    </Row>
                  </React.Fragment>
                }
              </Container>
            }
          </Modal.Body>
        </Modal>
      </div >
    )
  }
}

export default ModalActive
