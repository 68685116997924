import React, { useEffect, useState } from 'react'
import Index from '../../pages/suscribe/index'
import AuthService from '../../services/auth.service'
import './i18n.js'
import '../../css/app.scss'


const Suscribe = (props) => {
  const [originalData, setoriginalData] = useState([])
  const [data, setdata] = useState([])
  const [plataformas, setplataformas] = useState([])
  const [loader, setloader] = useState(false)
  const [error, seterror] = useState(false)
  const [errordata, seterrordata] = useState('')
  const [textError, setTextError] = useState('');

  useEffect(() => {
    setloader(true)
    AllData()
  }, [])

  const AllData = async () => {
    setloader(true)
    seterror(false)
    try {
      const [data, Items] = await Promise.all([
        AuthService.getComponents({ simplify: true }),
        AuthService.getSuscriptores({ simplify: true })
      ])
      setoriginalData(Items)
      setdata(Items)
      setplataformas(data.Items)
      setloader(false)
    } catch (error) {
      seterrordata(error?.response?.data?.data)
      seterror(true)
      setloader(true)
      setTextError('Oops, algo salió mal, intenta refrescar la página o volver a iniciar sesión.');

    }
  }

  const onFilterData = async ({ idComponent }) => {
    let stateOriginData = originalData
    if (idComponent === null) {
      setdata(stateOriginData.slice(0))
      return
    }
    stateOriginData = stateOriginData.filter((item) =>
      item.components.find((c) => c?.PK === idComponent)
    )
    setdata(stateOriginData)
  }
  return (
    <React.Fragment >
      {textError && <div className="error-message mb-2">{textError}</div>}

      {data && (
        <Index
          admin={true}
          callAllData={AllData}
          onFilterData={onFilterData}
          AllData={data}
          Plataformas={plataformas}
          loader={loader}
          error={error}
          errordata={errordata}
        />
      )}
    </React.Fragment>
  )
}

export default Suscribe