import React from 'react';
import { Form } from 'react-bootstrap';

function DelayedCheckbox(props) {
  const { handleInputChangeCheck, props: { item, rca, i, plataformas } } = props;



  return (
    <article className="card__list__rca card__col card__col-b  flex--start-j">
      <Form.Group>
          <Form.Check
            inline
            className={`btn__checkbox active `}
            type="checkbox"
            label="Recibe RCA"
            onChange={handleInputChangeCheck}
            name={item?.PK}
            id={`${i + 1}`}
            defaultChecked={rca}
            disabled={!plataformas.some((plataforma) => plataforma.checked)}
          />
       
      </Form.Group>
    </article>
  );
}

export default DelayedCheckbox;
