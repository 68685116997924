import React, { useEffect, useState } from 'react';
import Pagination from '../../components/Pagination';
import '../../../src/css/app.scss';
import AuthService from '../../services/auth.service';
import Maintenance from '../../components/card/aplication/maintenance';

const Index = (props) => {
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPageLimit] = useState(10);
  const [numberPage, setNumberPage] = useState(1);
  const [numberPageList, setNumberPageList] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState('');
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [errorData, setErrorData] = useState('');
  const [totalPages, setTotalPages] = useState(0);

  const filterPage = async (value) => {
    setLoader(true);

    const text = value;
    try {
      const { data } = await AuthService.getmantenimientos(`${text}+&limit=${currentPageLimit}`);
      setTotalPages(data.data.TotalPages);
      setCurrentItems(data.data.Items);
      setLastEvaluatedKey(data.data.LastEvaluatedKey);
      setLoader(false);
    } catch (error) {
      setCurrentItems([]);
      setErrorData(error?.data?.data);
      setError(true);
      setLoader(false);
    }
  };
  useEffect(() => {


    if (numberPage !== 1) {
      if (numberPageList[numberPage] === undefined) {
        const number = [...numberPageList];
        number[numberPage] = { lastEvaluatedKey };
        setNumberPageList(number);
        filterPage(`&nextToken=${lastEvaluatedKey}`);
      } else {
        filterPage(`&nextToken=${numberPageList[numberPage].lastEvaluatedKey}`);
      }
    } else {
      filterPage();
    }
  }, [numberPage, currentPageLimit]);

  const resetAllDataIncident = (loader) => {
    if (loader) {
      setNumberPage(1);
      filterPage();
    }
  };

  return (
    <React.Fragment>
      {loader ? (
        error ? (
          <div className="loader__container">
            {errorData}
          </div>
        ) : (
          <div data-testid="loader" className="loader__container">
            <div className="loader"></div>
          </div>
        )
      ) : (
        <div className="card__list">
          <div className="maintenance__title">
            Histórico de Mantenimientos
          </div>
          <div data-testid="maintenance__container" >
            {currentItems && currentItems.map((item, i) => (
              <Maintenance
                key={`Maintenance-${i}`}
                btnEdit={true}
                btnDelete={true}
                ResetAllDataComponet={resetAllDataIncident}
                maintenance={item}
                admin={props.admin}
              />

            ))}
          </div>
          {currentItems.length > 0 && (
            <Pagination
            totalPages={totalPages}
            numberPage={numberPage}
            onPageChange={setNumberPage}

            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default Index;