import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const Nav = () => {
  const location = useLocation();

  return (
    <React.Fragment>
      <div data-testid="nav-component" className="nav">
        <div className="nav__container content">
          <div className="nav__items">
            <NavLink to="/admin/state_service" className={`nav__item ${location.pathname === '/admin/state_service' ? 'active' : ''}`}>
              Estado de Servicios
            </NavLink>
            <NavLink to="/admin/incident_history" className={`nav__item ${location.pathname === '/admin/incident_history' ? 'active' : ''}`}>
              Histórico de Incidentes
            </NavLink>
            <NavLink to="/admin/maintenance_history" className={`nav__item ${location.pathname === '/admin/maintenance_history' ? 'active' : ''}`}>
              Histórico de Mantenimientos
            </NavLink>
            <NavLink to="/admin/suscribe" className={`nav__item ${location.pathname === '/admin/suscribe' ? 'active' : ''}`}>
              Suscripciones
            </NavLink>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Nav;
