import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, Form } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import ExpandingInput from '../../input/expandingInput';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Check from '../../../img/c-check.png'
import AuthService from '../../../services/auth.service'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Moment from 'moment'

Moment.locale('es')

class ModalMaintenance extends React.Component {
  constructor () {
    super()
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      description: '',
      loader: false,
      success: false,
      change: false
    }

    this.handleModalShowHide = this.handleModalShowHide.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleChangeStartDateUpdate = this.handleChangeStartDateUpdate.bind(this)
    this.handleChangeEndDateDateUpdate = this.handleChangeEndDateDateUpdate.bind(this)
    this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this)
  }

  componentWillReceiveProps (props) {
    const dateYYYY = parseInt(Moment(props?.maintenance?.startDate).format('YYYY'),10)
    const dateMMMM = parseInt(Moment(props?.maintenance?.startDate).format('MM'),10)
    const dateDD = parseInt(Moment(props?.maintenance?.startDate).format('DD'),10)
    const datehh = parseInt(Moment(props?.maintenance?.startDate).format('HH'),10)
    const datemm = parseInt(Moment(props?.maintenance?.startDate).format('mm'),10)
    const datess = parseInt(Moment(props?.maintenance?.startDate).format('ss'),10)
    const union = dateMMMM + '/' + dateDD + '/' + dateYYYY + ' ' + datehh + ':' + datemm + ':' + datess
    const dateTimeHourFormat = new Date(union)

    const dateYYYY2 = parseInt(Moment(props?.maintenance?.endDate).format('YYYY'),10)
    const dateMMMM2 = parseInt(Moment(props?.maintenance?.endDate).format('MM'),10)
    const dateDD2 = parseInt(Moment(props?.maintenance?.endDate).format('DD'),10)
    const datehh2 = parseInt(Moment(props?.maintenance?.endDate).format('HH'),10)
    const datemm2 = parseInt(Moment(props?.maintenance?.endDate).format('mm'),10)
    const datess2 = parseInt(Moment(props?.maintenance?.endDate).format('ss'),10)
    const union2 = dateMMMM2 + '/' + dateDD2 + '/' + dateYYYY2 + ' ' + datehh2 + ':' + datemm2 + ':' + datess2
    const dateTimeHourFormat2 = new Date(union2)
    this.setState({
      startDate: dateTimeHourFormat,
      endDate: dateTimeHourFormat2,
      description: props?.maintenance?.description
    })
  }

  handleSubmitUpdate () {
    this.setState({
      loader: true
    })
    const obj = {
      startDate: Moment(this.state.startDate).format('YYYY-MM-DD HH:mm:ss'),
      endDate: Moment(this.state.endDate).format('YYYY-MM-DD HH:mm:ss'),
      description: this.state.description
    }

    AuthService.putActualizacionMantenimientos(this.props.maintenance.PK, obj)
      .then(
        _response => {
          this.setState({
            success: true,
            loader: false
          })
        },
        error => {
          this.setState({
            success: false,
            loader: false
          })
        }
      )
  }

  handleModalShowHide (value) {
    this.props.handleModalShowHide(value)
    this.setState({
      success: false,
      change:false
    })
  }

  handleInputChange (event) {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
      change:true
    })
  }

  handleChangeStartDateUpdate (date) {
    this.setState({
      startDate: date,
      change:true
    })
  }

  handleChangeEndDateDateUpdate (date) {
    this.setState({
      endDate: date,
      change:true
    })
  }

  render () {
    return (
            <div>
                <Modal data-testid="ModalMaintenance" show={this.props.showHide} data-show={true}>
                    {this.state.success
                      ? <Modal.Header closeButton onClick={() => this.handleModalShowHide(true)}>
                        </Modal.Header>
                      : <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                        </Modal.Header>}

                    <Modal.Body>

                        {this.state.success
                          ? <Container>
                                <Row>
                                    <Col className="success">

                                        <img className="mb-4" src={Check} alt={Check} />

                                        <div className='success__text modal__title'>Los cambios se han guardado con éxito.</div>
                                    </Col>
                                </Row>
                            </Container>
                          : <div>
                                {this.state.loader
                                  ? <div className="loader__container">
                                        <div className="loader"></div>
                                    </div>
                                  : <React.Fragment>
                                        <h4 className="modal__title">Editar Mantenimiento</h4>
                                        <Form >
                                            <Row className="justify-content-md-center">
                                                <Col md={6}>

                                                    <Form.Group className="mb-3">
                                                        <Form.Label htmlFor="1">Desde</Form.Label>

                                                        <DatePicker id="1" className='form-select '
                                                            selected={this.state.startDate}
                                                            onChange={this.handleChangeStartDateUpdate}
                                                            minDate={new Date()}
                                                            showTimeSelect
                                                            timeFormat="HH:mm"
                                                            dateFormat="dd/MM/yyyy HH:mm"
                                                        />

                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>

                                                    <Form.Group className="mb-3">
                                                        <Form.Label htmlFor="1">Hasta</Form.Label>

                                                        <DatePicker id="1" className='form-select '
                                                            selected={this.state.endDate}
                                                            onChange={this.handleChangeEndDateDateUpdate}
                                                            minDate={this.state.startDate}
                                                            showTimeSelect
                                                            timeFormat="HH:mm"
                                                            dateFormat="dd/MM/yyyy HH:mm"
                                                        />

                                                    </Form.Group>
                                                </Col>
                                                <Col sm={12}>
                                                    <Form.Group >
                                                        <Form.Label>Mensaje</Form.Label>
                                                        <ExpandingInput name={"description"} value={this.state.description} initialHeight={40}  onChange={this.handleInputChange}  maxRows={5}/>
                                                   </Form.Group>
                                                </Col>
                                                <Row className="d-flex justify-content-end modal__btn mb-0">

                                                    <Button className='btn btn-extra' variant="outline-secondary" onClick={() => this.handleModalShowHide()}>
                                                        Cancelar
                                                    </Button>
                                                    {
                                                        (this.state.change)
                                                          ? <Button className='ml-24' variant="primary" onClick={() => this.handleSubmitUpdate()}>
                                                                Guardar cambios
                                                            </Button>
                                                          : <Button className='ml-24' variant="secondary"> Guardar cambios</Button>

                                                    }
                                                </Row>

                                            </Row>
                                        </Form>
                                    </React.Fragment>
                                }
                            </div>
                        }
                    </Modal.Body>

                </Modal>

            </div >
    )
  }
}
ModalMaintenance.propTypes = {
  handleModalShowHide: PropTypes.func,
  showHide: PropTypes.bool,
  maintenance: PropTypes.any
}
export default ModalMaintenance