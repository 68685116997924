import React, { Component } from 'react';
import { Moment, BtnMore, BtnMinus } from '../../commonImports';
import AuthService from '../../../services/auth.service';
import ListItem from './listItemSubGroup';
import ListItemHistorico from './listItemHistorico';
import ListItemGroupsEditDelete from './listItemGroupsEditDelete';
Moment.locale('es');
class ListItemGroups extends Component {
  state = {
    subGrupos: [],
    active: false
  };

  componentDidMount() {
    this.handleClickgetSubGrupos();
  }

  handleClickActive = () => {
    this.setState(prevState => ({
      active: !prevState.active
    }));
  };

  handleClick = () => {
    this.props.btnFuntion();
  };

  handleClickDelete = () => {
    this.props.btnFuntionDelete();
  };

  handleClickgetSubGrupos = () => {
    AuthService.getGruposSubgrupos(this.props.item?.PK)
      .then(response => {
        this.setState({
          subGrupos: response.data.data.Items
        });
      })
      .catch(error => {
      });
  };
  render() {
    const { item, admin, i, ResetAllDataComponet } = this.props;
    const { subGrupos, active } = this.state;
    const itemName = item?.name;
    const subGruposLength = subGrupos.length;
    return (
      <>
        <div key={i} className="card__list__container card__list__container__item">
          <div className="accordion__heading">
            <div className={`card__list__top no-pointer ${subGruposLength === 0 ? 'no-pointer' : ''}`}>
              <div className="card__list__head">
                <div className="card__list__status__item card__list__status__item--b">
                  <div className="card__list__status__item--a">
                    <div className="card__list__title">{itemName}</div>
                  </div>
                  {admin && (
                    <div className="card__list__updates">
                      <ListItemGroupsEditDelete handleClick={this.handleClick} handleClickDelete={this.handleClickDelete} />
                    </div>
                  )}
                </div>
                <div onClick={this.handleClickActive}>
                  {subGruposLength !== 0 ? (
                    active ? (
                      <img data-testid="BtnMinus" className="card__list__btn pointer" src={BtnMinus} alt={BtnMinus} />
                    ) : (
                      <img data-testid="BtnMore" className="card__list__btn pointer" src={BtnMore} alt={BtnMore} />
                    )
                  ) : (
                    <div className="card__list__btn card__list__btn--op" />
                  )}
                </div>
              </div>
              <div className="card__list__body">
                <div className="status__btn__container">
                  <ListItemHistorico parent={item} item={item} />
                </div>
              </div>
            </div>
          </div>

          {active && (
            <div data-testid="accordion__panel" className="accordion__panel--a">
              {subGrupos.map((item, index) => (
                <ListItem
                  admin={admin}
                  key={index}
                  ResetAllDataComponet={ResetAllDataComponet}
                  parent={this.props.item}
                  item={{ ...item, index }}
                />
              ))}
            </div>
          )}
        </div>
      </>
    );
  }
}

export default ListItemGroups;