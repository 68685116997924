import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import CloseButton from 'react-bootstrap/CloseButton';
import ModalDeleteComponent from './deleteComponent';
import AuthService from '../../../services/auth.service';

const CardDeleteComponent = (props) => {
  const [showHide, setShowHide] = useState(false);
  const [component, setComponent] = useState(null);

  useEffect(() => {
    handleClickgetComponents(props.item?.SK);
  }, [props.item?.SK]);

  const handleClickgetComponents = (id) => {
    AuthService.getComponentId(id)
      .then((response) => {
        setComponent(response.data.data.Item);
      })
      .catch((error) => {
        // Handle error
      });
  };

  const handleModalShowHide = (loader) => {
    setShowHide(!showHide);
    if (loader) {
      props.reset(loader);
    }
  };

  return (
    <React.Fragment>
      <ModalDeleteComponent
        key={props.key}
        item={component}
        showHide={showHide}
        handleModalShowHide={handleModalShowHide}
        parent={props.parent}
        obj={props.obj}
        length={props.length}
      />
      <Card key={props.key} bg="secondary" className="mb-3 card__component" body>
        {component && component.name}
        <CloseButton onClick={() => handleModalShowHide()} />
      </Card>
    </React.Fragment>
  );
};

export default CardDeleteComponent;