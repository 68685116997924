const awsmobile = {
  "federated_Id_name":  process.env.REACT_APP_FEDERATED_ID_NAME ,
  "aws_project_region":  process.env.REACT_APP_AWS_PROJECT_REGION ,
  "aws_user_pools_id":  process.env.REACT_APP_AWS_USER_POOLS_ID ,
  "aws_user_pools_web_client_id":  process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID ,
  "oauth": {
    domain:  process.env.REACT_APP_DOMAIN ,
    scope: ["email", "openid", "profile"] ,
    redirectSignIn:  process.env.REACT_APP_REDIRECTSIGNIN ,
    redirectSignOut:  process.env.REACT_APP_REDIRECTSIGNOUT ,
    responseType:  process.env.REACT_APP_RESPONSETYPE 
  }
};

export default awsmobile;